import React from "react";

import { OptionItemBodyMultiRow } from "../../../Components/etc/OptionItem";
import CustomValueSelector from "../QuickValueSelector/CustomValueSelector";

const LinkToWebrtc = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      {[
        {
          label: "Caller Details",
          rows: [
            {
              label: "Sender Name",
              className: "mb14",
              key: "callerName",
            },
            {
              label: "Sender Photo",
              key: "callerPhoto",
            },
          ],
        },
        {
          label: "Receiver Details",
          rows: [
            {
              label: "Receiver Device ID",
              className: "mb14",
              key: "receiverDeviceId",
            },
            {
              label: "Receiver Name",
              className: "mb14",
              key: "receiverName",
            },
            {
              label: "SenReceiverder Photo",
              key: "receiverPhoto",
            },
          ],
        },
        {
          label: "Call Details",
          rows: [
            {
              label: "Video / Audio",
              className: "mb14",
              key: "roomType",
              inputType: "customSelect",
              options: ["video", "audio"],
            },
          ],
        },
      ].map((group, index) => (
        <OptionItemBodyMultiRow
          label={group.label}
          key={index}
          rows={group.rows.map((row) => ({
            ...row,
            render: () => (
              <CustomValueSelector
                {...{
                  ...props,
                  row,
                  value: value[row.key],
                  onChange: (x) => mergeChange({ [row.key]: x }),
                }}
              />
            ),
          }))}
        />
      ))}
    </>
  );
};


export default LinkToWebrtc;
