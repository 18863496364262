import React from "react";
import authModule from "../../../Modules/auth/auth-module";

const Logout = () => {
  return (
    <button
      onClick={() => {
        authModule.logout();
      }}
    >
      Logout
    </button>
  );
};

export default Logout;
