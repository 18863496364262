import React, { useState } from "react";
const SearchInput = (props) => {
  const [value, setValue] = useState(props.value || "");
  const [timer, setTimer] = useState(null);

  const handleChange = (text) => {
    clearTimeout(timer);
    setValue(text);

    if (text) {
      setTimer(
        setTimeout(() => {
          props.onChange(text);
        }, 100)
      );
    } else {
      props.onChange(text);
    }
  };

  return (
    <input
      type="text"
      {...props}
      value={value || ""}
      onChange={(event) => handleChange(event.target.value)}
      placeholder={props.placeholder || "Enter some text"}
    />
  );
};

export default SearchInput;
