import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Config from "../Config";
import MainScreenNavigator from "./MainScreenNavigators";
import LoginScreen from "../Views/Auth/LoginScreen";
import HomeScreen from "../Views/Home/HomeScreen";
import DesignScreen from "../Views/Design/DesignScreen";

function AppNavigator() {
  return (
    <Router basename={Config.relativePath}>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/login" exact element={<LoginScreen />} />
        <Route path="/design/:component" exact element={<DesignScreen />} />
        <Route path="*" element={<MainScreenNavigator />} />
      </Routes>
    </Router>
  );
}

export default AppNavigator;
