import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import update from "immutability-helper";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import { isLoggedIn } from "../../Stores/redux/Persisted/Selectors";
import modules from "../../Modules";

const LoginScreen = ({ isLoggedIn }) => {
  const [newUser, setNewUser] = useState(false);
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      const gotohome = () => {
        let defaultNextScreen = "/dashboard";
        let nextScreen =
          modules.navigation.getQuery("nextScreen") || defaultNextScreen;
        nextScreen = modules.navigation.matchCurrentPath(nextScreen)
          ? defaultNextScreen
          : nextScreen;

        navigate(nextScreen);
      };

      gotohome();
    }
  }, [isLoggedIn, navigate]);

  if (isLoggedIn) return null;

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  const login = async (e) => {
    try {
      e.preventDefault();

      if (loading) return;
      setLoading(true);
      setFormError(null);

      await modules.auth.login({
        email: form?.email,
        password: form?.password,
        clientType: "web",
        deviceId: await modules.etc.getDeviceToken(),
      });

      setLoading(false);
    } catch (e) {
      setFormError(e.message);
      setLoading(false);
    }
  };

  const signup = async (e) => {
    try {
      e.preventDefault();

      if (loading) return;
      setLoading(true);
      setFormError(null);

      await modules.auth.signup({
        firstName: form.name,
        email: form?.email,
        password: form?.password,
        clientType: "web",
        deviceId: await modules.etc.getDeviceToken(),
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
    }
  };

  return (
    <div className="login-page">
      <div className="form">
        {formError ? <div className="error">{formError}</div> : ""}
        <form
          className="register-form"
          style={!newUser ? styleObj.hidden : {}}
          onSubmit={(e) => signup(e)}
        >
          <input
            type="text"
            placeholder="Full Name"
            onChange={(e) => {
              updateForm({ name: e.target.value });
            }}
            required
          />
          <input
            type="text"
            placeholder="Phone Number"
            onChange={(e) => {
              updateForm({ phone: e.target.value });
            }}
            required
          />
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => {
              updateForm({ email: e.target.value });
            }}
            required
          />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => {
              updateForm({ password: e.target.value });
            }}
            required
          />
          <button>create</button>
          <p className="message">
            Already registered?{" "}
            <span className="a" onClick={() => setNewUser(false)}>
              Sign In
            </span>
          </p>
        </form>
        <form
          className="login-form"
          style={newUser ? styleObj.hidden : {}}
          onSubmit={(e) => login(e)}
        >
          <input
            type="email"
            placeholder="email"
            onChange={(e) => {
              updateForm({ email: e.target.value });
            }}
            required
          />
          <input
            type="password"
            placeholder="password"
            onChange={(e) => {
              updateForm({ password: e.target.value });
            }}
            required
          />
          <button>login</button>
          <p className="message">
            Not registered?{" "}
            <span className="a" onClick={() => setNewUser(true)}>
              Create an account
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "LOGIN_SCREEN") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const styleObj = {
  hidden: {
    display: "none",
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
