import React, { Component } from "react";
import { DragSource } from "react-dnd";
import _ from "lodash";

const itemSource = {
  beginDrag(props) {
    return props.item;
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

class SourceElement extends Component {
  render() {
    const { connectDragSource, item } = this.props;
    // console.log(item, "ITEMlog");

    const icon = 
    _.startCase(item.elementType) === 'Container' ? require("../../Assets/img/elements/elementContainer.png") : 
    _.startCase(item.elementType) === 'Text' ? require("../../Assets/img/elements/elementText.png") :
    _.startCase(item.elementType) === 'Image' ? require("../../Assets/img/elements/elementImage.png") :
    _.startCase(item.elementType) === 'Input' ? require("../../Assets/img/elements/elementInput.png") :
    _.startCase(item.elementType) === 'Map' ? require("../../Assets/img/elements/elementMap.png") :
    _.startCase(item.elementType) === 'Media' ? require("../../Assets/img/elements/elementMedia.png") : 'No'

    return connectDragSource(
      <div className="builderElementItem">
        <img className="builderElementIcon" src={icon} />
        <span>{_.startCase(item.elementType)}</span>
      </div>
    );
  }
}

export default DragSource("item", itemSource, collect)(SourceElement);
