import React from "react";
import update from "immutability-helper";

import styleModule from "../../../../Modules/style/style-module";
import ImageUpload from "../../Properties/Common/ImageSources/ImageUpload";
import api from "../../../../Services/Api/api";
import etcModule from "../../../../Modules/etc/etc-module";
import AppModal from "../../../../Components/Models/app-modal";
import SearchIcons from "../../Properties/Common/ImageSources/SearchIcons";

class Image extends React.PureComponent {
  state = { files: "", src: null, iconModal: false };

  inputRef = React.createRef(null);

  renderImage(props) {
    return (
      <img
        {...{
          alt: "",
          ...props,
          style: props.src
            ? props.style
            : Object.assign({}, props.style, {
                backgroundColor: props.backgroundColor || "#dedede",
              }),
        }}
      />
    );
  }

  appendSrc(src) {
    const element = this.props.element;
    const staticUrls =
      element.value?.data?.tabs?.[element.value?.data?.activeTabIndex || 0]
        ?.imageData?.valueObj?.staticUrls || [];

    const updatedStaticUrls = update(staticUrls, { $push: [src] });

    const updatedValue = etcModule.mergeObjects(
      element.value,
      { staticUrls: updatedStaticUrls },
      [
        "data",
        "tabs",
        element.value?.data?.activeTabIndex || 0,
        "imageData",
        "valueObj",
      ]
    );

    console.log({ src, updatedStaticUrls, updatedValue });
    this.props.dom.updateNodeValue(element.id, updatedValue);
  }

  uploadHandler = async (event) => {
    try {
      let file = event.target.files[0];
      if (!file) return;

      const fileRes = await api.media("v1/file", {
        file,
      });
      const staticUrl = api.getFileLink(fileRes?.file);

      this.appendSrc(staticUrl);
    } catch (e) {
      console.error("Error uploading file: " + e.message);
    }
  };

  render() {
    const element = this.props.element;
    const activeTab =
      element.value?.data?.tabs?.[element.value?.data?.activeTabIndex || 0];
    const staticUrls = activeTab?.imageData?.valueObj?.staticUrls || [];
    const imageDisplayMode = activeTab?.imageDisplayMode;

    const style =
      styleModule.getElementStyleData(element)?.[element.value?.elementType]
        ?.builder;

    return !staticUrls?.length ? (
      <div
        style={Object.assign({}, style, {
          display: "flex",
          flexDirection: "column",
        })}
      >
        <ImageUpload
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            textAlign: "center",
            backgroundColor: "#efefef",
            color: "black",
            cursor: "pointer",
          }}
          onChange={(file) => this.appendSrc(api.getFileLink(file))}
          label="Image"
        />
        <div
          className="flex jc clickable"
          style={{ flex: 1, textAlign: "center", backgroundColor: "#dedede" }}
          onClick={() => this.setState({ iconModal: true })}
        >
          Icon
        </div>

        <AppModal
          {...{
            title: "Choose Icon",
            visible: this.state.iconModal,
            handleClose: () => this.setState({ iconModal: false }),
            showSubmitBtn: false,
          }}
        >
          <SearchIcons
            iconChooseHandler={(urlObj, src) => {
              let source = src || urlObj?.images?.["128"];
              if (source) this.appendSrc(source);
            }}
            uploadHandler={this.uploadHandler.bind(this)}
            iconSize={style?.width?.replace("px", "")}
          />
        </AppModal>
      </div>
    ) : ["gallery", "repeating"].includes(imageDisplayMode) ? (
      staticUrls?.map((url, i) =>
        this.renderImage({
          key: i.toString() + url,
          style,
          src: url,
        })
      )
    ) : (
      this.renderImage({
        style,
        src: staticUrls[0],
      })
    );
  }
}

export default Image;
