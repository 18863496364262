import StyleSelector from "./StyleSelector";

class Wrap extends StyleSelector {
  styleKey = "flexWrap";
  optionItemLabel = "WRAP";
  defaultStyleValue = "";
  options = [
    { value: "", label: "Off" },
    { value: "wrap", label: "On" },
  ];
}

export default Wrap;
