import React from "react";
import ExternalApiDataSelector from "../ExternalApiOperations/ExternalApiDataSelector";

const UpdateExternalApiReq = (props) => {
  return (
    <>
      <div className="box" key={props.valueType}>
        <div>Target API request id</div>
        <input
          {...{
            value: props.value?.targetApiRequestId || '',
            onChange: (e) =>
              props.onChange({
                ...(props.value || {}),
                targetApiRequestId: e.target.value,
              }),
          }}
        />
      </div>
      <ExternalApiDataSelector
        {...{ ...props, mode: "updateExternalApiReq" }}
      />
    </>
  );
};

export default UpdateExternalApiReq;
