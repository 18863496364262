import React from "react";
import update from "immutability-helper";
import _ from "lodash";

import DatabaseDataSelector from "../DatabaseOperations_v2/DatabaseDataSelector";
import StylesOfElementSelector from "./StylesOfElementSelector";
import LocationSelector from "./LocationSelector";
import TimeInput from "../../../Components/Input/TimeInput";
import DateInput from "../../../Components/Input/DateInput";
import ValueOfElementSelector from "./ValueOfElementSelector";
import PassedParamSelector, {
  PassedParameterFromParentSelector,
} from "./PassedParamSelector";
import DateTimeInput from "../../../Components/Input/DateTimeInput";
import ExternalApiDataSelector from "../ExternalApiOperations/ExternalApiDataSelector";
import GetApiResponse from "./GetApiResponse";
import CustomSelect from "../../../Components/etc/CustomSelect";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import { allBuilderValueTypes } from "../../../Modules/calculation/builder-value-types";

const SelectorBox = ({ title, children }) => {
  return (
    <div className="calcItemUnit">
      <div className="calcItemUnitBox">
        <div className="calcItemUnitDecor"></div>
        <div className="calcItemUnitMain">
          <div className="calcItemUnitMainLabel">{title}</div>
          {children}
        </div>
      </div>
    </div>
  );
};

export const selectors = {
  passedParameter: (props) => (
    <PassedParameterFromParentSelector
      {...{
        ...props,
        key: props.valueType,
        value: props.value[props.valueType],
        onChange: (x) => props.mergeChange({ [props.valueType]: x }),
      }}
    />
  ),
  valueOfElement: (props) => (
    <SelectorBox title={props.builderValueType.label} key={props.valueType}>
      <ValueOfElementSelector
        {...{
          ...props,
          elementType: null,
          styles: { dd: { width: "100%" } },
          value: props.value?.element,
          onChange: (x) => props.mergeChange({ element: x }),
        }}
      />
    </SelectorBox>
  ),
  textParts: (props) => (
    <SelectorBox title={props.builderValueType.label} key={props.valueType}>
      <RichTextData
        {...{
          ...props,
          placeholder: "Custom Value",
          value: props.value[props.valueType]?.valueObj,
          onChange: (valueObj) =>
            props.mergeChange({
              [props.valueType]: { valueType: "textParts", valueObj },
            }),
        }}
      />
    </SelectorBox>
  ),
  valueOfRepeatingContainerRow: (props) => {
    const elementFilter = (x) => {
      const tabs =
        x.value?.data?.dataType === "repeating"
          ? x.value?.data?.tabs || []
          : [x.value?.data?.tabs?.[0]].filter((x) => x);

      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i];

        if (tab?.containerData?.repeating) {
          return true;
        }
      }
      return false;
    };

    return (
      <SelectorBox title={props.builderValueType.label} key={props.valueType}>
        <ValueOfElementSelector
          {...{
            ...props,
            elementType: null,
            styles: { dd: { width: "100%" } },
            elementType: "container",
            elementFilter,
            value: props.value?.valueOfRepeatingContainerRow?.element,
            onChange: (x) =>
              props.mergeChange({
                valueOfRepeatingContainerRow: {
                  ...(props.value?.valueOfRepeatingContainerRow || {}),
                  element: x,
                },
              }),
          }}
        />
        <div>Column Name</div>
        <RichTextData
          {...{
            ...props,
            placeholder: "Column Name",
            value:
              props.value?.valueOfRepeatingContainerRow?.columnName?.valueObj,
            onChange: (valueObj) =>
              props.mergeChange({
                valueOfRepeatingContainerRow: {
                  ...(props.value?.valueOfRepeatingContainerRow || {}),
                  columnName: {
                    valueType: "textParts",
                    valueObj,
                  },
                },
              }),
          }}
        />
        <div>Column Value</div>
        <RichTextData
          {...{
            ...props,
            placeholder: "Column Value",
            value:
              props.value?.valueOfRepeatingContainerRow?.columnValue?.valueObj,
            onChange: (valueObj) =>
              props.mergeChange({
                valueOfRepeatingContainerRow: {
                  ...(props.value?.valueOfRepeatingContainerRow || {}),
                  columnValue: {
                    valueType: "textParts",
                    valueObj,
                  },
                },
              }),
          }}
        />
      </SelectorBox>
    );
  },
  currentTableName: (props) => (
    <SelectorBox title={props.builderValueType.label} key={props.valueType} />
  ),
  valueOfAffectedColumn: (props) => (
    <SelectorBox title={props.builderValueType.label} key={props.valueType}>
      <input
        style={{ width: "100%" }}
        type="text"
        placeholder="Column Name"
        value={props.value?.valueOfAffectedColumn || ""}
        onChange={(event) =>
          props.mergeChange({ valueOfAffectedColumn: event.target.value })
        }
      />
    </SelectorBox>
  ),
  databaseValue: (props) => (
    <DatabaseDataSelector
      {...{
        ...props,
        key: props.valueType,
        value: props.value?.dbData,
        onChange: (x) => props.mergeChange({ dbData: x }),
      }}
    />
  ),
  valueFromApi: (props) => (
    <ExternalApiDataSelector
      {...{
        ...props,
        key: props.valueType,
        value: props.value?.valueFromApi,
        onChange: (x) => props.mergeChange({ valueFromApi: x }),
        mode: "valueFromApi",
      }}
    />
  ),
  dateAndTime: (props) => {
    const value = props.value?.[props.valueType] || {};
    const mergeChange = (x) =>
      props.mergeChange({ [props.valueType]: { ...value, ...x } });

    return (
      <SelectorBox title={props.builderValueType.label} key={props.valueType}>
        <>
          <CustomSelect
            classNames={{
              head: "calcItemUnitMainDropdown",
              label: "calcItemUnitMainDropdownLabel",
            }}
            onChange={(option) =>
              mergeChange({ currentOrCustom: option.value })
            }
            value={value?.currentOrCustom || "current"}
            options={[
              { value: "current", label: "Current Time" },
              { value: "custom", label: "Custom Value" },
            ]}
            jointActionRow={
              <div className="calcItemUnitMainDropdownFooter">
                <div className="calcItemUnitMainDropdownFooterArrow">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              </div>
            }
          />

          {value?.currentOrCustom === "custom" ? (
            <div>
              <RichTextData
                {...{
                  ...props,
                  immediateProps: {
                    classNames: {
                      wrapper: "",
                      editor: "",
                      paramListRow: "",
                    },
                  },
                  placeholder: "Timestamp or Datetime string",
                  value: value?.datetime?.valueObj,
                  onChange: (valueObj) =>
                    mergeChange({
                      datetime: { valueType: "textParts", valueObj },
                    }),
                }}
              />
            </div>
          ) : null}

          <div>Format</div>
          <CustomSelect
            classNames={{
              head: "calcItemUnitMainDropdown",
              label: "calcItemUnitMainDropdownLabel",
            }}
            onChange={(option) =>
              mergeChange({
                formatType: option.value,
                customFormat: option.format,
              })
            }
            value={value?.formatType || "timestamp"}
            options={[
              { value: "timestamp", label: "Timestamp", format: "1234567890" },
              { value: "time", label: "Time", format: "HH:mm" },
              { value: "date", label: "Date", format: "MM/dd/yyyy" },
              {
                value: "dateAndTime",
                label: "Date And Time",
                format: "MM/dd/yyyy hh:mm a",
              },
              { value: "custom", label: "Custom Format", format: "" },
            ]}
            jointActionRow={
              <div className="calcItemUnitMainDropdownFooter">
                <div className="calcItemUnitMainDropdownFooterArrow">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              </div>
            }
          />

          <div>
            <input
              {...{
                placeholder:
                  value?.formatType === "custom" ? "Enter date format" : " ",
                disabled: value?.formatType !== "custom",
                value: value?.customFormat || "",
                onChange: (e) =>
                  mergeChange({
                    customFormat: e.target.value,
                  }),
              }}
            />
          </div>
        </>
      </SelectorBox>
    );
  },

  screenInformation: (props) => {
    const value = props.value?.[props.valueType] || {};
    const mergeChange = (x) =>
      props.mergeChange({ [props.valueType]: { ...value, ...x } });

    return (
      <SelectorBox title={props.builderValueType.label} key={props.valueType}>
        <CustomSelect
          className=""
          onChange={(option) => mergeChange({ action: option.value })}
          value={value?.action || ""}
          options={["screenWidth", "screenHeight"].map((x) => ({
            value: x,
            label: _.startCase(x),
          }))}
          placeholder={"Select"}
          classNames={{
            head: "calcDropdown",
            label: "calcDropdownLabel",
          }}
          jointActionRow={
            <div className="calcItemUnitMainDropdownFooterArrow">
              <div className="one"></div>
              <div className="two"></div>
            </div>
          }
        />
      </SelectorBox>
    );
  },

  deviceInformation: (props) => {
    const value = props.value?.[props.valueType] || {};
    const mergeChange = (x) =>
      props.mergeChange({ [props.valueType]: { ...value, ...x } });

    return (
      <SelectorBox title={props.builderValueType.label} key={props.valueType}>
        <CustomSelect
          className=""
          onChange={(option) => mergeChange({ action: option.value })}
          value={value?.action || ""}
          options={[
            "deviceModal",
            "deviceVersion",
            "deviceId",
            "notificationToken",
            "microphoneStatus",
            "cameraStatus",
          ].map((x) => ({ value: x, label: _.startCase(x) }))}
          placeholder={"Select"}
          classNames={{
            head: "calcDropdown",
            label: "calcDropdownLabel",
          }}
          jointActionRow={
            <div className="calcItemUnitMainDropdownFooterArrow">
              <div className="one"></div>
              <div className="two"></div>
            </div>
          }
        />
      </SelectorBox>
    );
  },

  distanceBetweenLocation: (props) => {
    return (
      <SelectorBox title={props.builderValueType.label} key={props.valueType}>
        {[0, 1].map((i) => (
          <RichTextData
            {...{
              ...props,
              key: i,
              placeholder: "Custom Address",
              value: props.value?.locations?.[i]?.valueObj,
              onChange: (valueObj) =>
                props.mergeChange({
                  locations: update(props.value?.locations || [], {
                    $merge: {
                      [i]: {
                        valueType: "textParts",
                        valueObj,
                      },
                    },
                  }),
                }),
            }}
          />
        ))}
      </SelectorBox>
    );
  },

  randomValue: (props) => {
    const mergeChange = props.mergeChange;
    return (
      <SelectorBox title={props.builderValueType.label} key={props.valueType}>
        <CustomSelect
          classNames={{
            head: "calcItemUnitMainDropdown adjust spaceTopify",
            label: "calcItemUnitMainDropdownLabel",
          }}
          jointActionRow={
            <div className="calcItemUnitMainDropdownFooter">
              <div className="calcItemUnitMainDropdownFooterArrow">
                <div className="one"></div>
                <div className="two"></div>
              </div>
            </div>
          }
          value={props.value?.randomValue?.method || "alphanumeric"}
          onChange={(option) =>
            mergeChange({
              randomValue: {
                ...(props.value?.randomValue || {}),
                method: option.value,
              },
            })
          }
          options={[
            { value: "alphanumeric", label: "Numbers And Letters" },
            { value: "numeric", label: "Numbers" },
            { value: "uuid", label: "UUID" },
          ]}
        />
        {["uuid"].includes(props.value?.randomValue?.method) ? null : (
          <div>
            <div>Length</div>
            <input
              style={{ width: "100%" }}
              type="number"
              value={props.value?.randomValue?.length || 5}
              onChange={(e) =>
                mergeChange({
                  randomValue: {
                    ...(props.value?.randomValue || {}),
                    length: e.target.value,
                  },
                })
              }
            />
          </div>
        )}
      </SelectorBox>
    );
  },
};

const CalculationValueSelector = (props) => {
  const builderValueType = allBuilderValueTypes.find(
    (x) => x.value === props.valueType
  );

  const commonProps = {
    ...props,
    builderValueType,
    value: props.value || {},
    mergeChange: (obj) => props.onChange({ ...(props.value || {}), ...obj }),
  };

  return <>{selectors[builderValueType?.value]?.(commonProps) || null}</>;
};

export default CalculationValueSelector;
