import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import update from "immutability-helper";

import api from "../../../Services/Api/api";
import ProjectDetailsBase from "./ProjectDetailsBase";

import { connect } from "react-redux";
import PActions from "../../../Stores/redux/Persisted/Actions";
import UnpActions from "../../../Stores/redux/Unpersisted/Actions";
import { Link } from "react-router-dom";

class ProjectEmailsInner extends ProjectDetailsBase {
  state = {
    ...this.state,
    selected: [],
    components:
      this.props.components?.filter((x) => x.category === "emailTemplate") || [],
  };

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true });
      const { components } = await api.get("v1/component", {
        filter: JSON.stringify({
          where: {
            project: this.props.project._id,
            category: "emailTemplate",
          },
        }),
      });
      this.setState({ loading: false, components });
    } catch (e) {
      toast.error(e.mssage);
      this.setState({ loading: false });
    }
  }

  // getNewTemplateName(i = 1) {
  //   const newName = `Template-${i}`;

  //   if (
  //     [...this.props.components, ...this.state.components].find(
  //       (x) => x.name === newName
  //     )
  //   ) {
  //     return this.getNewTemplateName(i + 1);
  //   } else {
  //     return newName;
  //   }
  // }

  generateNewTemplateName() {
    const existingTemplates = [
      ...this.props.components,
      ...this.state.components,
    ];

    const templateNumbers = existingTemplates
      .map(({ name }) => {
        const match = name.match(/^Template-(\d+)$/);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter((num) => num !== null); // Keep only valid template numbers

    const highestNumber =
      templateNumbers.length > 0 ? Math.max(...templateNumbers) : 0;

    return `Template-${highestNumber + 1}`;
  }

  async createNew() {
    try {
      if (this.state.loading) return;
      this.setState({ loading: true });

      const payload = {
        name: this.generateNewTemplateName(),
        project: this.props.project._id,
        category: "emailTemplate",
      };

      await api.post("v1/component", payload);

      this.load();
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      toast.error(e.message);
    }
  }

  async deleteComponents(items) {
    if (!items.length) return;

    let deletedItems = [];
    try {
      this.setState({ loading: true, error: null });
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        let index = this.state.components?.findIndex((x) =>
          item._id ? x._id === item._id : x.uid ? x.uid === item.uid : false
        );

        if (index > -1) {
          if (item?._id) {
            await api.delete("v1/component/" + item._id);
          }
          deletedItems.push(item);
        }
      }
      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: e.message });
      toast.error(e.message);
    }

    if (deletedItems.length) {
      const deletedIds = deletedItems.map((x) => x._id);
      const updated = this.state.components.filter(
        (x) => !deletedIds.includes(x._id)
      );

      this.setState({
        components: updated,
      });
    }
  }

  toggleSelection(item) {
    this.setState({
      selected: this.isSelected(item, this.state.selected)
        ? this.state.selected.filter((x) =>
            item._id ? x._id !== item._id : x.uid !== item.uid
          )
        : [...this.state.selected, item],
    });
  }

  isSelected(item, selected) {
    return !!selected.find((x) =>
      item._id ? x._id === item._id : item.uid ? x.uid === item.uid : false
    );
  }

  render() {
    return (
      <>
        <div className="pageTitle">
          <div>Project Emails</div>
          <span>Create and manage your email templates</span>
        </div>

        <div className="contentBody">
          <div className="dataTableActionRow">
            <div className="minLightButton" onClick={() => this.createNew()}>
              <div className="buttonPlus">
                <div></div>
                <div></div>
              </div>
              Create Template
            </div>
            <div
              className="minButton mbRed"
              onClick={() => this.deleteComponents(this.state.selected)}
            >
              Delete Selected
            </div>
          </div>
          <div className="dataTable">
            <table>
              <thead>
                <tr>
                  <th>id</th>
                  <th>Name</th>
                  <th>Created</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.components.map((item, index) => (
                  <EmailTemplateRow
                    {...{
                      item,
                      index,
                      key: item._id,
                      formatDate: this.formatDate,
                      toggleSelection: () => this.toggleSelection(item),
                      selected: this.isSelected(item, this.state.selected),
                      onSuccess: () => this.load(),
                      deleteComponent: () => this.deleteComponents([item]),
                    }}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

const EmailTemplateRow = (props) => {
  const { item, index, formatDate, toggleSelection, selected } = props;

  const [name, setName] = useState(item?.name || "");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setName(item?.name || "");
  }, [item?.name]);

  const changeName = async () => {
    try {
      console.log("changing name: ", loading);
      if (loading) return;

      setLoading(true);

      await api.put("v1/component", {
        _id: item._id,
        name: name,
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Error updating name: " + e.message);
    }
  };

  return (
    <tr>
      <td>
        <div className="tableData">
          <div
            className={"tdRadio " + (selected ? "active" : "")}
            onClick={() => toggleSelection()}
          >
            <div className="tdRadioDecor"></div>
          </div>
          <div className="tdID">{index + 1}</div>
        </div>
      </td>

      <td>
        <div className="tableData">
          <input
            type="text"
            className="tdInput"
            placeholder="Enter template name"
            value={name || ""}
            onChange={(e) => setName(e.target.value)}
            // onSubmit={() => changeName()}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                changeName();
              }
            }}
          />
        </div>
      </td>
      <td>
        <div className="tableData">{formatDate(item.createdAt)}</div>
      </td>
      <td>
        <div className="tableData">
          <div className="minActionRow">
            <Link className="minButton" to={`/email-template/${item._id}`}>
              Open
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({
  components: state.vState.BUILDER.components,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "BUILDER") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const ProjectEmails = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEmailsInner);

export default ProjectEmails;
