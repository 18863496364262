import React, { useState } from "react";
import { ChromePicker } from "react-color";
import { getSocket } from "../../../../../Providers/socket";
import api from "../../../../../Services/Api/api";

const ColorIconPopup = (props) => {
  const [color, setColor] = useState("");
  const [binaryImage, setBinaryImage] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = async (color) => {
    setLoading(true);
    setColor(color);
    color.hex = color.hex;
    console.log(color);

    const { icon } = await api.get(`v1/icon/download/${props.value.id}`, {
      format: "png",
      color: color.hex,
      size: 128,
    });

    console.log({ binaryImage: icon });
    setBinaryImage(icon);
  };

  const handleApply = async () => {
    try {
      props.setVisible(false);
      let blob;

      if (binaryImage) {
        blob = dataURLToBlob(binaryImage);
      } else {
        const response = await fetch(props.value?.images?.[128]);
        blob = await response.blob();
      }
      if (blob) props.uploadHandler({ target: { files: [blob] } });
    } catch (e) {
      window.alert(e.message);
    }
  };

  const dataURLToBlob = (dataUrl) => {
    const byteString = atob(dataUrl.split(",")[1]);
    const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const styles = {
    container: {
      position: "relative",
    },
    popover: {
      position: "absolute",
      zIndex: "2",
      transform: props.transform || "",
      background: "#fff",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  return (
    <div>
      <div style={styles.cover} onClick={() => props.setVisible(false)} />
      <div style={styles.popover}>
        {loading ? (
          <div
            className="loader"
            style={{
              border: "4px solid #fff",
              borderTop: "4px solid #3498db",
            }}
          ></div>
        ) : null}

        <img
          height="100%"
          src={binaryImage ? binaryImage : props.value?.images?.[128]}
        />

        <div style={{ display: "flex" }}>
          <button onClick={handleApply}>Apply</button>
          <br />
          <button onClick={() => props.setVisible(false)}>Cancel</button>
        </div>

        <ChromePicker color={color.rgb} onChange={handleChange} />
      </div>
    </div>
  );

  return (
    <div style={styles.holder}>
      <div style={{ height: "80px" }}>
        <div style={{ float: "right" }}>
          <button onClick={handleApply}>Apply</button>
          <br />
          <button onClick={() => props.setVisible(false)}>Cancel</button>
        </div>
        {/* <Data /> */}
        {loading ? (
          <span
            className={"loader"}
            style={{ top: "unset", left: "unset", right: "unset" }}
          />
        ) : (
          ""
        )}
        <img
          height="100%"
          src={
            binaryImage
              ? `data:image/png;base64,${binaryImage}`
              : props.value?.images?.png?.[128]
          }
        />
      </div>
      <ChromePicker color={color.rgb} onChange={handleChange} />
    </div>
  );
};

// const ColorIconPopup1 = (props) => {
//   const [color, setColor] = useState("");
//   const [binaryImage, setBinaryImage] = useState(false);
//   const [loading, setLoading] = useState(false);

//   const styles = {
//     holder: {
//       margin: "auto",
//     },
//     popover: {
//       // position: "absolute",
//       borderRadius: "7px",
//       background: "#efefef",
//       position: "absolute",
//       borderRadius: "7px",
//       background: "rgb(239, 239, 239)",
//       /* margin: -200px auto 0px -200px; */
//       padding: "6px",
//       width: "223px",
//       left: "-2px",
//       height: "322px",
//       boxShadow: "grey 10px 10px 5px",
//       transform: "translateY(-78px)",
//       border: "0.5px solid lightgray",
//     },
//     cover: {
//       // position: "fixed",
//       // zIndex: 2,
//       // top: "0px",
//       // right: "0px",
//       // bottom: "0px",
//       // left: "0px"
//     },
//     resultContainer: {
//       display: "flex",
//       flexWrap: "wrap",
//       overflow: "auto",
//       maxHeight: "300px",
//     },
//     icon: {
//       // width: "60px"
//       height: "50px",
//       padding: "5px",
//     },
//   };

//   const handleChange = (color) => {
//     setLoading(true);
//     setColor(color);
//     color.hex = color.hex.replace("#", "");
//     console.log(color);
//     getSocket()?.emit(
//       "downloadIcon",
//       {
//         id: props.urlObj.id,
//         format: "png",
//         qs: { color: color.hex, size: 64 },
//       },
//       function (err, data) {
//         setLoading(false);
//         var image = btoa(String.fromCharCode(...new Uint8Array(data)));
//         setBinaryImage(image);
//       }
//     );
//   };

//   const handleApply = () => {
//     props.setShowColorPicker(false);
//     getSocket()?.emit(
//       "downloadIcon",
//       {
//         id: props.urlObj.id,
//         format: "png",
//         output: "url",
//         qs: { color: color.hex, size: 128 },
//       },
//       function (err, path) {
//         console.log(err, path);
//         props.iconChooseHandler(
//           props.urlObj,
//           process.env.REACT_APP_API_URL + "/" + path
//         );
//       }
//     );

//     // save icon via socket api on server and use as static image
//   };
//   return (
//     <div
//       style={styles.cover}
//       className="popup-cover"
//       onClick={() => props.setShowColorPicker(false)}
//     >
//       <div
//         style={Object.assign(styles.popover, props.style)}
//         onClick={(e) => e.stopPropagation()}
//       >
//         <div style={styles.holder}>
//           <div style={{ height: "80px" }}>
//             <div style={{ float: "right" }}>
//               <button onClick={handleApply}>Apply</button>
//               <br />
//               <button onClick={() => props.setShowColorPicker(false)}>
//                 Cancel
//               </button>
//             </div>
//             {/* <Data /> */}
//             {loading ? (
//               <span
//                 className={"loader"}
//                 style={{ top: "unset", left: "unset", right: "unset" }}
//               />
//             ) : (
//               ""
//             )}
//             <img
//               height="100%"
//               src={
//                 binaryImage
//                   ? `data:image/png;base64,${binaryImage}`
//                   : props.urlObj &&
//                     props.urlObj.images &&
//                     props.urlObj.images.png[128]
//               }
//             />
//           </div>
//           <ChromePicker color={color.rgb} onChange={handleChange} />
//         </div>
//       </div>
//     </div>
//   );
// };

const styles = {
  holder: {
    margin: "auto",
  },
  popover: {
    // position: "absolute",
    borderRadius: "7px",
    background: "#efefef",
    position: "absolute",
    borderRadius: "7px",
    background: "rgb(239, 239, 239)",
    /* margin: -200px auto 0px -200px; */
    padding: "6px",
    width: "223px",
    left: "-2px",
    height: "322px",
    boxShadow: "grey 10px 10px 5px",
    transform: "translateY(-78px)",
    border: "0.5px solid lightgray",
  },
  cover: {
    // position: "fixed",
    // zIndex: 2,
    // top: "0px",
    // right: "0px",
    // bottom: "0px",
    // left: "0px"
  },
  resultContainer: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
    maxHeight: "300px",
  },
  icon: {
    // width: "60px"
    height: "50px",
    padding: "5px",
  },
};

export default ColorIconPopup;
