import React from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import update from "immutability-helper";

import ToggleButton from "../../../Components/Input/ToggleButton";
import DataCondition from "../../Common/DataQueryOperations/DataCondition";
import ContainerProperties from "./ElementTypes/container/ContainerProperties";
import TextProperties from "./ElementTypes/text/TextProperties";
import ImageProperties from "./ElementTypes/image/ImageProperties";
import InputProperties from "./ElementTypes/input/InputProperties";
import MapProperties from "./ElementTypes/map/MapProperties";
import MediaProperties from "./ElementTypes/media/MediaProperties";

import _pp from "../../../appxolo-engine/modules/passedParameter/passedParemeter";
import OptionItem from "../../../Components/etc/OptionItem";
const { getContainerPassedParameters } = _pp;

class ElementProperties extends React.Component {
  render() {
    const {
      focusedElement: {
        indices,
        element: { id: elementType },
      },
      dom,
      databases,
    } = this.props;

    const parents = dom.findNodeAndParentsByIndices(indices);
    const element = parents[parents.length - 1];

    if (!element || elementType !== element.id) return null;

    const data = element.value?.data || {};
    const setData = (data) =>
      dom.updateNodeValue(element.id, { ...(element.value || {}), data });
    const updateData = (obj) => setData({ ...data, ...obj });

    const passedParameters = getContainerPassedParameters(parents, {
      databases,
    });

    const activeTab = data?.tabs?.[data?.activeTabIndex || 0];

    const optionHeadTabs = (
      <ToggleButton
        classNames={{ container: "optionHeadTabs", item: "optionHeadTabItem" }}
        overideDefaultStyle
        value={data.dataType}
        onChange={(x) =>
          updateData({
            dataType: x,
            activeTabIndex: x === "fixed" ? 0 : data.activeTabIndex,
          })
        }
        options={["fixed", "conditional"]?.map((x) => ({
          value: x,
          label: _.startCase(x),
        }))}
      />
    );

    return (
      <div className="properties" key={activeTab?.uid}>
        <div className="optionsHeader">
          <div className="optionsElementName">
            {_.startCase(element.value?.elementType)} <span>Element</span>
          </div>
          <div className="optionsElementMeta">
            ID: <span></span>
            {element.id}
          </div>
        </div>

        <OptionItem
          className="mainCondition"
          optionItemLabel="Data"
          optionHeadTabs={optionHeadTabs}
        >
          {data.dataType === "conditional" ? (
            <DataCondition
              {...{ ...this.props, passedParameters }}
              value={data}
              onChange={updateData}
            />
          ) : null}
        </OptionItem>

        {{
          container: (props) => <ContainerProperties {...props} />,
          text: (props) => <TextProperties {...props} />,
          image: (props) => <ImageProperties {...props} />,
          input: (props) => <InputProperties {...props} />,
          map: (props) => <MapProperties {...props} />,
          media: (props) => <MediaProperties {...props} />,
        }[element.value.elementType]?.({
          ...this.props,
          passedParameters,
          value: data.tabs?.[data.activeTabIndex || 0],
          onChange: (tab) =>
            updateData({
              ...data,
              tabs: update(data.tabs || [], {
                $merge: { [data.activeTabIndex || 0]: tab },
              }),
            }),
        })}

        <div className="box d-grid gap-2">
          <Button
            variant="danger"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure, you want to delete the selected element ?"
                )
              )
                dom.deleteNodeById(element.id);
            }}
          >
            Delete Element
          </Button>
        </div>
      </div>
    );
  }
}

export default ElementProperties;
