import React from "react";
import { useParams } from "react-router-dom";
import Example from "./Components/Example";
import Home from "./Components/HomeExample";

const components = {
  example: <Example />,
  home: <Home />,
};

const DesignScreen = (props) => {
  const params = useParams();

  return <>{components[params?.component] || "NOT FOUND"}</>;
};

export default DesignScreen;
