import React from "react";
import update from "immutability-helper";

import ToggleButton from "../../../../../Components/Input/ToggleButton";
import StaticImageSources from "../../Common/ImageSources/StaticImageSources";
import DatabaseDataSelector from "../../../../Common/DatabaseOperations/DatabaseDataSelector";

const MapIcon = (props) => {
  const { value, onChange, specificIconOption = false } = props;

  const iconObj = value || {};
  const setIconObj = (obj) => {
    onChange(update(iconObj, { $merge: obj }));
  };

  return (
    <div style={styleObj.container}>
      <div style={styleObj.title}>Map Icons</div>
      {specificIconOption ? (
        <div>
          <div style={styleObj.navswitch}>
            <ToggleButton
              value={iconObj.type}
              onChange={(value) => setIconObj({ type: value })}
              options={[
                { label: "Global", value: "global" },
                { label: "Specific", value: "specific" },
              ]}
              style={{
                container: { fontSize: "x-small" },
              }}
            />
          </div>
          {iconObj.type === "specific" ? (
            <div style={styleObj.filterContainer}>
              <DatabaseDataSelector
                {...{
                  ...props,
                  value: iconObj.databaseFilter,
                  onChange: (value) => setIconObj({ databaseFilter: value }),
                  valueTypeData: { tableSelector: { maxNoOfInputs: 0 } },
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <div style={styleObj.iconContainer}>
        <StaticImageSources
          {...{
            ...props,
            maxLength: 1,
            value: iconObj.src ? [iconObj.src] : null,
            onChange: (urls) => setIconObj({ src: urls[0] }),
            styles: {
              imagePreview: {
                width: (iconObj.width || 36) + "px",
                height: (iconObj.height || 36) + "px",
              },
            },
          }}
        />

        <div style={styleObj.dimension}>
          <div>
            <span>Width</span>{" "}
            <input
              style={styleObj.input1}
              value={iconObj.width || 36}
              onChange={(event) => setIconObj({ width: event.target.value })}
              type="number"
            />
          </div>
          <div>
            <span>Height</span>{" "}
            <input
              style={styleObj.input1}
              type="number"
              value={iconObj.height || 36}
              onChange={(event) => setIconObj({ height: event.target.value })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styleObj = {
  container: {
    background: "#d0d0d0",
    padding: "6px",
  },
  title: {
    color: "white",
    fontSize: "xx-small",
    padding: "3px",
  },
  navswitch: {
    backgroundColor: "white",
    padding: "6px",
  },
  iconContainer: {
    backgroundColor: "white",
    padding: "6px",
  },
  filterContainer: {
    border: "2px solid white",
  },
  dimension: {
    display: "flex",
    border: "1px solid gray",
    fontSize: "xx-small",
    padding: "4px",
  },
};
export default MapIcon;
