import React, { useCallback, useEffect } from "react";
import update from "immutability-helper";

import linkingObj from "../../../appxolo-engine/modules/linking/linking-obj";
import ToggleButton from "../../../Components/Input/ToggleButton";
import AddRecord from "./AddRecord";
import EditRecord from "./EditRecord";
import DeleteRecord from "./DeleteRecord";
import SuccessFailHandler from "../Linking/SuccessFailHandler";
import CustomSelect from "../../../Components/etc/CustomSelect";

const RowOperations = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;

  const operations = value.operations || [];
  const valueTypes = linkingObj.valueTypes.find(
    (x) => x.value === "rowOperations"
  ).valueTypes;

  const setOperations = useCallback(
    (operations) => {
      const mergeChange = (obj) => onChange({ ...value, ...obj });
      mergeChange({ operations });
    },
    [value, onChange]
  );

  const appendOperation = useCallback(() => {
    setOperations(
      update(operations, {
        $push: [{ uid: Math.random(), valueType: "addRecord" }],
      })
    );
  }, [operations, setOperations]);

  const deleteOperation = (i) => {
    setOperations(update(operations, { $splice: [[i, 1]] }));
  };

  const setOperation = (val, i) => {
    setOperations(update(operations, { $merge: { [i]: val } }));
  };

  const setOperationObj = (obj, i) => {
    setOperations(update(operations, { [i]: { $merge: obj } }));
  };

  useEffect(() => {
    if (!operations?.length) appendOperation();
  }, [operations, appendOperation]);

  const OperationObj = {
    addRecord: (operation, i) => (
      <AddRecord
        {...{
          ...props,
          value: operation,
          recordIdOperation: true,
          onChange: (operation) => setOperation(operation, i),
        }}
      />
    ),
    editRecord: (operation, i) => (
      <EditRecord
        {...{
          ...props,
          recordIdOperation: true,
          value: operation,
          onChange: (operation) => setOperation(operation, i),
        }}
      />
    ),
    deleteRecord: (operation, i) => (
      <DeleteRecord
        {...{
          ...props,
          recordIdOperation: false,
          value: operation,
          onChange: (operation) => setOperation(operation, i),
        }}
      />
    ),
  };

  return (
    <>
      {operations.map((operation, i) => {
        const valueTypeData = valueTypes.find(
          (x) => x.value === operation.valueType
        );

        return (
          <div key={operation?.uid || i} className="linkingComponent">
            <div className="databaseItemHead">
              <div className="databaseItemHeadOrder">{i + 1}</div>

              <CustomSelect
                value={operation && operation.valueType}
                onChange={(option) =>
                  setOperationObj({ valueType: option.value }, i)
                }
                options={valueTypes}
                placeholder={"Select"}
                className={
                  "calcDropdown " + valueTypeData?.shortLabel?.toLowerCase()
                }
                classNames={{
                  head:
                    "calcDropdown " + valueTypeData?.shortLabel?.toLowerCase(),
                  label: "calcDropdownLabel",
                }}
                jointActionRow={
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                }
              />
            </div>

            <div className="linkingBoxBody">
              <div className="linkingBoxDecor"></div>
              <div className="linkingBoxItem">
                <div className="removeX" onClick={() => deleteOperation(i)}>
                  <div className="removeXLine one"></div>
                  <div className="removeXLine two"></div>
                </div>

                {OperationObj[operation?.valueType]?.(operation, i)}
              </div>
            </div>
          </div>
        );
      })}

      <div key={"append"} style={styleObj.button} onClick={appendOperation}>
        Add Operation
      </div>

      {linkBoxOptions?.showTriggerOption ? (
        <div className="linkingBoxFooter">{triggerOption}</div>
      ) : null}
    </>
  );
};

const OperationSelector = (props) => {
  const { value, onChange, options, delete: deleteOperation } = props;
  const selectedOption = value;

  return (
    <div style={styleObj.titleBox}>
      <div>
        <select
          style={styleObj.titleDD}
          value={selectedOption || ""}
          onChange={(event) => onChange(event.target.value)}
        >
          <option
            disabled={true}
            value=""
            style={Object.assign({}, styleObj.option, { color: "#ddd" })}
          >
            -- Select --
          </option>
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              style={styleObj.option}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <NavSwitch {...props} />
        {deleteOperation ? (
          <div
            style={{}}
            onClick={() => deleteOperation()}
            className="roundMinus"
          >
            &#8211;
          </div>
        ) : null}
      </div>
    </div>
  );
};

const NavSwitch = ({ value, onChange, options }) => {
  return (
    <div style={styleObj.flex2}>
      <ToggleButton
        {...{
          value,
          onChange,
          options: options.map((x) => ({ ...x, label: x.shortLabel })),
        }}
      />
    </div>
  );
};

const styleObj = {
  flex2: {
    display: "flex",
    background: "lightgray",
    padding: " 1px",
    borderRadius: "7px",
    fontSize: "7px",
    float: "right",
  },
  switch: {
    padding: "2px",
    cursor: "pointer",
    width: "22px",
  },
  activeSwitch: {
    padding: "2px",
    background: "gray",
    color: "white",
    borderRadius: "13px",
    width: "22px",
    cursor: "pointer",
  },
  option: {
    color: "#333",
  },
  button: {
    backgroundColor: "white",
    fontSize: "x-small",
    color: "green",
    padding: "5px",
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: "3px",
    cursor: "pointer",
  },
  titleBox: {
    backgroundColor: "#09bfca",
    borderRadius: "6px 6px 0px 0",
    padding: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleDD: {
    border: 0,
    background: 0,
    color: "white",
    fontSize: "11px",
    maxWidth: "100px",
  },
  selectorContainer: {
    backgroundColor: "#00f0ff",
    padding: "3px",
    borderRadius: "0px 0px 6px 6px",
    marginBottom: "7px",
  },
};

export default RowOperations;
