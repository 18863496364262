import etcModule from "./etc/etc-module";
import authModule from "./auth/auth-module";
import navigationModule from "./navigation/navigation-module";

const modules = {
  etc: etcModule,
  auth: authModule,
  navigation: navigationModule
};
export default modules;
