import React from "react";
import _ from "lodash";

import ToggleButton from "../../../Components/Input/ToggleButton";
import CustomUrlParameters from "./CustomUrlParameters";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import {
  LinkingOptionItemBody,
  OptionItemBody,
} from "../../../Components/etc/OptionItem";
import CustomSelect from "../../../Components/etc/CustomSelect";

export const LinkToURLNav = ({ valueType, setValueType }) => (
  <ToggleButton
    value={valueType}
    onChange={setValueType}
    options={[
      { value: "linkToScreen", label: "Screen" },
      { value: "linkToURL", label: "URL" },
    ]}
  />
);

const LinkToURL = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">Destination</div>

            <RichTextData
              {...{
                ...props,
                immediateProps: {
                  classNames: {
                    wrapper: " ",
                    editor: "calcDatabaseFilterMultiBox spaceTopify",
                    paramListRow: "",
                  },
                },
                placeholder: "http://example.com",
                value: value?.to?.valueObj,
                onChange: (valueObj) => {
                  mergeChange({
                    to: {
                      valueObj,
                      valueType: "textParts",
                    },
                  });
                },
              }}
            />
          </div>
          <CustomUrlParameters
            {...{
              ...props,
              value: value.urlParameters,
              onChange: (urlParameters) => mergeChange({ urlParameters }),
            }}
          />
        </div>
      </LinkingOptionItemBody>

      <div className="linkingBoxFooter">
        <div className="optionItemBody">
          <div className="linkLabel">Window</div>
          <div className="optionItemRow">
            <CustomSelect
              value={value.target || "current"}
              onChange={(option) => mergeChange({ target: option.value })}
              options={["current", "new"]?.map((x) => ({
                value: x,
                label: _.startCase(x),
              }))}
              placeholder={"Select"}
              className="linkingToBox flexColumn flex1 br10 pad6"
              classNames={{
                head: "pad6 flexRow aCenter",
                label: "cDark flex1 cPurple",
                chevron: "optionDatabaseSortKeyIco",
              }}
            />
          </div>
        </div>

        {linkBoxOptions.showTriggerOption ? triggerOption : null}
      </div>
    </>
  );
};

export default LinkToURL;
