import React, { useState } from "react";
import { toast } from "react-toastify";
import ImageInput from "../../../Components/Input/ImageInput";
import api from "../../../Services/Api/api";
import config from "../../../Config";
import SearchInput from "../../../Components/Input/SearchInput";
import withRouter from "../../../Components/Wrapper/with-router";
import ProjectDetailsBase from "./ProjectDetailsBase";

class ProjectDetailsInfo extends ProjectDetailsBase {
  updateLogo = async (file) => {
    try {
      if (this.state.loadingObj.image) return;

      this.setLoading("image", true);
      let image = null;
      if (file) {
        const fileRes = await api.media("v1/file", { file });
        image = fileRes?.file?._id;
      }

      await this.submitEdit({ image }).catch(async (e) => {
        if (image) {
          api.delete(`v1/file/${image}`).catch(console.error);
        }
        throw e;
      });
      this.setLoading("image", false);
    } catch (e) {
      this.setLoading("image", false);
      toast.error(e.message);
    }
  };

  handleSubdomain = async (subdomain) => {
    try {
      this.setState({ project: { ...this.state.project, subdomain } });

      this.setToggleables("subdomainCheck", null);
      const { available } = await api.get("v1/project/subdomain-check", {
        subdomain,
        projectId: this.state.project._id,
      });

      if (available) {
        this.setToggleables("subdomainCheck", subdomain);
      } else {
        this.setToggleables("subdomainCheck", false);
      }
    } catch (error) {
      this.setToggleables("subdomainCheck", null);
    }
  };

  getProductionDomainName() {
    const name = this.props.project?.productionDomains?.[0]?.name || "";

    let result;

    const nameParts = name.split(".");

    if (!name) {
      result = `${this.props.project?.subdomain || ""}.${config.frontDomain}`;
    } else if (nameParts.length > 2) {
      result = `https://${name}`;
    } else {
      result = `https://${name}, https://www.${name}`;
    }

    return result;
  }

  async checkConnection() {
    try {
      if (this.state.loadingObj.domainConnectionCheck) return;

      const domain = this.state.project?.productionDomains?.[0]?.name || "";

      this.setState({ domainConnectionCheck: null, connectionTestMessage: "" });
      this.setLoading("domainConnectionCheck", true);

      const { connected, message } = await api.get(
        "v1/project/domain-connection-check",
        {
          project: this.state.project._id,
          ip: config.frontProductionIp,
          domain,
        }
      );
      this.setState({
        domainConnectionCheck: connected,
        connectionTestMessage: message,
      });
      this.setLoading("domainConnectionCheck", false);

      if (
        connected &&
        domain === this.props.project?.productionDomains?.[0]?.name
      ) {
        this.submit(
          {
            productionDomains: this.state.project.productionDomains?.map(
              (x) => ({ ...x, name: x.name.replace(/^www\./, "") })
            ),
          },
          "productionDomains"
        );
      }
    } catch (e) {
      toast.error(e.message);
      this.setState({
        connectionTestMessage: `Connection test failed: ${e.message}`,
        domainConnectionCheck: false,
      });
      this.setLoading("domainConnectionCheck", false);
    }
  }

  render() {
    const {
      project,
      toggleables,
      loadingObj,
      connectionTestMessage = "",
      domainConnectionCheck = null,
    } = this.state;
    const { project: projectProps } = this.props;

    return (
      <>
        <div className="pageTitle">
          <div>Project Details</div>
          <span>View and manage your project</span>
        </div>

        <div className="contentBody">
          <div className="tLabel mb10">Information</div>

          <div className="borderBox mb20">
            <ImageInput
              value={api.getFileLink(project.image)}
              onChange={(image) => this.updateLogo(image)}
              loading={loadingObj.image}
            />

            <div>
              <div className="borderItem">
                <div className="mLabel">App Name</div>
                <input
                  type="text"
                  className="mInput"
                  placeholder="App Name"
                  value={project.name || ""}
                  onChange={(e) =>
                    this.setState({
                      project: { ...project, name: e.target.value },
                    })
                  }
                />

                <div className="minActionRow minAdjust">
                  <div
                    className="minButton"
                    onClick={() => this.submit({ name: project.name }, "name")}
                  >
                    {loadingObj.name ? "Saving" : "Save"}
                  </div>
                </div>
              </div>

              <div className="borderItem">
                <div className="mLabel">App Type</div>
                <div className="mValue">Mobile Application</div>
              </div>
            </div>
          </div>

          <div className="tLabel mb10">Status</div>
          <div className="borderBox mb20">
            <div className="borderItem">
              <div className="mLabel">iOS App</div>
              <div className="publishRow">
                <div className="mValue">Unpublished</div>
                <div className="publishAction">Apply</div>
              </div>

              <div className="publishDetails">
                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">iOS App Name</div>
                    <input
                      type="text"
                      className="mInput"
                      placeholder="App Name"
                    />
                  </div>
                </div>

                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">App ID</div>
                    <input type="text" className="mInput" placeholder="" />
                  </div>
                  <div className="pbItem">
                    <div className="pbLabel">Bundle Code</div>
                    <input type="text" className="mInput" placeholder="" />
                  </div>
                </div>

                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">Camera permission text</div>
                    <input type="text" className="mInput" placeholder="" />
                  </div>
                </div>

                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">Microphone permission text</div>
                    <input type="text" className="mInput" placeholder="" />
                  </div>
                </div>

                <div className="minActionRow">
                  <div className="minButton">Submit</div>
                  <div className="minLightButton">Cancel</div>
                </div>
              </div>
            </div>
            <div className="borderItem">
              <div className="mLabel">Android App</div>
              <div className="publishRow">
                <div className="mValue">
                  Published on <span>04/12/2024 3:22pm</span>
                </div>
                <div className="publishAction">Republish</div>
              </div>
            </div>
          </div>

          <div className="tLabel mb10">Status</div>
          <div className="borderBox mb20">
            <div className="borderItem">
              <div className="mLabel">Staging subdomain</div>
              <div className="publishRow">
                <div className="mValue">
                  {`${projectProps?.subdomain || ""}.${config.frontDomain}`}
                </div>
                <div
                  className="publishAction"
                  onClick={() =>
                    this.setToggleables("subdomain", !toggleables.subdomain)
                  }
                >
                  Change
                </div>
              </div>

              <div
                className="publishDetails"
                style={
                  toggleables.subdomain
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">Staging Subdomain</div>
                    <div className="pbDomainRow">
                      <SearchInput
                        type="text"
                        className="mInput"
                        placeholder="subdomain"
                        value={project.subdomain || ""}
                        onChange={(text) => this.handleSubdomain(text)}
                      />
                      <div className="pbDomain">.{config.frontDomain}</div>
                    </div>
                    {toggleables.subdomainCheck === project.subdomain ? (
                      <div className="domainLabel mt10 success">
                        Subdomain is available
                      </div>
                    ) : toggleables.subdomainCheck === false ? (
                      <div className="domainLabel mt10 fail">
                        Subdomain is not available
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="minActionRow">
                  <div
                    className="minButton"
                    onClick={() =>
                      this.submit(
                        { subdomain: project.subdomain },
                        "subdomain",
                        {
                          onSuccess: () =>
                            this.setToggleables("subdomain", false),
                        }
                      )
                    }
                  >
                    Submit
                  </div>
                  <div
                    className="minLightButton"
                    onClick={() => {
                      this.setToggleables("subdomain", false);
                      this.setState({
                        project: {
                          ...project,
                          subdomain: projectProps?.subdomain,
                        },
                      });
                    }}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
            <div className="borderItem">
              <div className="mLabel">Production domain</div>
              <div className="publishRow">
                <div className="mValue">{this.getProductionDomainName()}</div>
                <div
                  className="publishAction"
                  onClick={() =>
                    this.submit(
                      {
                        productionDomains: project.productionDomains?.map(
                          (x) => ({ ...x, name: x.name.replace(/^www\./, "") })
                        ),
                      },
                      "productionDomains"
                    )
                  }
                >
                  Publish
                </div>
              </div>

              <div className="publishDetails">
                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">Domain Name</div>
                    <input
                      type="text"
                      className="mInput"
                      placeholder="example.com"
                      value={project?.productionDomains?.[0]?.name || ""}
                      onChange={(e) =>
                        this.setState({
                          domainConnectionCheck: null,
                          project: {
                            ...this.state.project,
                            productionDomains: [
                              {
                                name: e.target.value.toLowerCase(),
                              },
                            ],
                          },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="minActionRow">
                  <div
                    className="minButton"
                    onClick={() => this.checkConnection()}
                  >
                    Check Connection
                  </div>
                  <div
                    className={
                      "domainLabel " +
                      (domainConnectionCheck === false
                        ? "fail"
                        : domainConnectionCheck === true
                        ? "success"
                        : "")
                    }
                  >
                    {this.state.loadingObj.domainConnectionCheck
                      ? "Checking.."
                      : connectionTestMessage}
                  </div>
                </div>
              </div>

              <div className="publishDetails">
                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">A Record</div>
                    <input
                      type="text"
                      className="mInput"
                      defaultValue={config.frontProductionIp}
                      disabled
                    />
                  </div>
                </div>
                {/* <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">CNAME</div>
                    <input
                      type="text"
                      className="mInput"
                      defaultValue="appxolo"
                    />
                  </div>
                </div>
                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">TXT</div>
                    <input
                      type="text"
                      className="mInput"
                      defaultValue="g7df78d87g98sf"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="tLabel mb10">Details</div>
          <div className="borderBox mb20">
            <div>
              <div className="borderItem">
                <div className="mLabel">Screens</div>
                <div className="mValue">
                  {
                    this.props.components?.filter(
                      (x) => x.category === "screen"
                    )?.length
                  }
                </div>
              </div>

              <div className="borderItem">
                <div className="mLabel">App Description</div>
                <div className="mValue">{project.description}</div>
              </div>

              <div className="borderItem">
                <div className="mLabel">Action</div>
                <div className="minActionRow jStart">
                  <div className="minButton mbGray">Transfer App</div>
                  <div
                    className="minButton mbRed"
                    onClick={() => this.deleteProject()}
                  >
                    Delete App
                  </div>
                </div>

                <div className="publishDetails">
                  <div className="pbRow">
                    <div className="pbItem">
                      <div className="pbLabel">Send to:</div>
                      <input
                        type="text"
                        className="mInput"
                        placeholder="example@gmail.com"
                      />
                    </div>
                  </div>
                  <div className="minActionRow">
                    <div className="minButton">Submit</div>
                    <div className="minLightButton">Cancel</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProjectDetailsInfo;
