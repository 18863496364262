import React from "react";
import OptionItem, {
  OptionFourInputs,
} from "../../../../../Components/etc/OptionItem";

const BorderRadius = (props) => {
  return (
    <OptionItem optionItemLabel="Radius">
      <div className="optionItemBody">
        <OptionFourInputs
          {...{
            ...props,
            options: [
              { key: "borderTopLeftRadius", iconClass: "rotate315" },
              { key: "borderTopRightRadius", iconClass: "rotate45" },
              { key: "borderBottomRightRadius", iconClass: "rotate135" },
              { key: "borderBottomLeftRadius", iconClass: "rotate225" },
            ],
          }}
        />
      </div>
    </OptionItem>
  );
};

export default BorderRadius;
