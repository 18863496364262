import React from "react";

import { LinkingOptionItemBody } from "../../../Components/etc/OptionItem";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";

const ShareToExternal = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">Content</div>

            <RichTextData
              {...{
                ...props,
                immediateProps: {
                  classNames: {
                    wrapper: " ",
                    editor: "calcDatabaseFilterMultiBox spaceTopify",
                    paramListRow: "",
                  },
                },
                placeholder: "Type here",
                value: value?.content?.valueObj,
                onChange: (valueObj) => {
                  mergeChange({
                    content: {
                      valueObj,
                      valueType: "textParts",
                    },
                  });
                },
              }}
            />
          </div>
        </div>
      </LinkingOptionItemBody>

      {linkBoxOptions.showTriggerOption ? (
        <div className="linkingBoxFooter"> {triggerOption}</div>
      ) : null}
    </>
  );
};

export default ShareToExternal;
