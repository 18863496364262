import React from "react";
import update from "immutability-helper";
import _ from "lodash";

import RichTextEditor from "../../../../../Components/Input/RichTextEditor";
import { CalculationTrigger } from "../../../../Common/DataQueryOperations/CalculationBox";

class RichTextData extends React.Component {
  richTextChangeTimer = 0;

  generateRandomId() {
    return Math.random().toString(36).substring(2, 10);
  }

  richtextToParts(richTextParts) {
    const existingParts = this.props.value?.textParts || [];
    return richTextParts.map((x) => {
      if (x.type === "text") {
        return {
          id: x.data?.id || this.generateRandomId(),
          valueType: "customText",
          valueObj: {
            customText: x.content || "",
          },
        };
      } else {
        const existingPart =
          existingParts.find((part) => part.id === x.data.id) || {};
        return {
          ...existingPart,
          id: x.data.id,
          valueType: existingPart.valueType || "calculation",
        };
      }
    });
  }

  onRichTextChange(richTextParts) {
    clearTimeout(this.richTextChangeTimer);
    this.richTextChangeTimer = setTimeout(() => {
      const textParts = this.richtextToParts(richTextParts);
      this.props.onChange({ ...(this.props.value || {}), textParts });
    }, 200);
  }

  mergeChange(obj) {
    return this.props.onChange({ ...this.props.value, ...obj });
  }

  getCustomComponentLabel(part) {
    return part.valueType === "passedParameter"
      ? part.valueObj?.[part.valueType]?.valueObj?.optionLabel ||
          _.startCase(part.valueType)
      : part.valueType === "calculation"
      ? _.startCase(part.valueType)
      : part.valueObj?.customText;
  }

  render() {
    const {
      textParts = [],
      activePartId,
      updatedAt = 0,
    } = this.props.value || {};

    const { placeholder = "Type Here" } = this.props;

    const activePartIndex = textParts.findIndex(
      (x) => activePartId && x?.id === activePartId
    );
    const part = textParts[activePartIndex];

    const setPart = (part, index) =>
      this.mergeChange({
        textParts: update(textParts || [], { $merge: { [index]: part } }),
        activePartIndex: index,
        updatedAt: Date.now(),
      });

    const richTextParts = textParts.map((x) => ({
      type: x.valueType === "customText" ? "text" : "custom",
      content: this.getCustomComponentLabel(x) || "",
      data: {
        id: x.id,
        valueType: x.valueType,
      },
    }));

    return (
      <div style={{ position: "relative" }}>
        <RichTextEditor
          immediateProps={this.props.immediateProps}
          key={updatedAt.toString()}
          defaultValue={richTextParts}
          placeholder={placeholder}
          onChange={(richTextParts) => this.onRichTextChange(richTextParts)}
          onClick={(x) => this.mergeChange({ activePartId: x?.id })}
          onInsert={(x) => this.mergeChange({ activePartId: x?.id })}
        />

        {part ? (
          <AutoCalculationTrigger
            {...{
              ...this.props,
              immediateProps: null,
              value: part,
              onChange: (part) => setPart(part, activePartIndex),
              onHide: () => this.mergeChange({ activePartId: null }),
            }}
          />
        ) : null}
      </div>
    );
  }
}

class AutoCalculationTrigger extends React.Component {
  render() {
    const { value = {}, onChange } = this.props;
    const valueType = "calculation";
    const mergeChange = (obj) => onChange({ ...value, ...obj });

    const valueObjProps = {
      ...this.props,
      value: value.valueObj?.[valueType],
      onChange: (x) =>
        mergeChange({
          valueObj: { ...(value.valueObj || []), [valueType]: x },
        }),
    };

    return (
      <CalculationTrigger
        {...{ ...valueObjProps, visible: true }}
        style={{ display: "none" }}
      />
    );
  }
}

export default RichTextData;
