import React from "react";
import ValueOfElementSelector from "../DataQueryOperations/ValueOfElementSelector";

const UpdateElement = (props) => {
  return (
    <>
      <div className="box" key={props.valueType}>
        <div>Element Id</div>
        <ValueOfElementSelector
          {...{
            ...props,
            elementType: "container",
            styles: { dd: { width: "100%" } },
            value: props.value?.element,
            onChange: (x) =>
              props.onChange({ ...(props.value || {}), element: x }),
          }}
        />
      </div>
    </>
  );
};

export default UpdateElement;
