import React from "react";
import PassedParamSelector from "./PassedParamSelector";
import ValueOfElementSelector from "./ValueOfElementSelector";
import { CalculationTrigger } from "./CalculationBox";
import LocationInput from "./LocationInput";

const InputSelector = (props) => {
  const { valueType, value, onChange } = props;
  const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

  const propsToBePassed = {
    ...props,
    value: value?.[valueType],
    onChange: (x) => mergeChange({ [valueType]: x }),
  };

  switch (valueType) {
    case "recordId":
    case "currentValue":
    case "basedOnData":
    case "currentLocation":
    case "mapCenter":
      return null;
    case "passedParameter":
      return <PassedParamSelector {...propsToBePassed} />;
    case "valueOfInputElement":
      return (
        <ValueOfElementSelector
          {...{ ...propsToBePassed, elementType: "input" }}
        />
      );
    case "calculation":
      return <CalculationTrigger {...propsToBePassed} />;
    case "customAddress":
      return <LocationInput {...propsToBePassed} />;
    case "customText":
    default:
      return (
        <input
          className="underline-input"
          type="text"
          placeholder="Type here.."
          value={value?.customText || ""}
          onChange={(event) => mergeChange({ customText: event.target.value })}
        />
      );
  }
};

export default InputSelector;
