import React from "react";
import _ from "lodash";

import { LinkingOptionItemBody } from "../../../Components/etc/OptionItem";
import CustomSelect from "../../../Components/etc/CustomSelect";
import ValueOfElementSelector from "../DataQueryOperations/ValueOfElementSelector";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";

export const SetElementStyle = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">Element</div>

            <ValueOfElementSelector
              {...{
                ...props,
                elementType: null,
                elementFilter: () => true,
                styles: { dd: { width: "100%" } },
                value: props.value?.element,
                onChange: (x) => mergeChange({ element: x }),
              }}
            />
          </div>
        </div>
      </LinkingOptionItemBody>

      <div className="linkingBoxFooter">
        <div className="optionItemBody">
          <div className="linkLabel">Action</div>
          <div className="optionItemRow">
            <CustomSelect
              value={value.action || "hide"}
              onChange={(option) =>
                mergeChange({ elementStyle: { display: option.value } })
              }
              options={[
                {
                  value: "none",
                  label: "Hide",
                },
                {
                  value: "flex",
                  label: "Show",
                },
              ]}
              placeholder={"Select"}
              className="linkingToBox flexColumn flex1 br10 pad6"
              classNames={{
                head: "pad6 flexRow aCenter",
                label: "cDark flex1 cPurple",
                chevron: "optionDatabaseSortKeyIco",
              }}
            />
          </div>
        </div>

        {linkBoxOptions.showTriggerOption ? triggerOption : null}
      </div>
    </>
  );
};

export const SetElementValue = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">Element</div>

            <ValueOfElementSelector
              {...{
                ...props,
                elementType: null,
                elementFilter: () => true,
                styles: { dd: { width: "100%" } },
                value: props.value?.element,
                onChange: (x) => mergeChange({ element: x }),
              }}
            />
          </div>

          <div className="domBody">
            <div className="calcItemDatabaseFilterBox">
              <div className="calcItemDatabaseFilterBoxInner">
                <div className="calcItemDatabaseFilterBoxInnerRow">
                  <div className="calcItemDBLabel">Element Value</div>
                </div>
                <RichTextData
                  {...{
                    ...props,
                    immediateProps: {
                      classNames: {
                        wrapper: " ",
                        editor: "calcDatabaseFilterMultiBox ",
                        paramListRow: "",
                      },
                    },
                    placeholder: "Type here",
                    value: value?.elementValue?.valueObj,
                    onChange: (valueObj) => {
                      mergeChange({
                        elementValue: {
                          valueObj,
                          valueType: "textParts",
                        },
                      });
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </LinkingOptionItemBody>

      {linkBoxOptions.showTriggerOption ? (
        <div className="linkingBoxFooter">{triggerOption}</div>
      ) : null}
    </>
  );
};
