import React from "react";
import StyleSelector from "./StyleSelector";
import ToggleButton from "../../../../../Components/Input/ToggleButton";

class Scrollable extends StyleSelector {
  styleKey = "scrollable";
  optionItemLabel = "SCROLLING";
  defaultStyleValue = "";
  options = [
    { value: "vertical", label: "Vertical" },
    { value: "horizontal", label: "Horizontal" },
  ];
  renderOptionHeadTabs = () => (
    <ToggleButton
      classNames={{
        container: "optionHeadTabs headTabsDark",
        item: "optionHeadTabItem",
      }}
      overideDefaultStyle
      value={this.props.value.scrollable ? "on" : "off"}
      onChange={(x) => {
        console.log({ x, styleValue: this.getStyleValue() });
        this.mergeStyle({
          scrollable: x === "on" ? this.getStyleValue() || "vertical" : "",
        });
      }}
      options={[
        { value: "on", label: "On" },
        { value: "off", label: "Off" },
      ]}
    />
  );
}

export default Scrollable;
