import React from "react";
import Example from "./Example";

const Home = () => {
  return (
    <div>
      Home
      <Example />
    </div>
  );
};

export default Home;
