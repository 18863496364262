import React from "react";
import _ from 'lodash';

import { LinkingOptionItemBody } from "../../../Components/etc/OptionItem";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import CustomSelect from "../../../Components/etc/CustomSelect";
import CustomUrlParameters from "./CustomUrlParameters";

const SendEmail = (props) => {
  const {
    value = {},
    onChange,
    linkBoxOptions,
    triggerOption,
    components,
  } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">From</div>

            <RichTextData
              {...{
                ...props,
                immediateProps: {
                  classNames: {
                    wrapper: " ",
                    editor: "calcDatabaseFilterMultiBox spaceTopify",
                    paramListRow: "",
                  },
                },
                placeholder: "Type here",
                value: value?.from?.valueObj,
                onChange: (valueObj) => {
                  mergeChange({
                    from: {
                      valueObj,
                      valueType: "textParts",
                    },
                  });
                },
              }}
            />
          </div>

          <div className="domBody">
            {[
              {
                label: "To",
                key: "targetEmailAddresses",
              },
              {
                label: "Subject",
                key: "subject",
              },
              {
                label: "Email Body",
                key: "body",
              },
              {
                label: "Email Template",
                key: "emailTemplate",
              },
            ]
              .filter((x) =>
                value.emailFormat === "custom"
                  ? x.key !== "emailTemplate"
                  : x.key !== "body"
              )
              .map((item) => (
                <div className="calcItemDatabaseFilterBox" key={item.key}>
                  <div className="calcItemDatabaseFilterBoxInner">
                    <div className="calcItemDatabaseFilterBoxInnerRow">
                      <div className="calcItemDBLabel">{item.label}</div>
                    </div>
                    {["emailTemplate"].includes(item.key) ? (
                      <>
                        <CustomSelect
                          value={value?.[item?.key]?.valueObj?.customText}
                          onChange={(option) => {
                            mergeChange({
                              [item.key]: {
                                valueObj: { customText: option.value },
                                valueType: "customText",
                              },
                            });
                          }}
                          options={components
                            ?.filter((x) => x.category === "emailTemplate")
                            ?.map((x) => ({
                              value: x._id,
                              label: x.name,
                            }))}
                          placeholder={"Select"}
                          classNames={{
                            head: "calcItemUnitMainDropdown spaceTopify",
                            label: "calcItemUnitMainDropdownLabel",
                          }}
                          jointActionRow={
                            <div className="calcItemUnitMainDropdownFooter">
                              <div className="calcItemUnitMainDropdownFooterArrow">
                                <div className="one"></div>
                                <div className="two"></div>
                              </div>
                            </div>
                          }
                        />
                        <CustomUrlParameters
                          {...{
                            ...props,
                            value: value.urlParameters,
                            onChange: (urlParameters) =>
                              mergeChange({ urlParameters }),
                          }}
                        />
                      </>
                    ) : (
                      <RichTextData
                        {...{
                          ...props,
                          immediateProps: {
                            classNames: {
                              wrapper: " ",
                              editor: "calcDatabaseFilterMultiBox ",
                              paramListRow: "",
                            },
                          },
                          placeholder: "Type here",
                          value: value?.[item.key]?.valueObj,
                          onChange: (valueObj) => {
                            mergeChange({
                              [item.key]: {
                                valueObj,
                                valueType: "textParts",
                              },
                            });
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </LinkingOptionItemBody>

      <div className="linkingBoxFooter">
        <div className="optionItemBody">
          <div className="linkLabel">Email Format</div>
          <div className="optionItemRow">
            <CustomSelect
              value={value.emailFormat || "template"}
              onChange={(option) => mergeChange({ emailFormat: option.value })}
              options={["template", "custom"]?.map((x) => ({
                value: x,
                label: _.startCase(x),
              }))}
              placeholder={"Select"}
              className="linkingToBox flexColumn flex1 br10 pad6"
              classNames={{
                head: "pad6 flexRow aCenter",
                label: "cDark flex1 cPurple",
                chevron: "optionDatabaseSortKeyIco",
              }}
            />
          </div>
        </div>

        {linkBoxOptions.showTriggerOption ? triggerOption : null}
      </div>
    </>
  );
};

export default SendEmail;
