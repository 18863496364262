import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";

const AlignItems = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  const items = [
    [
      {
        key: "flex-start",
        label: "Default",
        subtext: "Start top",
        decorClass: "jStart",
      },
      {
        key: "flex-end",
        label: "Bottom",
        subtext: "start end",
        decorClass: "jEnd",
      },
    ],
    [
      {
        key: "stretch",
        label: "Stretch",
        subtext: "End to end",
        decorClass: "jSpaceBetween",
      },
      {
        key: "center",
        label: "Center",
        subtext: "Middle",
        decorClass: "jCenter",
      },
    ],
  ];

  return (
    <OptionItem
      className="mainCondition"
      optionItemLabel="Vertical Orientation"
    >
      <div className="optionItemBody">
        {items.map((items, i) => (
          <div className="optionItemRow" key={i}>
            {items.map((item) => {
              const active = item.key === styles.alignItems;
              return (
                <div className="optionItemBox" key={item.key}>
                  <div
                    className={
                      "advancedRadioItem" + (active ? " lightActive" : "")
                    }
                    onClick={() =>
                      mergeStyle({
                        alignItems: item.key,
                        display: styles.display || "flex",
                      })
                    }
                  >
                    <div className={"verticalOptions " + item.decorClass}>
                      <div className="vertical one"></div>
                      <div className="vertical two"></div>
                      <div className="vertical three"></div>
                    </div>
                    <div className="advancedMultiLabel">
                      {item.label} <span>{item.subtext}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </OptionItem>
  );
};

export default AlignItems;
