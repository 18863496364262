import _operators from "./operators";
import valueTypes, { quickSelectionValueTypes } from "./value-types";

const { operators, operatorList } = _operators;

let conditionObj = {};

conditionObj.valueTypes = valueTypes;

conditionObj.operators = operators;
conditionObj.operatorList = operatorList;

conditionObj.filterConditions = [
  { label: "Equals", value: "eq" },
  { label: "Doesn't equal", value: "neq" },
  { label: "Greater Then", value: "gt" },
  { label: "Less Then", value: "lt" },
  { label: "Greater Then Or Equal To", value: "gte" },
  { label: "Less Then Or Equal To", value: "lte" },
  { label: "Contains", value: "contain" },
  { label: "Contains Anything", value: "containAnything" },
];

conditionObj.quickSelectionValueTypes = quickSelectionValueTypes

conditionObj.getQuickSelectionValueTypes = (props) =>
  conditionObj.quickSelectionValueTypes.filter(
    (x) =>
      (!props.availableFor || x.availableFor?.includes(props.availableFor)) &&
      (!x.isAvailable || x.isAvailable(props))
  );

export default conditionObj;
