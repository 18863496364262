import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { ToastContainer } from "react-toastify";

import RootScreen from "./Views/Root/RootScreen";
import { persistor, store } from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/Sass/main.css";
import "./Styles/style.css";
import "react-toastify/dist/ReactToastify.css";
import 'rc-slider/assets/index.css';
export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RootScreen />
          <ToastContainer />
        </PersistGate>
      </Provider>
    );
  }
}
