import React from "react";
import api from "../../../../../Services/Api/api";

const ImageUpload = ({ onChange, style = {}, label }) => {
  const imageUploadHandler = async (event) => {
    try {
      let files = event.target.files;
      const fileRes = await api.media("v1/file", { file: files[0] });
      onChange(fileRes?.file);
    } catch (e) {
      console.error("Error uploading file: " + e.message);
    }
  };

  return (
    <label
      style={{
        backgroundColor: "gray",
        padding: "4px",
        borderRadius: "3px",
        color: "white",
        ...style,
      }}
    >
      <input
        type="file"
        onChange={imageUploadHandler}
        accept="image/png, image/jpeg"
        multiple={false}
        style={{ display: "none" }}
      />
      <span>{label || "Upload Image"}</span>
    </label>
  );
};

export default ImageUpload;
