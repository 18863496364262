import React from "react";
import update from "immutability-helper";

import OptionItem from "../../../../Components/etc/OptionItem";

const ContainerTabValue = (props) => {
  const containerTabElementId = props.passedParameters?.find(
    (x) => x.sourceType === "containerTabs"
  )?.elementId;
  const conatinerTabElement = props.dom.findNodeAndParent(
    containerTabElementId
  )?.node;

  const conatinerTabELementData = conatinerTabElement.value?.data || {};
  const setConatinerTabELementData = (data) =>
    props.dom.updateNodeValue(conatinerTabElement.id, {
      ...(conatinerTabElement.value || {}),
      data,
    });

  const containerTabsConfig =
    conatinerTabELementData.tabs[conatinerTabELementData.activeTabIndex || 0]
      .containerType.containerTabsConfig || {};

  const activeTabValueElementIds = (
    containerTabsConfig.activeTabValueElementIds || ""
  )
    .split(",")
    .map((x) => x.trim())
    .filter((x) => x);

  const currentElementId = props.focusedElement.element.id;
  const active = activeTabValueElementIds.includes(currentElementId);

  const toggleTabValue = () => {
    const updatedActiveTabValueElementIds = active
      ? activeTabValueElementIds.filter((x) => x !== currentElementId)
      : [...activeTabValueElementIds, currentElementId];

    const updatedContainerTabsConfig = {
      ...containerTabsConfig,
      activeTabValueElementIds: updatedActiveTabValueElementIds.join(","),
    };

    setConatinerTabELementData(
      update(conatinerTabELementData, {
        tabs: {
          [conatinerTabELementData.activeTabIndex || 0]: {
            containerType: {
              $merge: { containerTabsConfig: updatedContainerTabsConfig },
            },
          },
        },
      })
    );
  };

  return (
    <>
      <OptionItem optionItemLabel="Tab Value">
        <div className="optionItemBody pad5t">
          <div className="optionItemRow">
            <div className="flex column pad7 flex1">
              <div className="linkingOpen flexRow bgWhite shadow flex1 br10 pad6">
                <div className="pad6 flexRow flex1" onClick={toggleTabValue}>
                  <div
                    className={
                      "optionDatabaseRadio checkBoxify" +
                      (active ? " active" : "")
                    }
                  >
                    <div className="optionDatabaseRadioDecor"></div>
                  </div>
                  <div className="cGrey">Set as Tab Value</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OptionItem>
    </>
  );
};

export default ContainerTabValue;
