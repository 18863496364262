import StyleSelector from "./StyleSelector";

class TextAlign extends StyleSelector {
  styleKey = "textAlign";
  optionItemLabel = "TEXT ALIGN";
  defaultStyleValue = "left";
  options = [
    { value: "left", label: "Left" },
    { value: "center", label: "Center" },
    { value: "right", label: "Right" },
  ];
}

export default TextAlign;
