import React from "react";
import AlignItems from "./AlignItems";
import JustifyContent from "./JustifyContent";

const Alignment = (props) => {
  return (
    <>
      <JustifyContent {...props} />
      <AlignItems {...props} />
    </>
  );
};

export default Alignment;
