import React from "react";
import OptionItem, {
  OptionToggle1,
} from "../../../../../Components/etc/OptionItem";

const ObjectFit = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  return (
    <OptionItem optionItemLabel="Resize Mode">
      <div className="optionItemBody">
        <OptionToggle1
          value={styles.objectFit}
          onChange={(option) => mergeStyle({ objectFit: option.value })}
          options={[
            { value: "cover", label: "Cover" },
            { value: "contain", label: "Contain" },
          ]}
        />
        <OptionToggle1
          value={styles.objectFit}
          onChange={(option) => mergeStyle({ objectFit: option.value })}
          options={[
            { value: "stretch", label: "Stretch" },
            { value: "center", label: "Center" },
          ]}
        />
      </div>
    </OptionItem>
  );
};

export default ObjectFit;
