import React from "react";
import update from "immutability-helper";

import MarkLocation from "./MarkLocation";
import MapIcon from "./MapIcon";
import { LinkingBoxes } from "../../../../Common/Linking/LinkingBox";
import RichTextData from "../../Common/DataProperties/RichTextData";
import ImageData from "../image/ImageData";
import Dimensions from "../../Common/StyleProperties/Dimensions";
import ToggleButton from "../../../../../Components/Input/ToggleButton";

const FixedMark = (props) => {
  const { value, onChange } = props;

  const locationObj = value || {};
  const setLocationObj = (obj) => {
    onChange(update(locationObj, { $merge: obj }));
  };

  return (
    <>
      <div className="mapLinkingCombo">
        <div className="optionMapDatabase optionMapSingular">
          <div className="optionItemBody">
            <div className="optionItemRow filterItemUnit">
              <div className="optionDatabaseBoxWrapper">
                <div className="optionDatabaseBox">
                  <div className="optionDatabaseFilters">
                    <div className="addressSelector optionMapAdjust">
                      <div className="optionDatabaseBoxLabel">Address</div>

                      <RenderPlotMark
                        {...props}
                        value={locationObj.origin}
                        onChange={(value) => setLocationObj({ origin: value })}
                      />
                    </div>

                    <div className="addressSelector mapIconSection">
                      <div className="optionDatabaseBoxLabelMultiUnit">
                        <div className="optionDatabaseBoxLabel">Icon</div>
                        <ToggleButton
                          classNames={{
                            container: "optionHeadTabs headTabsDark",
                            item: "optionHeadTabItem",
                          }}
                          overideDefaultStyle
                          value={locationObj.icon?.valueType || "staticUrls"}
                          onChange={(x) =>
                            setLocationObj({
                              icon: {
                                ...(locationObj.icon || {}),
                                valueType: x,
                              },
                            })
                          }
                          setDefaultOption="staticUrls"
                          options={[
                            { value: "staticUrls", label: "File" },
                            { value: "textParts", label: "Reference" },
                          ]}
                        />
                      </div>

                      <div className="optionItemRow optionItemIconDimension">
                        <div className="optionItemBox">
                          <div className="optionInputIconBox">
                            <img
                              className="optionInputIconBoxArrowIco"
                              src={require("../../../../../Assets/img/options/container/widthIco.png")}
                            ></img>
                            <input
                              type="text"
                              className="optionInputIconBoxSpacingField"
                              placeholder="25px"
                              value={locationObj.icon?.width || ""}
                              onChange={(e) =>
                                setLocationObj({
                                  icon: {
                                    ...(locationObj.icon || {}),
                                    width: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="optionItemBox">
                          <div className="optionInputIconBox">
                            <img
                              className="optionInputIconBoxArrowIco rotate90"
                              src={require("../../../../../Assets/img/options/container/widthIco.png")}
                            ></img>
                            <input
                              type="text"
                              className="optionInputIconBoxSpacingField"
                              placeholder="25px"
                              value={locationObj.icon?.height || ""}
                              onChange={(e) =>
                                setLocationObj({
                                  icon: {
                                    ...(locationObj.icon || {}),
                                    height: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <IconData
                        {...props}
                        value={locationObj.icon}
                        onChange={(x) => {
                          setLocationObj({
                            icon: {
                              ...locationObj.icon,
                              ...x,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LinkingBoxes
          {...{
            ...props,
            value: locationObj.linkings,
            onChange: (linkings) => setLocationObj({ linkings }),
            autoAddOneEmptyLinking: false,
            linkBoxOptions: {},
          }}
        />
      </div>
    </>
  );
};

class IconData extends ImageData {
  render() {
    return this.props.value?.valueType === "textParts"
      ? this.textPartsConfig()
      : this.staticUrlConfig();
  }
}

const RenderPlotMark = (props) => {
  const { value = {}, onChange, remove } = props;

  return (
    <div className="optionItemRow mt10">
      <div className="optionConditionTableSelectorFilterItemContext lightenMode">
        <div className="optionConditionTableSelectorFilterItemHead">
          <div className="optionConditionTableFilterMetaRequirementLabel">
            Location
          </div>
        </div>

        <RichTextData
          {...{
            ...props,
            key: "location",
            placeholder: "Enter Location",
            value: value?.valueObj || {},
            onChange: (valueObj) =>
              onChange({
                ...value,
                valueObj,
                valueType: "textParts",
              }),
          }}
        />

        <div className="jointActionRow">
          <div className="jointActionClose" onClick={() => remove()}>
            <div className="jointActionCloseItem one"></div>
            <div className="jointActionCloseItem two"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styleObj = {
  container: { background: "#d0d0d0", padding: "6px" },
  title: {
    color: "white",
    fontSize: "xx-small",
    padding: "3px",
  },
  filterContainer: {
    backgroundColor: "white",
    padding: "6px",
    fontSize: "11px",
  },
  filterTitle: {
    margin: "4px 0 ",
    color: "blue",
  },
  input: {
    background: 0,
    border: 0,
    color: "gray",
    paddingRight: "20px",
    textAlign: "right",
  },
  unit: {
    marginLeft: "-20px",
  },
  titleBox: {
    backgroundColor: "#218a21",
    borderRadius: "6px 6px 0px 0",
    padding: "6px 0px 6px 7px",
    display: "flex",
    alignItems: "center",
  },
  selectorContainer: {
    backgroundColor: "#035803",
    padding: "7px",
    borderRadius: "0px 0px 6px 6px",
  },
  titleDD: {
    border: 0,
    background: 0,
    color: "white",
    fontSize: "11px",
    maxWidth: "100px",
  },
  hr: {
    margin: 0,
    borderColor: "#ddd",
  },
};
export default FixedMark;
