import React, { useEffect, useMemo, useState } from "react";
import Slider from "rc-slider";

import TextInput from "../../../../../Components/Input/TextInput";
import api from "../../../../../Services/Api/api";
import ColorIconPopup from "./ColorIconPopup";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import { Form } from "react-bootstrap";
import { ChromePicker } from "react-color";
let _symbols = [];

const SearchIcons = (props) => {
  const [allSymbols, setAllSymbols] = useState(_symbols);
  const [symbols, setSymbols] = useState(null);
  const [svgData, setSvgData] = useState(null);
  const [icon, setIcon] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fillColor, setFillColor] = useState({ hex: "#000" });

  const [filter, setFilter] = useState({
    style: "materialsymbolsoutlined",
    weight: 400,
    grad: 0,
    fill: false,
    size: 40,
  });

  const symbolPath = useMemo(() => api.getApiUrl() + "v1/materialsymbols", []);
  const iconSize = isNaN(props.iconSize)
    ? 200
    : parseInt(props.iconSize || 200).toString();

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (icon) selectIcon(icon);
  }, [filter]);

  const load = async () => {
    try {
      setLoading(true);
      const { symbols } = await api.get("v1/icon/material");
      setAllSymbols(symbols);
      _symbols = symbols;
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.warn("Error loading icons ", e.message);
    }
  };

  const handleSearchInput = async (q) => {
    if (!q) {
      setSymbols(null);
    } else {
      const regexes = [
        new RegExp(`^${q}$`, "i"),
        new RegExp(`^${q}.*$`, "i"),
        new RegExp(`^.*${q}$`, "i"),
        new RegExp(`${q}`, "i"),
      ];

      let matches = regexes.flatMap((regex) => {
        return allSymbols?.filter((x) => regex.test(x));
      });

      let uniqueMatches = Array.from(new Set(matches));
      setSymbols(uniqueMatches);
    }
  };

  const selectIcon = async (symbol, event) => {
    try {
      if (event) event.stopPropagation();
      setIcon(symbol);
      setSvgData(null);

      if (symbol) {
        const url = getSvgUrl(symbol);
        const svgData = await api.get(url, {}, { fullUrl: true });
        setSvgData(svgData);
      }
    } catch (e) {
      window.alert(e.message);
    }
  };

  const modifySVGColor = (svgString, newColor) => {
    // Replace the existing fill color or add a fill attribute if it doesn't exist
    return svgString?.replace(
      /(<path .*?)(\s?\/?>)/,
      `$1 fill="${newColor}"$2`
    );
  };

  const getIconSvgDataString = (svgData) => {
    return `data:image/svg+xml;utf8,${encodeURIComponent(
      modifySVGSize(modifySVGColor(svgData, fillColor.hex), iconSize)
    )}`;
  };

  const modifySVGSize = (svgString, newSize) => {
    return svgString
      ?.replace(/width="\d+"/, `width="${newSize}"`)
      ?.replace(/height="\d+"/, `height="${newSize}"`);
  };

  const getSvgUrl = (symbol) => {
    const symbolPath = api.getApiUrl() + "v1/materialsymbols";

    const weight = filter.weight === 400 ? "" : "wght" + filter.weight;
    const grad = filter.grad
      ? filter.grad === -1
        ? "gradN25"
        : "grad" + 200
      : "";
    const fill = filter.fill ? "fill1" : "";

    const varient = weight || grad || fill ? "_" + weight + grad + fill : "";

    const src = `${symbolPath}/${symbol}/${filter.style}/${symbol}${varient}_${filter.size}px.svg`;
    return src;
  };

  const uploadImage = async (pngData) => {
    if (loading) return;

    setLoading(true);
    try {
      console.log({ pngData });
      const blob = dataURLToBlob(pngData);
      console.log({ blob });
      await props.uploadHandler({ target: { files: [blob] } });
      selectIcon(null);
    } catch (error) {
      window.alert(error.message);
    }
    setLoading(false);
  };

  const dataURLToBlob = (dataUrl) => {
    const byteString = atob(dataUrl.split(",")[1]);
    const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const applyIcon = () => {
    if (loading) return;
    try {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const pngData = canvas.toDataURL("image/png");
        uploadImage(pngData);
      };

      img.src = getIconSvgDataString(svgData);
    } catch (e) {
      window.alert(e.message);
    }
  };

  return (
    <div style={styles.popover} onClick={(e) => e.stopPropagation()}>
      <div style={styles.holder}>
        <SearchInput handleSearch={handleSearchInput} />
      </div>
      {loading ? <div>Loading..</div> : ""}
      <div style={styles.resultContainer}>
        {(symbols || allSymbols)?.map((symbol, i) => {
          if (i > 100) return null;

          const src = getSvgUrl(symbol);

          // battery_60_wght600gradN25fill1_40px
          return (
            <img
              key={symbol}
              alt={symbol}
              style={styles.icon}
              src={src}
              onClick={(e) => selectIcon(symbol, e)}
            />
          );
        })}
      </div>

      {icon ? (
        <div>
          <div>
            {svgData ? (
              <img src={getIconSvgDataString(svgData)} alt="icon" />
            ) : (
              <div className="loader" />
            )}
          </div>
          <ChromePicker color={fillColor} onChange={(x) => setFillColor(x)} />
          {/* <input
            placeholder="Icon Size"
            value={iconSize}
            onChange={(e) => setIconSize(e.target.value)}
            type="number"
          ></input> */}
          <button onClick={applyIcon}> Apply</button>
        </div>
      ) : null}

      <div>
        <div>
          <div>Fill</div>
          <Form.Switch
            size="large"
            checked={!!filter.fill}
            onChange={() => setFilter({ ...filter, fill: !filter.fill })}
          />
        </div>

        <div>
          <div>Style</div>
          <CustomSelect
            onChange={(option) => setFilter({ ...filter, style: option.value })}
            value={filter.style || ""}
            options={[
              { value: "materialsymbolsoutlined", label: "Outlined" },
              { value: "materialsymbolsrounded", label: "Rounded" },
              { value: "materialsymbolssharp", label: "Sharp" },
            ]}
            placeholder={"Select"}
            classNames={{
              head: "optionInputIconBox",
              label: "optionInputIconBoxField",
              chevron: "optionDatabaseSelectChevron",
            }}
          />
        </div>

        <div style={{ height: "60px" }}>
          <div>Weight</div>
          <Slider
            min={100}
            max={700}
            marks={{
              100: "100",
              200: "200",
              300: "300",
              400: "400",
              500: "500",
              600: "600",
              700: "700",
            }}
            step={null}
            defaultValue={filter.weight}
            onChangeComplete={(v) => setFilter({ ...filter, weight: v })}
          />
        </div>

        <div style={{ height: "60px" }}>
          <div>Grade</div>
          <Slider
            min={-1}
            max={1}
            marks={{
              "-1": "-25",
              0: "0",
              1: "200",
            }}
            step={null}
            defaultValue={filter.grad}
            onChangeComplete={(v) => setFilter({ ...filter, grad: v })}
          />
        </div>

        <div style={{ height: "60px" }}>
          <div>Size</div>
          <Slider
            min={20}
            max={48}
            marks={{
              20: "20",
              24: "24",
              40: "40",
              48: "48",
            }}
            step={null}
            defaultValue={filter.size}
            onChangeComplete={(v) => setFilter({ ...filter, size: v })}
          />
        </div>
      </div>
    </div>
  );
};

export const SearchIconDropDown = (props) => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      {visible ? (
        <div>
          <div onClick={() => setVisible(!visible)}>Icons</div>
          <SearchIcons {...props} />
        </div>
      ) : (
        <div onClick={() => setVisible(!visible)}>Show Icons</div>
      )}
    </div>
  );
};

const SearchInput = (props) => {
  const [timer, setTimer] = useState(null);

  const handleSearch = (q) => {
    clearTimeout(timer);

    if (!q) props.handleSearch(q);
    else {
      setTimer(
        setTimeout(() => {
          props.handleSearch(q);
        }, 200)
      );
    }
  };

  return (
    <input
      defaultValue=""
      onChange={(e) => handleSearch(e.target.value)}
      placeholder={"Search Icons"}
      style={{
        //   border: ".5px solid aqua",
        //   width: "90%",
        padding: "4px",
        margin: "5px 0",
        borderRadius: "4px",
      }}
    />
  );
};

const styles = {
  holder: {
    margin: "auto",
  },
  popover: {
    borderRadius: "7px",
    background: "#efefef",
    width: "96%",
  },

  resultContainer: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
    maxHeight: "200px",
  },
  icon: {
    // height: "33px",
    padding: "3px",
  },
};

export default SearchIcons;
