import React from "react";
import { Button, Modal } from "react-bootstrap";

const AppModal = ({
  className,
  showHeader = true,
  showFooter = true,
  showCancelBtn = true,
  showSubmitBtn = true,

  Title,
  title,
  children,
  cancelBtnText,
  submitBtnText,

  visible,
  handleClose,

  onSubmit,
  disableSubmit = false,
  disableCancel = false,
  disableBtns = false,
  backdrop = "static",
  modalProps = {},
}) => {
  return (
    <Modal
      show={visible}
      onHide={handleClose}
      className={className || "app-modal"}
      backdrop={backdrop}
      {...modalProps}
    >
      {showHeader ? (
        <Modal.Header closeButton>
          <Modal.Title>{Title ? <Title /> : title}</Modal.Title>
        </Modal.Header>
      ) : null}
      <Modal.Body>{children}</Modal.Body>
      {showFooter ? (
        <Modal.Footer>
          {showCancelBtn ? (
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={disableBtns || disableCancel}
            >
              {cancelBtnText || "Cancel"}
            </Button>
          ) : null}
          {showSubmitBtn ? (
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={disableBtns || disableSubmit}
            >
              {submitBtnText || "Submit"}
            </Button>
          ) : null}
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default AppModal;
