import React from "react";
import {
  CheckBoxHorizontal,
  RadioBoxVertical,
} from "../../../../../Components/etc/RadioBox";

// const tabs = [
//   {
//     value: "datepicker",
//     label: "Datepicker",
//     options: [
//       { label: "Select", value: "select" },
//       { label: "Bar", value: "bar" },
//       { label: "Open", value: "open" },
//       { label: "Infinite", value: "infinite" },
//     ],
//   },
//   {
//     value: "timepicker",
//     label: "Timepicker",
//     options: [{ label: "Select", value: "select" }, null, null, null],
//   },
//   {
//     value: "datetimepicker",
//     label: "Datepicekr + Timepicker",
//     options: [{ label: "Select", value: "select" }, null, null, null],
//   },
// ];

const DateTimePickerConfig = (props) => {
  const { value = {}, onChange } = props;
  const config = value?.datetimePicker || {};

  const changeConfig = (obj) => {
    onChange({ datetimePicker: { ...(value?.datetimePicker || {}), ...obj } });
  };

  const datepickerMode = config.datepickerMode || "datepicker";
  let datepickerModeValues = [datepickerMode];
  if (config.datepickerMode === "datetimepicker")
    datepickerModeValues.push("datepicker", "timepicker");

  const handleDatepickerModeSelect = (list) => {
    let datepickerMode = "datepicker";
    if (list.includes("datepicker") && list.includes("timepicker"))
      datepickerMode = "datetimepicker";
    else if (list.includes("timepicker")) datepickerMode = "timepicker";
    changeConfig({ datepickerMode });
  };

  return (
    <>
      <div className="optionItemHead pad5t">
        <div className="optionItemLabel">Options</div>
      </div>
      <div className="optionItemRow">
        <CheckBoxHorizontal
          value={datepickerModeValues}
          onChange={handleDatepickerModeSelect}
          options={[
            { label: "Date", value: "datepicker" },
            { label: "Time", value: "timepicker" },
          ]}
        />
      </div>
      {datepickerMode === "datepicker" ? (
        <div className="optionItemRow pad7">
          <RadioBoxVertical
            value={config.datepickerType}
            onChange={(x) =>
              changeConfig({
                datepickerType: x,
              })
            }
            options={[
              { label: "Bar", value: "bar" },
              { label: "Open", value: "open" },
              { label: "Infinite", value: "infinite" },
              { label: "Dropdown", value: "select" },
            ]}
          />
        </div>
      ) : null}

      {/* <div style={{ whiteSpace: "pre" }}>{JSON.stringify(value, null, 2)}</div> */}
    </>
  );
};

export default DateTimePickerConfig;
