const processStyle = (style) => {
  let result = {
    web: {},
    ios: {},
    android: {},
    builder: {},
    builderWrapper: {},
    default: style,
  };

  for (const key in style) {
    if (Object.hasOwnProperty.call(style, key)) {
      let v = style[key];
      let vMobile = v;

      if (v || v === 0) {
        if (1 || [].includes(key)) {
          // remove px from styles like padding margin etc
          vMobile =
            typeof vMobile === "string" ? vMobile.replace(/px/g, "") : vMobile;
          vMobile = isNaN(vMobile) ? vMobile : parseFloat(vMobile);
        }

        result.ios[keyMap[key]?.(v)?.ios || key] = vMobile;
        result.android[keyMap[key]?.(v)?.android || key] = vMobile;
        result.web[keyMap[key]?.(v)?.web || key] = v;

        if (builderWrapperKeys.includes(key)) {
          result.builderWrapper[keyMap[key]?.(v)?.builderWrapper || key] = v;
        } else {
          result.builder[keyMap[key]?.(v)?.builder || key] = v;
        }
      }
    }
  }

  builderWrapperKeys.map(
    (key) =>
      (result.builder[keyMap[key]?.()?.builder || key] =
        builderWrapperKeyValueMap.find((x) => x.key === key)?.builderValue)
  );

  result = checkConditionsAndApply(result);

  return result;
};

const builderWrapperKeyValueMap = [
  { key: "width", builderValue: "100%" },
  { key: "height", builderValue: "100%" },
  { key: "flex", builderValue: 1 },
  { key: "marginTop", builderValue: 0 },
  { key: "marginBottom", builderValue: 0 },
  { key: "marginLeft", builderValue: 0 },
  { key: "marginRight", builderValue: 0 },
  { key: "margin", builderValue: 0 },
];

const builderWrapperKeys = builderWrapperKeyValueMap.map((x) => x.key);

const keyMap = {
  objectFit: () => ({
    ios: "resizeMode",
    android: "resizeMode",
  }),
};

const checkConditionsAndApply = (data) => {
  let result = { ...data };
  if (data.default.backgroundSize === "center") {
    result.web.backgroundSize = "contain";
    result.builder.backgroundSize = "contain";
    result.web.backgroundPosition = "center";
    result.builder.backgroundPosition = "center";
  } else {
    delete result.web.backgroundPosition;
    delete result.builder.backgroundPosition;
  }

  if (data.default.backgroundRepeat) {
    delete result.ios.backgroundRepeat;
    delete result.android.backgroundRepeat;

    if (data.default.backgroundRepeat === "repeat") {
      result.ios.backgroundSize = "repeat";
      result.android.backgroundSize = "repeat";
    }
  }

  if (data.default.objectFit === "stretch") {
    result.web.objectFit = "fill";
    result.builder.objectFit = "fill";
  } else if (data.default.objectFit === "center") {
    result.web.objectPosition = "center center";
    result.builder.objectPosition = "center center";
    result.web.objectFit = "contain";
    result.builder.objectFit = "contain";
  }

  return result;
};

export default { processStyle };
