import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import etcModule from "../../../../../Modules/etc/etc-module";
import Padding from "../../Common/StyleProperties/Padding";
import Margin from "../../Common/StyleProperties/Margin";
import Border from "../../Common/StyleProperties/Border";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import MapData from "./MapData";
import Dimensions from "../../Common/StyleProperties/Dimensions";
import Spacing from "../../Common/StyleProperties/Spacing";

class MapProperties extends PropertyClass {
  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });
    const mergeStyleData = (styleData) => mergeChange({ styleData });

    const styleProps = {
      ...this.props,
      value: value.styleData?.map?.default,
      onChange: (obj) => {
        const updatedStyleData = etcModule.mergeObjects(value.styleData, obj, [
          "map",
          "default",
        ]);
        mergeStyleData(updatedStyleData);

        this.processStyleWithThrottle(
          updatedStyleData.map?.default,
          (styleData) => mergeStyleData({ ...updatedStyleData, map: styleData })
        );
      },
    };

    return (
      <>
        <Dimensions {...styleProps} />
        <Spacing {...styleProps} />
        <Border {...styleProps} />
        <BorderRadius {...styleProps} />

        <MapData
          {...this.props}
          value={value.mapData}
          onChange={(x) => mergeChange({ mapData: x })}
        />
      </>
    );
  }
}

export default MapProperties;
