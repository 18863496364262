const findIndexOfMinimumValue = (array) => {
  const minValue = Math.min(...array);
  return array.indexOf(minValue);
};

const getHoverPosition = (node, monitor) => {
  /*
   * Calculate the position of the mouse pointer relative to the div on hover
   */
  const hoverBoundingRect = node.getBoundingClientRect(); // Determine rectangle on screen
  const widthY = hoverBoundingRect.bottom - hoverBoundingRect.top; // Get vertical middle
  const widthX = hoverBoundingRect.right - hoverBoundingRect.left; // Get horizontal middle
  const clientOffset = monitor.getClientOffset(); // Determine mouse position

  const topDistance = clientOffset.y - hoverBoundingRect.top; // Get pixels to the top
  const bottomDistance = hoverBoundingRect.bottom - clientOffset.y; // Get pixels to the top
  const leftDistance = clientOffset.x - hoverBoundingRect.left; // Get pixels to the left
  const rightDistance = hoverBoundingRect.right - clientOffset.x; // Get pixels to the left

  let position = {};
  position.x = leftDistance < rightDistance ? "left" : "right";
  position.y = topDistance < bottomDistance ? "top" : "bottom";

  position.centerY = // To check mouse pointer is vertically inside, not near horizontal border
    (widthY < 60 &&
      topDistance > widthY * 0.33 &&
      bottomDistance > widthY * 0.33) ||
    (widthY >= 60 && topDistance > 20 && bottomDistance > 20)
      ? true
      : false;

  position.centerX = // To check mouse pointer is horizontally inside, not near vertical border
    (widthX < 60 &&
      leftDistance > widthX * 0.33 &&
      rightDistance > widthX * 0.33) ||
    (widthX >= 60 && leftDistance > 20 && rightDistance > 20)
      ? true
      : false;

  position.center = position.centerX && position.centerY ? true : false; // To check mouse pointer is inside, not near any border

  position.side = ["left", "right", "top", "bottom"][
    findIndexOfMinimumValue([
      leftDistance,
      rightDistance,
      topDistance,
      bottomDistance,
    ])
  ];

  return position;
};

const centerEnable = ["container"];

const getElementPosition = (node, monitor, elementType) => {
  let position = getHoverPosition(node, monitor);

  return centerEnable.includes(elementType) && position.center
    ? "center"
    : position.side;
};

const getHeirarchyPosition = (node, monitor, elementType) => {
  let position = getHoverPosition(node, monitor);

  let result =
    position.centerY && centerEnable.indexOf(elementType) > -1
      ? "center"
      : position.y;

  return result;
};

const hoverPosition = {
  getHoverPosition,
  getElementPosition,
  getHeirarchyPosition,
};

export default hoverPosition;
