import _ from "lodash";
import React from "react";

const ToggleRadioButton = ({
  value,
  onChange,
  options,
  styles: propStyles,
  classNames = {},
  overideDefaultStyle = false,
}) => {
  return (
    <div
      style={Object.assign(
        {},
        overideDefaultStyle ? {} : styles.container,
        propStyles?.container
      )}
      className={classNames.container}
    >
      {options.map((item) => {
        const isActive = value === item.value;
        return (
          <div
            className={(classNames.item || "") + (isActive ? " active" : "")}
            key={item.value}
            style={Object.assign(
              {},
              overideDefaultStyle ? {} : styles.item,
              propStyles?.item,
              isActive
                ? Object.assign(
                    {},
                    overideDefaultStyle ? {} : styles.active,
                    propStyles?.active
                  )
                : null
            )}
            onClick={() => onChange(item.value)}
          >
            <div
              className={(classNames.radio || "") + (isActive ? " active" : "")}
            >
              <div className={classNames.radioDecor}></div>
            </div>
            <div className={classNames.label || ""}>
              {item.label || _.startCase(item.value)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    borderRadius: "3px",
    cursor: "pointer",
    border: "solid 0.5px #444",
  },
  item: {
    padding: "2px 7px 2px 7px",
    flex: 1,
    borderRadius: "3px",
    textAlign: "center",
    border: "solid 0.5px #eee",
  },
  active: {
    color: "white",
    backgroundColor: "#3c9fec",
  },
};

export default ToggleRadioButton;
