import React from "react";
import update from "immutability-helper";
import RichTextData from "../../Common/DataProperties/RichTextData";

const MapCenter = (props) => {
  const { value, onChange } = props;

  const dataObj = value || {};
  const setDataObj = (obj) => onChange(update(dataObj, { $merge: obj }));

  return (
    <RichTextData
      {...{
        ...props,
        key: "mapCenter",
        placeholder: "Enter Location",
        value: dataObj?.valueObj || {},
        onChange: (valueObj) =>
          setDataObj({
            valueObj,
            valueType: "textParts",
          }),
      }}
    />
  );
};
export default MapCenter;
