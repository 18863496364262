import React from "react";
import { toast } from "react-toastify";

import ProjectDetailsBase from "./ProjectDetailsBase";
import SearchInput from "../../../Components/Input/SearchInput";
import api from "../../../Services/Api/api";

class ProjectBackups extends ProjectDetailsBase {
  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true });
      const { changeLogs } = await api.get("v1/project/changelog", {
        filter: JSON.stringify({
          where: {
            project: this.props.project._id,
            type: "project",
          },
          populate: "createdBy",
          order: -1,
          sortby: "createdAt",
        }),
      });
      this.setState({ loading: true, changeLogs });
    } catch (e) {
      toast.error(e.mssage);
      this.setState({ loading: false });
    }
  }

  createBackup = async () => {
    try {
      if (this.state.loadingObj.createBackup) return;

      this.setLoading("createBackup", true);

      await api.post("v1/project/changelog", {
        project: this.props.project._id,
        type: "project",
      });

      this.setLoading("createBackup", false);
      this.load();
    } catch (e) {
      this.setLoading("createBackup", false);
      toast.error(e.message);
    }
  };

  RestoreBackup = async (changelogId) => {
    try {
      if (
        this.state.loadingObj.createBackup ||
        this.state.loadingObj.restoreBackup
      )
        return;
      this.setLoading("restoreBackup", changelogId);

      let lastBackupTime = this.state.changeLogs?.[0]?.createdAt;

      for (let i = 0; i < this.state.changeLogs.length; i++) {
        const changeLog = this.state.changeLogs[i];
        if (changeLog?.createdAt > lastBackupTime)
          lastBackupTime = changeLog.createdAt;
      }

      const lastBackupTimeMs = lastBackupTime
        ? new Date(lastBackupTime).getTime()
        : 0;

      if (Date.now() - lastBackupTimeMs > 1000 * 60 * 60) {
        await this.createBackup();
      }

      await api.patch("v1/project/changelog", {
        project: this.props.project._id,
        changeLog: changelogId,
      });

      this.props.reload();
      this.load();

      this.setLoading("restoreBackup", false);

      // toast.success(`Project is restored successfully`);
      this.setToggleables("restoreBackupSuccess", changelogId);
    } catch (e) {
      this.setLoading("restoreBackup", false);

      toast.error(e.message);
    }
  };

  
  render() {
    return (
      <>
        <div className="pageTitle">
          <div>Project Backups</div>
          <span>Create and manage your backups</span>
        </div>

        <div className="contentBody">
          <div className="dataTableActionRow">
            <div className="minLightButton" onClick={() => this.createBackup()}>
              <div className="buttonPlus">
                <div></div>
                <div></div>
              </div>
              {this.state.loadingObj.createBackup
                ? `Processing`
                : `Create Backup`}
            </div>
          </div>
          <div className="dataTable">
            <table>
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Created</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.changeLogs?.map((changeLog, i) => (
                  <tr key={changeLog._id}>
                    <td>
                      <div className="tableData">
                        <div className="tdID">{i + 1}</div>
                      </div>
                    </td>

                    <td>
                      <div className="tableData">
                        {this.formatDate(changeLog.createdAt)}
                      </div>
                    </td>
                    <td>
                      {this.state.loadingObj.restoreBackup === changeLog._id ? (
                        <div className="tableData">Processing</div>
                      ) : this.state.toggleables?.restoreBackupSuccess ===
                        changeLog._id ? (
                        <div className="tableData cGreen">Complete</div>
                      ) : null}
                    </td>
                    <td>
                      <div className="tableData">
                        <div className="minActionRow">
                          <div
                            onClick={() => this.RestoreBackup(changeLog._id)}
                            className="minButton"
                          >
                            Restore
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default ProjectBackups;
