import React from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import _ from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import withRouter from "../../Components/Wrapper/with-router";

import _dom from "../../appxolo-engine/modules/dom/Dom";
import { BuilderInner } from "./BuilderScreen";
import sourceElements from "../../appxolo-engine/modules/source-elements/source-elements";

class EmailBuilderInner extends BuilderInner {
  constructor(props) {
    super(props);
    this.sourceElements = this.prepareSourceElements(
      sourceElements.filter((x) =>
        ["container", "text", "image"].includes(x.elementType)
      )
    );

    const getCommonPropsToPass = this.getCommonPropsToPass.bind(this);
    this.getCommonPropsToPass = () => {
      const commonProps = getCommonPropsToPass();
      return { ...commonProps, availableFor: "emailTemplate" };
    };
  }

  screenList() {
    const {
      props: { components },
      state: { component },
    } = this;

    return (
      <div className="builderPanel">
        <div className="builderScreens">
          <div className="card-body" style={{ padding: "4px" }}>
            <div style={{}} className="card-title">
              Email Templates
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {components
                ?.filter((x) => x.category === "emailTemplate")
                ?.map((item) => {
                  return (
                    <Link
                      key={item._id}
                      to={"/email-template/" + item._id}
                      className="card"
                      style={Object.assign(
                        {},
                        {
                          width: "50%",
                          fontSize: "small",
                          textDecoration: "none",
                          color: "unset",
                        },
                        item._id === component?._id
                          ? {
                              background: "#ddffdd",
                            }
                          : {}
                      )}
                    >
                      <div
                        className="card-text"
                        style={{
                          padding: "4px",
                        }}
                      >
                        {item.name}
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.vState.BUILDER.project,
  databases: state.vState.BUILDER.databases,
  components: state.vState.BUILDER.components,
  allBuilderData: state.vState.BUILDER,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "BUILDER") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const EmailBuilder = DragDropContext(HTML5Backend)(
  connect(mapStateToProps, mapDispatchToProps)(EmailBuilderInner)
);

const EmailBuilderScreen = withRouter((props) => (
  <EmailBuilder
    key={props.router.params.componentId}
    {...{ ...props.router.params }}
  />
));

export default EmailBuilderScreen;
