import React from "react";
import _ from "lodash";

import CustomSelect from "../../../../../Components/etc/CustomSelect";

const InputType = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const { group: selectedGroup = "textInput" } = value || {};

  const setInputType = (group, inputType) => {
    mergeChange({ group, inputType });
  };

  return (
    <>
      <div className="optionItemHead">
        <div className="optionItemLabelCombo">
          <div className="optionItemLabel">Type</div>
        </div>

        <div className="optionItemDecor">
          <div className="optionItemDecorLine one"></div>
          <div className="optionItemDecorLine two"></div>
        </div>
      </div>

      <div className="optionItemBody">
        <div className="optionItemRow">
          <div className="optionItemBox">
            <CustomSelect
              onChange={(option) => setInputType(option.value, "default")}
              value={selectedGroup || ""}
              options={groups.map((x) => ({
                value: x.group,
                label: _.startCase(x.group),
              }))}
              placeholder={"Text Box"}
              classNames={{
                head: "optionInputIconBox",
                label: "optionInputIconBoxField",
                chevron: "optionDatabaseSelectChevron",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const groups = [
  {
    group: "textInput",
    inputTypes: ["default", "withLabel", "withBorderAndLabel"],
  },
  {
    group: "locationInput",
    inputTypes: ["default", "withLabel", "withBorderAndLabel"],
  },
  {
    group: "selectBox",
    inputTypes: ["default", "withLabel", "withBorderAndLabel"],
  },
  {
    group: "checkBox",
    inputTypes: ["default"],
  },
  {
    group: "radio",
    inputTypes: ["default"],
  },
  {
    group: "switch",
    inputTypes: ["default"],
  },
  {
    group: "imagePicker",
    inputTypes: ["default"],
  },
  {
    group: "filePicker",
    inputTypes: ["default"],
  },
  {
    group: "dateTimePicker",
    inputTypes: ["default"],
  },
];

export default InputType;
