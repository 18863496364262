import React from "react";
import { Button, Form } from "react-bootstrap";
import _ from "lodash";

import api from "../../../Services/Api/api";
import AppModal from "../../../Components/Models/app-modal";

export class PaymentMethods extends React.Component {
  state = {
    items: null,
    loading: false,
    error: null,
  };

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });
      const { paymentMethods } = await api.get("v1/payment/payment-methods", {
        filter: JSON.stringify(this.props.filter || {}),
      });
      this.setState({ loading: false, items: paymentMethods });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: null });
    }
  }

  async deletePaymentMethod({ _id }) {
    try {
      this.setState({ loading: true, error: null });
      await api.delete("v1/payment/payment-methods/" + _id);
      this.setState({ loading: false });
      this.load();
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: null });
      window.alert(e.message);
    }
  }

  render() {
    const {
      state: { items },
    } = this;
    return (
      <div>
        {items?.map((item) => (
          <div
            key={item._id}
            style={{ margin: "2px", padding: "10px", border: "1px solid gray" }}
          >
            <div>Provider: {item.provider}</div>
            <div style={{ overflow: "auto", whiteSpace: "nowrap" }}>
              Public Key: {item.publicKey}
            </div>
            <button
              onClick={() =>
                window.confirm("Confirm Delete ?") &&
                this.deletePaymentMethod(item)
              }
            >
              Delete
            </button>
          </div>
        ))}

        <AddPaymentMethod {...this.props} onSuccess={() => this.load()} />
      </div>
    );
  }
}

export class AddPaymentMethod extends React.Component {
  initialState = {
    loading: false,
    visible: null,
    error: null,
    paymentMethod: {
      project: this.props.filter?.where?.project,
    },
  };

  state = { ...this.initialState };

  async onSubmit() {
    try {
      if (this.state.loading) return;
      this.setState({ loading: true, error: null });

      const res = await api.post(
        "v1/payment/payment-methods",
        this.state.paymentMethod
      );

      console.log(res);

      this.setState({ loading: false, visible: false });
      this.props.onSuccess();
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }

  render() {
    const { paymentMethod, error, loading } = this.state;
    const content = (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.onSubmit();
        }}
      >
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Provider</Form.Label>
          <Form.Control
            as="select"
            value={paymentMethod?.provider || ""}
            placeholder="Select"
            onChange={(e) =>
              this.setState({
                paymentMethod: { ...paymentMethod, provider: e.target.value },
              })
            }
          >
            <option value="" disabled>
              Select
            </option>
            {[
              "stripe",
              //  "applePay", "googlePay",
              "paypal",
            ].map((x) => (
              <option key={x} value={x}>
                {_.startCase(x)}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {["stripe"].includes(paymentMethod?.provider) ? (
          <>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Public Key</Form.Label>
              <Form.Control
                placeholder="public key"
                value={paymentMethod?.publicKey || ""}
                onChange={(e) =>
                  this.setState({
                    paymentMethod: {
                      ...paymentMethod,
                      publicKey: e.target.value,
                    },
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Secret Key</Form.Label>
              <Form.Control
                placeholder="secret key"
                value={paymentMethod?.secretKey || ""}
                onChange={(e) =>
                  this.setState({
                    paymentMethod: {
                      ...paymentMethod,
                      secretKey: e.target.value,
                    },
                  })
                }
                required
              />
            </Form.Group>
          </>
        ) : ["paypal"].includes(paymentMethod?.provider) ? (
          <>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Client ID</Form.Label>
              <Form.Control
                placeholder="Client ID"
                value={paymentMethod?.publicKey || ""}
                onChange={(e) =>
                  this.setState({
                    paymentMethod: {
                      ...paymentMethod,
                      publicKey: e.target.value,
                    },
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Client Secret</Form.Label>
              <Form.Control
                placeholder="Client Secret"
                value={paymentMethod?.secretKey || ""}
                onChange={(e) =>
                  this.setState({
                    paymentMethod: {
                      ...paymentMethod,
                      secretKey: e.target.value,
                    },
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Mode</Form.Label>
              <Form.Control
                value={paymentMethod?.mode || ""}
                onChange={(e) =>
                  this.setState({
                    paymentMethod: {
                      ...paymentMethod,
                      mode: e.target.value,
                    },
                  })
                }
                as="select"
                required
              >
                <option value="" disabled>
                  Select Mode
                </option>
                <option value="sandbox">Sandbox</option>
                <option value="live">Live</option>
              </Form.Control>
            </Form.Group>
          </>
        ) : null}

        <div className="errormsg">{error}</div>

        <Button variant="primary" type="submit" disabled={loading}>
          Submit
        </Button>
      </Form>
    );

    return (
      <>
        <Button onClick={() => this.setState({ visible: true })}>
          Add New
        </Button>
        <AppModal
          {...{
            title: "Add Payment Methods",
            visible: this.state.visible,
            handleClose: () => this.setState(this.initialState),
            showSubmitBtn: false,
          }}
        >
          {content}
        </AppModal>
      </>
    );
  }
}

export class PaymentMethodsModal extends React.Component {
  render() {
    return (
      <AppModal
        {...{
          title: "Payment Methods",
          visible: this.props.visible,
          handleClose: () => this.props.close(),
          showSubmitBtn: false,
        }}
      >
        {this.props.visible ? <PaymentMethods {...this.props} /> : null}
      </AppModal>
    );
  }
}

export class ShowPaymentMethodBtn extends React.Component {
  state = { visible: false };

  render() {
    return (
      <>
        <button onClick={() => this.setState({ visible: true })}>
          Payment Methods
        </button>
        <PaymentMethodsModal
          {...{
            ...this.props,
            close: () => this.setState({ visible: false }),
            visible: this.state.visible,
          }}
        />
      </>
    );
  }
}
