import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { toast } from "react-toastify";

import api from "../../Services/Api/api";
import AppModal from "../../Components/Models/app-modal";

export const DatabasesPopup = (props) => {
  const [databases, setDatabases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [selected, setSelected] = useState(props.selectedDatabases);

  const load = useCallback(async () => {
    try {
      setLoading(true);
      const { databases } = await api.get("v1/database");
      setDatabases(databases);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const onSubmit = async () => {
    try {
      setSubmiting(true);
      await props.onSubmit({ databases: selected?.map((x) => x._id) });
      props.setVisible(false);
      setSubmiting(false);
    } catch (error) {
      setSubmiting(false);
      toast.error(error.message);
    }
  };

  const content = (
    <div>
      <div>
        <div>Selected Databases</div>
        {selected?.map((database, i) => (
          <div key={database._id}>
            <div>
              {database.name} {database.type}
            </div>

            <div
              onClick={() =>
                setSelected(update(selected, { $splice: [[i, 1]] }))
              }
            >
              Remove
            </div>
          </div>
        ))}
      </div>

      <div>
        <div>Your Databases </div>
        {databases?.map?.((database) => {
          const isSelected = selected?.find(
            (x) => x._id.toString() === database._id.toString()
          );
          return (
            <div key={database._id}>
              <div>
                {database.name} {database.type}
              </div>

              {!isSelected ? (
                <div
                  onClick={() =>
                    setSelected(update(selected || [], { $push: [database] }))
                  }
                >
                  Add
                </div>
              ) : (
                <div />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <AppModal
      {...{
        title: props.title || "Databases",
        visible: props.visible,
        handleClose: () => props.setVisible(false),
        onSubmit,
        disableBtns: submiting,
        submitBtnText: submiting ? "Loading" : "Submit",
      }}
    >
      {content}
    </AppModal>
  );
};
