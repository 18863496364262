import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import etcModule from "../../../../../Modules/etc/etc-module";
import Padding from "../../Common/StyleProperties/Padding";
import Margin from "../../Common/StyleProperties/Margin";
import Border from "../../Common/StyleProperties/Border";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import TextAlign from "../../Common/StyleProperties/TextAlign";
import Appearance from "../../Common/StyleProperties/Appearance";
import TextData from "./TextData";
import TextColor from "../../Common/StyleProperties/TextColor";
import Spacing from "../../Common/StyleProperties/Spacing";
import ContainerTabValue from "../../Common/ContainerTabValue";

class TextProperties extends PropertyClass {
  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });
    const mergeStyleData = (styleData) => mergeChange({ styleData });

    const styleProps = {
      ...this.props,
      hover: {
        value: value.styleData?.hover?.default,
        onChange: (obj) => {
          const updatedStyleData = etcModule.mergeObjects(value.styleData, obj, [
            "hover",
            "default",
          ]);
          mergeStyleData(updatedStyleData);
  
          this.processStyleWithThrottle(
            updatedStyleData.hover?.default,
            (styleData) =>
              mergeStyleData({ ...updatedStyleData, hover: styleData })
          );
        },
      },
      value: value.styleData?.text?.default,
      onChange: (obj) => {
        const updatedStyleData = etcModule.mergeObjects(value.styleData, obj, [
          "text",
          "default",
        ]);
        mergeStyleData(updatedStyleData);

        this.processStyleWithThrottle(
          updatedStyleData.text?.default,
          (styleData) =>
            mergeStyleData({ ...updatedStyleData, text: styleData })
        );
      },
    };

    const isInContainerTab = !!this.props.passedParameters?.find(
      (x) => x.sourceType === "containerTabs"
    );

    return (
      <>
        {isInContainerTab ? <ContainerTabValue {...this.props} /> : null}
        <Appearance {...styleProps} />
        <TextAlign {...styleProps} />
        <Spacing {...styleProps} />
        <Border {...styleProps} />
        <BorderRadius {...styleProps} />

        <TextData
          {...this.props}
          value={value.textData}
          onChange={(x) => mergeChange({ textData: x })}
        />
      </>
    );
  }
}

export default TextProperties;
