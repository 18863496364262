import ControlledInput from "../../../../../Components/Input/ControlledInput";
import React, { useState } from "react";

const UrlSource = (props) => {
  const [src, setSrc] = useState("");

  const handleSubmit = () => {
    props.onSubmit(src);
    setSrc("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <ControlledInput
      value={src}
      onChange={(event) => setSrc(event.target.value)}
      onKeyPress={handleKeyPress}
      style={{ width: "100%" }}
      placeholder="Enter URL"
    />
  );
};

export default UrlSource;
