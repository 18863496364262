import React from "react";
import styleModule from "../../../../Modules/style/style-module";

class Map extends React.PureComponent {
  el = React.createRef(null);

  updateStaticText(x) {
    console.log(x);
  }

  render() {
    const element = this.props.element;

    let style =
      styleModule.getElementStyleData(element)?.[element.value?.elementType]
        ?.builder;

    return (
      <div style={style} ref={this.el}>
        {/* <iframe
          onClick={() => {
            console.log("clicked");
            this.el.current.click();
          }}
          title={`map#${element.id}`}
          src="https://www.google.com/maps/embed?"
          width="100%"
          height="100%"
          style={{ border: 0 }}
        /> */}
      </div>
    );
  }
}

export default Map;
