import React from "react";
import AppModal from "./app-modal";

const ConfirmationModal = ({
  title,
  visible,
  handleClose,
  onSubmit,
  body,
  children,
  cancelBtnText,
  submitBtnText,
  modalProps = {},
  error = "",
}) => {
  return (
    <AppModal
      {...{
        title: title || "Confirm",
        submitBtnText: submitBtnText || "Confirm",
        cancelBtnText: cancelBtnText || "Cancel",
        visible,
        handleClose,
        onSubmit,
        ...modalProps,
      }}
    >
      {body}
      {children}
      {error ? <div className="errormsg">{error}</div> : null}
    </AppModal>
  );
};

export default ConfirmationModal;
