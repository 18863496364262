import _ from "lodash";
import React, { useState } from "react";
import { QuickValueSelector2 } from "../QuickValueSelector/QuickValueSelector";
import SuccessFailHandler from "./SuccessFailHandler";
import {
  LinkingOptionItemBody,
  OptionItemBody,
} from "../../../Components/etc/OptionItem";
import ToggleRadioButton from "../../../Components/Input/ToggleRadioButton";
import CustomSelect from "../../../Components/etc/CustomSelect";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";

const LinkToPayment = (props) => {
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);

  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">Payment Options</div>

            <ToggleRadioButton
              classNames={{
                container: "linkingOptionsBox spaceTopify",
                item: "pad6 flexRow flex1",
                radio: "optionDatabaseRadio checkBoxify",
                radioDecor: "optionDatabaseRadioDecor",
                label: "cGrey",
              }}
              overideDefaultStyle
              value={value.paymentMethod || ""}
              onChange={(x) => mergeChange({ paymentMethod: x })}
              options={["paypal", "stripe", "inAppPurchase"]?.map((x) => ({
                value: x,
                label: _.startCase(x),
              }))}
            />
          </div>

          <div className="domBody">
            {[
              { key: "productId", label: "Product Id" },
              {
                key: "price",
                label:
                  value.paymentType?.valueObj?.customText === "recurring"
                    ? "Plan Id"
                    : "Product Price",
              },
            ].map(({ key, label, className }) => (
              <div className="calcItemDatabaseFilterBox" key={key}>
                <div className="calcItemDatabaseFilterBoxInner">
                  <div className="calcItemDatabaseFilterBoxInnerRow">
                    <div className="calcItemDBLabel">{label}</div>
                  </div>

                  <RichTextData
                    {...{
                      ...props,
                      immediateProps: {
                        classNames: {
                          wrapper: " ",
                          editor: "calcDatabaseFilterMultiBox",
                          paramListRow: "",
                        },
                      },
                      placeholder: "Type here",
                      value: value?.[key]?.valueObj,
                      onChange: (valueObj) =>
                        mergeChange({
                          [key]: {
                            valueObj,
                            valueType: "textParts",
                          },
                        }),
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </LinkingOptionItemBody>

      <div className="linkingBoxFooter">
        <div className="optionItemBody">
          <div className="linkLabel">Payment Type</div>
          <div className="optionItemRow">
            <CustomSelect
              value={value.paymentType?.valueObj?.customText || "oneTime"}
              onChange={(option) =>
                mergeChange({
                  paymentType: {
                    valueType: "customText",
                    valueObj: { customText: option.value },
                  },
                })
              }
              options={[
                { value: "oneTime", label: "One Time" },
                { value: "recurring", label: "Recurring" },
              ]}
              placeholder={"Select"}
              className="linkingToBox flexColumn flex1 br10 pad6"
              classNames={{
                head: "pad6 flexRow aCenter",
                label: "cDark flex1 cPurple",
                chevron: "optionDatabaseSortKeyIco",
              }}
            />
          </div>
        </div>

        {linkBoxOptions.showTriggerOption ? triggerOption : null}
      </div>
    </>
  );

  return (
    <>
      <OptionItemBody label="Payment Method">
        <ToggleRadioButton
          classNames={{
            container: "linkingOpen flexColumn bgWhite shadow flex1 br10 pad6",
            item: "pad6 flexRow flex1",
            radio: "optionDatabaseRadio",
            radioDecor: "optionDatabaseRadioDecor",
            label: "cGrey",
          }}
          overideDefaultStyle
          value={value.paymentMethod || ""}
          onChange={(x) => mergeChange({ paymentMethod: x })}
          options={["paypal", "stripe", "inAppPurchase"]?.map((x) => ({
            value: x,
            label: _.startCase(x),
          }))}
        />
      </OptionItemBody>

      <OptionItemBody label="Payment Type">
        <CustomSelect
          value={value.paymentType?.valueObj?.customText || "oneTime"}
          onChange={(option) =>
            mergeChange({
              paymentType: {
                valueType: "customText",
                valueObj: { customText: option.value },
              },
            })
          }
          options={[
            { value: "oneTime", label: "One Time Payment" },
            { value: "recurring", label: "Recurring Payment" },
          ]}
          placeholder={"Select Payment Type"}
          className="linkingToBox flexColumn bgWhite shadow flex1 br10 pad6"
          classNames={{
            head: "pad6 flexRow aCenter",
            label: "cGrey flex1 cPurple",
            chevron: "optionDatabaseSortKeyIco",
          }}
        />
      </OptionItemBody>

      <div className="optionItemBody">
        <div className="cDark fwSemiBold f14 mb10">Payment Details</div>

        {[
          {
            key: "price",
            label:
              value.paymentType?.valueObj?.customText === "recurring"
                ? "Plan Id"
                : "Price",
            className: "mb14",
          },
          { key: "productId", label: "Product Id" },
        ].map(({ key, label, className }) => (
          <div className={"optionItemRow " + className} key={key}>
            <div className="optionConditionTableSelectorFilterItemContext">
              <div className="optionConditionTableSelectorFilterItemHead">
                <div className="optionConditionTableFilterMetaRequirementLabel">
                  {label}
                </div>
              </div>
              <RichTextData
                {...{
                  ...props,

                  value: value?.[key]?.valueObj,
                  onChange: (valueObj) =>
                    mergeChange({
                      [key]: {
                        valueObj,
                        valueType: "textParts",
                      },
                    }),
                }}
              />
            </div>
          </div>
        ))}
      </div>

      <SuccessFailHandler {...props} />
    </>
  );
};

export default LinkToPayment;
