import React from "react";
import _ from "lodash";

import CustomSelect from "../../../Components/etc/CustomSelect";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";



const CustomValueSelector = (props) => {
    const { row, value = {}, onChange } = props;
  
    return (
      <div className="optionConditionTableSelectorFilterItemContext">
        {["customSelect"].includes(row.inputType) ? (
          <>
            <div className="optionConditionTableSelectorFilterItemHead">
              <div className="optionConditionTableFilterMetaRequirementLabel">
                {row.label}
              </div>
            </div>
            <CustomSelect
              value={value.valueObj?.customText || ""}
              onChange={(option) =>
                onChange({
                  valueObj: { customText: option.value },
                  valueType: "customText",
                })
              }
              options={row.options?.map((x) => ({
                value: x,
                label: _.startCase(x),
              }))}
              placeholder={row.placeholder || "Select"}
              className="linkingToBox flexColumn bgWhite shadow flex1 br10 pad6"
              classNames={{
                head: "pad6 flexRow aCenter",
                label: "cGrey flex1 cPurple",
                chevron: "optionDatabaseSortKeyIco",
              }}
            />
          </>
        ) : (
          <>
            <div className="optionConditionTableSelectorFilterItemHead">
              <div className="optionConditionTableFilterMetaRequirementLabel">
                {row.label}
              </div>
            </div>
            <RichTextData
              {...{
                ...props,
                value: value.valueObj,
                onChange: (valueObj) => {
                  onChange({
                    valueObj,
                    valueType: "textParts",
                  });
                },
              }}
            />
          </>
        )}
      </div>
    );
  };

  export default CustomValueSelector