import React, { useState, useEffect } from "react";

import _dom from "../../../../appxolo-engine/modules/dom/Dom";
import styleModule from "../../../../Modules/style/style-module";

import Text from "../Text/Text";
import Image from "../Image/Image";
import Input from "../Input/Input";
import Map from "../Map/Map";
import Media from "../Media/Media";

const { DomTree, DomTreeNode } = _dom;

const Component = (props) => {
  const { element } = props;
  const activeTab =
    element.value?.data?.tabs?.[element.value?.data?.activeTabIndex || 0];
  const { to: componentId } = activeTab?.containerType?.screenConfig || {};

  const [component, setComponent] = useState(null);
  const [dom, setDom] = useState(null);
  const [hovering, setHovering] = useState(null);

  useEffect(() => {
    const component = props.components?.find((x) => x._id === componentId);

    setComponent(component);

    const domTreeNode = new DomTreeNode(
      "ROOT",
      { elementType: "container" },
      component?.data?.dom?.children
    );
    const domTree = new DomTree(domTreeNode);

    setDom(domTree);
  }, [componentId]);

  const propsToPass = {
    ...props,
    dom,
    component,
    builderData: null,
    setBuilderData: null,
    triggerRender: null,
    ts: null,
  };

  const elements = dom?.tree?.children || [];

  return (
    <div
      style={{ position: "relative" }}
      onMouseOver={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      {elements.map((element, index) => (
        <CanvasElement
          key={element.id}
          {...{
            ...propsToPass,
            element,
            indices: [index],
            style:
              styleModule.getElementStyleData(element)?.[
                element.value?.elementType
              ]?.builderWrapper,
          }}
        />
      ))}

      {hovering ? <HoverBanner component={component} /> : null}
    </div>
  );
};

const HoverBanner = ({ component }) => {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: "#000c",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        color: "#fff",
      }}
    >
      <div>
        You can't modify component elements on this screen.
        <br />
        <a href={component?._id} target="_blank">
          Click here
        </a>{" "}
        to go to the component page
      </div>
    </div>
  );
};

const CanvasElement = (props) => {
  return (
    <div style={props.style}>
      <RenderCanvasElement {...props} />
    </div>
  );
};

const RenderCanvasElement = React.forwardRef((props, ref) => {
  const elementMap = {
    container: <Container ref={ref} {...props} />,
    text: <Text {...props} />,
    image: <Image {...props} />,
    input: <Input {...props} />,
    map: <Map {...props} />,
    media: <Media {...props} />,
  };

  return elementMap[props.element.value.elementType];
});

const Container = React.forwardRef((props, ref) => {
  const element = props.element;
  const children = element.children;
  const activeTab =
    element.value?.data?.tabs?.[element.value?.data?.activeTabIndex || 0];

  let style =
    styleModule.getElementStyleData(element)?.[element.value?.elementType]
      ?.builder;

  return (
    <div ref={ref} style={style}>
      {activeTab?.containerType?.type === "screen" ? (
        <Component {...props} />
      ) : (
        children.map((element, index) => (
          <CanvasElement
            key={element.id}
            {...{
              ...props,
              element,
              indices: [...props.indices, index],
              style:
                styleModule.getElementStyleData(element)?.[
                  element.value?.elementType
                ]?.builderWrapper,
            }}
          />
        ))
      )}
    </div>
  );
});
export default Component;
