import React from "react";

const DateTimeInput = React.forwardRef((props, ref) => {
  const getFormatedValue = React.useCallback((d) => {
    if (isNaN(d)) return "0000-00-00T00:00";
    const date = new Date(d);

    let dd = date.getDate();
    let MM = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    let hh = date.getHours();
    let mm = date.getMinutes();

    if (isNaN(dd)) dd = 0;
    if (isNaN(MM)) MM = 0;
    if (isNaN(yyyy)) yyyy = "0000";
    if (isNaN(hh)) hh = 0;
    if (isNaN(mm)) mm = 0;

    dd = dd < 10 ? "0" + dd.toString() : dd.toString();
    MM = MM < 10 ? "0" + MM.toString() : MM.toString();
    yyyy = yyyy.toString();
    hh = hh < 10 ? "0" + hh.toString() : hh.toString();
    mm = mm < 10 ? "0" + mm.toString() : mm.toString();

    return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
  }, []);

  return (
    <input
      ref={ref}
      style={Object.assign({}, { width: "100%" }, props.style || {})}
      type="datetime-local"
      value={getFormatedValue(props.value)}
      onChange={(event) =>
        props.onChange(new Date(event.target.value).getTime())
      }
    />
  );
});

export default DateTimeInput;
