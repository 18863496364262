import React from "react";
import update from "immutability-helper";
import MarkLocation from "./MarkLocation";
import MapIcon from "./MapIcon";

const Navigate = (props) => {
  const { value = {}, onChange } = props;
  const operationObj = value || {};
  const setOperationObj = (obj) => {
    onChange(update(operationObj, { $merge: obj }));
  };
  const locationMarks = operationObj.locationMarks || [];

  const source = locationMarks[0] || {};
  const destination = locationMarks[1] || {};

  const setLocationMarksObj = (obj) =>
    setOperationObj({
      locationMarks: update(locationMarks, { $merge: obj }),
    });

  return (
    <div>
      <SetSource
        {...props}
        value={source}
        onChange={(value) => setLocationMarksObj({ 0: value })}
      />
      <SetDestintion
        {...props}
        value={destination}
        onChange={(value) => setLocationMarksObj({ 1: value })}
      />
    </div>
  );
};

const SetSource = (props) => {
  const { value = {}, onChange } = props;

  const sourceObj = value || {};
  const setSourceObj = (obj) => onChange(update(sourceObj, { $merge: obj }));

  return (
    <div>
      <MarkLocation
        {...props}
        value={sourceObj.origin}
        onChange={(value) => setSourceObj({ origin: value })}
        markerNo={"red"}
        defaultType={"currentLocation"}
        options={options}
      />
      <hr style={styleObj.hr} />
      <MapIcon
        {...props}
        value={sourceObj.icon}
        onChange={(val) => setSourceObj({ icon: val })}
      />
    </div>
  );
};
const SetDestintion = (props) => {
  const { value = {}, onChange } = props;
  const destObj = value || {};
  const setDestObj = (obj) => onChange(update(destObj, { $merge: obj }));

  return (
    <div>
      <MarkLocation
        {...props}
        value={destObj.origin}
        onChange={(value) => setDestObj({ origin: value })}
        markerNo={"green"}
      />
      <hr style={styleObj.hr} />
      <MapIcon
        {...props}
        value={destObj.icon}
        onChange={(val) => setDestObj({ icon: val })}
      />
    </div>
  );
};

const options = [
  { name: "Current Location", value: "currentLocation" },
  { name: "Input Value", value: "valueOfInputElement" },
  { name: "Calculation", value: "calculation" },
];

const styleObj = {
  add: {
    background: "gray",
    padding: "3px",
    textAlign: "center",
    fontSize: "small",
    cursor: "pointer",
    color: "white",
  },
  sort: {
    padding: "6px",
    backgroundColor: "#808080",
  },
  sortTitle: {
    color: "#ffc100",
    fontSize: "x-small",
  },
  select: {
    background: 0,
    border: 0,
    color: "white",
    width: "100%",
  },
  option: {
    color: "gray",
  },
  lineColor: {
    backgroundColor: "#b6b6b6",
    padding: "6px",
  },
  title: {
    fontSize: "x-small",
    color: "white",
    margin: "0 0 5px 0",
    fontWeight: "bold",
  },
  label: {
    color: "white",
    fontSize: "small",
  },
  hr: {
    margin: 0,
    borderColor: "#ddd",
  },
};

export default Navigate;
