import React, { useState } from "react";
import update from "immutability-helper";

import ToggleButton from "../../../../../Components/Input/ToggleButton";
import UrlSource from "./UrlSource";
import ImageUpload from "./ImageUpload";
import api from "../../../../../Services/Api/api";
import SearchIcons from "./SearchIcons";

const StaticImageSources = (props) => {
  const { maxLength = Infinity, styles = {} } = props;

  const [source, setSource] = useState("url");
  const [visible, setVisible] = useState(false);
  const options = props.options || [
    { label: "URL", value: "url" },
    { label: "Image", value: "image" },
    { label: "Icon", value: "icon" },
  ];

  let images;
  if (props.value && typeof props.value === "string") {
    images = [props.value];
  } else if (typeof props.value === "object" && props.value instanceof Array) {
    images = props.value;
  } else images = [];

  const appendSrc = (x) => {
    let updated = update(images, { $push: [x] });
    if (updated.length > maxLength) {
      const elementsToRemove = updated.length - maxLength;
      updated.splice(0, elementsToRemove);
    }
    props.onChange(updated);
    setVisible(false)
  };

  const sliceSrc = (pos, no) => {
    props.onChange(update(images, { $splice: [[pos, no]] }));
  };

  const methods = {
    url: () => <UrlSource {...props} onSubmit={appendSrc} />,
    image: () => (
      <ImageUpload onChange={(file) => appendSrc(api.getFileLink(file))} />
    ),
    icon: () => (
      <SearchIcons
        iconChooseHandler={(urlObj, src) => {
          let source = src || urlObj?.images?.["128"];
          if (source) appendSrc(source);
        }}
      />
    ),
  };

  return (
    <div style={{ position: "relative" }}>
      <div onClick={() => setVisible(true)}>
        {props.label || "Select Image"}
      </div>
      <div>
        {images?.map((x, i) => {
          return (
            <div key={i} style={{ position: "relative" }}>
              <div onClick={() => sliceSrc(i, 1)} className="roundMinus">
                &#8211;
              </div>
              <img
                alt=""
                src={x}
                style={Object.assign({ width: "40px" }, styles.imagePreview)}
              />
            </div>
          );
        })}
      </div>

      {visible ? (
        <>
          <div
            style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0 }}
            onClick={() => setVisible(false)}
          />

          <div
            style={{
              position: "absolute",
              background: "white",
              padding: "10px",
              zIndex: 2,
            }}
          >
            <ToggleButton
              value={source}
              onChange={setSource}
              options={options}
            />
            <div style={{ padding: "5px" }}>{methods[source]?.()}</div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default StaticImageSources;
