import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";

const JustifyContent = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  const items = [
    [
      {
        key: "flex-start",
        label: "Default",
        subtext: "Start left",
        decorClass: "jLeft",
      },
      {
        key: "flex-end",
        label: "Right",
        subtext: "start right",
        decorClass: "jRight",
      },
    ],
    [
      {
        key: "space-between",
        label: "Stretch",
        subtext: "End to end",
        decorClass: "jSpaceBetween",
      },
      {
        key: "space-evenly",
        label: "Even",
        subtext: "Spaced equal",
        decorClass: "jSpaceEvenly",
      },
    ],
    [
      {
        key: "center",
        label: "Center",
        subtext: "Horizontally centered",
        decorClass: "jCenter",
      },
    ],
  ];

  return (
    <OptionItem
      className="mainCondition"
      optionItemLabel="Horizontal Orientation"
    >
      <div className="optionItemBody">
        {items.map((items, i) => (
          <div className="optionItemRow" key={i}>
            {items.map((item) => {
              const active = item.key === styles.justifyContent;
              return (
                <div className="optionItemBox" key={item.key}>
                  <div
                    className={
                      "advancedRadioItem" + (active ? " lightActive" : "")
                    }
                    onClick={() =>
                      mergeStyle({
                        justifyContent: item.key,
                        display: styles.display || "flex",
                      })
                    }
                  >
                    <div className={"horizontalOptions " + item.decorClass}>
                      <div className="horizontal one"></div>
                      <div className="horizontal two"></div>
                      <div className="horizontal three"></div>
                    </div>
                    <div className="advancedMultiLabel">
                      {item.label} <span>{item.subtext}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </OptionItem>
  );
};

export default JustifyContent;
