import React from "react";
import _ from 'lodash'

const FloatingProperties = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          display: "flex",
          opacity: 0.8,
          left: 0,
          bottom: 0,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={() => {
            const { parent, index } = props.dom.findNodeAndParent(
              props.element.id
            );
            const newNode = props.dom.duplicateNodeWithUniqueIds(props.element);
            props.dom.addNode(parent.id, newNode, index + 1);
          }}
        >
          Duplicate
        </button>
        <button
          onClick={() => {
            if (
              window.confirm(
                `Are you sure, you want to delete this (#${props.element.id}) ${_.startCase(props.element.value.elementType)} element?`
              )
            )
              props.dom.deleteNodeById(props.element.id);
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default FloatingProperties;
