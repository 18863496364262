import React from "react";
import RichTextData from "../../Common/DataProperties/RichTextData";
import OptionItem from "../../../../../Components/etc/OptionItem";

const TextData = (props) => {
  return (
    <OptionItem optionItemLabel="Content">
      <div className="optionItemBody">
        <div className="optionItemRow">
          <div className="optionDatabaseBoxWrapper">
            <div className="optionDatabaseTextBox">
              <div className="optionDatabaseTextBoxLabel">Text</div>
              <RichTextData {...props} /> 
            </div>
          </div>
        </div>
      </div>
    </OptionItem>
  );
};

export default TextData;
