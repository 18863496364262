import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import AppModal from "../../Components/Models/app-modal";
import api from "../../Services/Api/api";
import { toast } from "react-toastify";
import NavBar from "../Common/NavBar";
import withRouter from "../../Components/Wrapper/with-router";

const DatabaseViewerInner = (props) => {
  const [loading, setLoading] = useState(false);
  const [databaseId, setDatabaseId] = useState();
  const [visibleModal, setVisibleModal] = useState(null);
  const [newTableName, setNewTableName] = useState("");
  const [newDatabaseName, setNewDatabaseName] = useState("");
  const [newDatabaseType, setNewDatabaseType] = useState("");

  const databases = props.databases;
  const database = databases?.find((x) => x._id?.toString() === databaseId);
  const tables = database?.tables;

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const { databases } = await api.get("v1/database");
      setLoading(false);
      props.setScreenState({ databases }, false, "BUILDER");
    } catch (e) {
      console.error(e);
      setLoading(false);
      toast.error(e.message);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const createTable = async (payload) => {
    setLoading(true);
    await api
      .post(`v1/database/${payload.dbId}/table`, {
        tableName: payload.tableName,
      })
      .then(() => {
        load();
        setVisibleModal(null);
      })
      .catch((err) => {
        window.alert("Error creating table: " + err.message);
      });
    setLoading(false);
  };

  const createDatabase = async (payload) => {
    setLoading(true);
    await api
      .post("v1/database", payload)
      .then(() => {
        load();
        setNewDatabaseName("");
        setNewDatabaseType("");
        setVisibleModal(null);
      })
      .catch((e) => window.alert(e.message));
    setLoading(false);
  };

  const selectDatabase = async (databaseId) => {
    setDatabaseId(databaseId?.toString());
  };

  return (
    <div>
      <NavBar activeLink="/database" />
      <div className="databaseViewer">
        <div
          style={{
            margin: "10px 10px 10px 20px",
            fontSize: 25,
            fontWeight: "400",
            background: "#c9c9c9",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              paddingLeft: "10px",
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            Datbases
            {loading ? (
              <div
                className="loader large"
                style={{
                  border: "4px solid #fff",
                  borderTop: "4px solid #3498db",
                }}
              ></div>
            ) : null}
          </div>

          <div
            style={{
              justifyContent: "flex-start",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {databases?.map((item) => (
              <div
                style={{
                  minWidth: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#fff",
                  margin: "10px",
                  fontSize: 15,
                  borderRadius: 5,
                  boxShadow: "#d0d0d0 3px 4px 8px",
                  padding: "10px",
                  cursor: loading ? "auto" : "pointer",
                  border:
                    item._id?.toString() === databaseId
                      ? "2px solid blue"
                      : "unset",
                }}
                key={item._id}
                onClick={() => {
                  if (!loading) selectDatabase(item._id);
                }}
              >
                <div>Name: {item.name}</div>
                <div>ID: {item._id}</div>
                <div>Type: {item.type}</div>
              </div>
            ))}

            <div
              style={{
                minWidth: "200px",
                height: "90px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#fff",
                margin: "10px",
                fontSize: 20,
                borderRadius: 5,
                boxShadow: "#d0d0d0 3px 4px 8px",
                flexDirection: "column",
                cursor: "pointer",
              }}
              key={"+new"}
              onClick={() => {
                if (!loading) {
                  setNewTableName("");
                  setVisibleModal("newDatabase");
                }
              }}
            >
              <PlusIcon style={{ width: "40px", height: "40px" }} />

              <div style={{ fontSize: "15px", marginTop: "10px" }}>
                Add New Database
              </div>
            </div>
          </div>
        </div>

        <div style={database ? { display: "block" } : { display: "none" }}>
          <div
            style={{
              margin: "10px 10px 10px 10%",
              fontSize: 25,
              fontWeight: "400",
            }}
          >
            {database?.name}
          </div>

          <div
            style={{
              margin: "20px",
              justifyContent: "flex-start",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {tables?.map((table) => (
              <Link
                to={`/database/${database._id}/table/${table.name}?prjectId=${
                  props?.projectId || ""
                }`}
                style={{
                  minWidth: "200px",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#fff",
                  margin: "10px",
                  fontSize: 20,
                  borderRadius: 5,
                  boxShadow: "#d0d0d0 3px 4px 8px",
                  textDecoration: "none",
                }}
                key={table.name}
              >
                <div>{table.name}</div>
              </Link>
            ))}

            <div
              style={{
                minWidth: "200px",
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#d8efff",
                margin: "10px",
                fontSize: 20,
                borderRadius: 5,
                boxShadow: "#d0d0d0 3px 4px 8px",
                flexDirection: "column",
                cursor: "pointer",
              }}
              key={"+new"}
              onClick={() => {
                if (!loading) {
                  setNewTableName("");
                  setVisibleModal("newTable");
                }
              }}
            >
              <PlusIcon style={{ width: "40px", height: "40px" }} />

              <div style={{ fontSize: "15px", marginTop: "10px" }}>
                Add New Table
              </div>
            </div>
          </div>
        </div>
      </div>

      <AppModal
        {...{
          title: "Create New Table",
          visible: visibleModal === "newTable",
          handleClose: () => setVisibleModal(null),
          disableBtns: loading || !newTableName,
          submitBtnText: loading ? "Loading" : "Create Table",
          onSubmit: (e) =>
            createTable({
              tableName: newTableName,
              dbId: database._id,
            }),
        }}
      >
        <div>Table name</div>
        <input
          value={newTableName}
          onChange={(e) => setNewTableName(e.target.value)}
          placeholder="Type Here"
        ></input>
      </AppModal>

      <AppModal
        {...{
          title: "Create New Database",
          visible: visibleModal === "newDatabase",
          handleClose: () => setVisibleModal(null),
          disableSubmit: loading || !newDatabaseName || !newDatabaseType,
          submitBtnText: loading ? "Loading" : "Create Database",
          onSubmit: (e) => {
            createDatabase({ name: newDatabaseName, type: newDatabaseType });
          },
        }}
      >
        <div>Database name</div>
        <input
          value={newDatabaseName}
          onChange={(e) => setNewDatabaseName(e.target.value)}
          placeholder="Type Here"
        ></input>

        <div>Database Type</div>
        <select
          value={newDatabaseType}
          onChange={(e) => setNewDatabaseType(e.target.value)}
        >
          <option value="">Select</option>
          <option value="remote">Remote</option>
          <option value="local">Local</option>
        </select>
      </AppModal>
    </div>
  );
};

const PlusIcon = ({ style = {} }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        alignSelf: "center",
        ...style,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "20%",
          background: "black",
          margin: "40% 0",
          borderRadius: "3px",
        }}
      ></div>
      <div
        style={{
          background: "black",
          width: "20%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: "40%",
          borderRadius: "3px",
        }}
      ></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  databases: state.vState.BUILDER.databases,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "DATABASE_VIEWER") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const DatabaseViewer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DatabaseViewerInner);

const DatabaseViewerScreen = withRouter((props) => (
  <DatabaseViewer
    key={props.router.params.componentId}
    {...{
      ...props.router.params,
      projectId: props.router.searchParams.projectId,
    }}
  />
));

export default DatabaseViewerScreen;
