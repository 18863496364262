import React from "react";

const TimeInput = React.forwardRef((props, ref) => {
  // const timestampTohhmm = React.useCallback((d) => {
  //   if (isNaN(d)) return "";
  //   const date = new Date(d);
  //   let hh = date.getUTCHours();
  //   let mm = date.getUTCMinutes();
  //   if (isNaN(hh)) hh = 0;
  //   if (isNaN(mm)) mm = 0;

  //   hh = hh < 10 ? "0" + hh.toString() : hh.toString();
  //   mm = mm < 10 ? "0" + mm.toString() : mm.toString();

  //   return hh + ":" + mm;
  // }, []);

  // const hhmmToTimestamp = (hhmm) => {
  //   const value = hhmm.split(":");
  //   return parseInt(value[0]) * 60 * 60 * 1000 + parseInt(value[1]) * 60 * 1000;
  // };

  return (
    <input
      ref={ref}
      style={Object.assign({}, { width: "100%" }, props.style || {})}
      type="time"
      value={props.value || ""}
      // value={timestampTohhmm(props.value)}
      onChange={(event) => {
        const value = event.target.value;
        // props.onChange(hhmmToTimestamp(value))
        props.onChange(value);
      }}
    />
  );
});

export default TimeInput;
