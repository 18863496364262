import React from "react";

import { OptionItemBody } from "../../../Components/etc/OptionItem";
import CustomSelect from "../../../Components/etc/CustomSelect";

const MicrophoneOperations = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <OptionItemBody label="Action">
        <CustomSelect
          value={value.action || "toggle"}
          onChange={(option) => mergeChange({ action: option.value })}
          options={[
            { value: "toggle", label: "Toggle" },
            { value: "mute", label: "Mute" },
            { value: "unmute", label: "Unmute" },
          ]}
          placeholder={"Select Action"}
          className="linkingToBox flexColumn bgWhite shadow flex1 br10 pad6"
          classNames={{
            head: "pad6 flexRow aCenter",
            label: "cGrey flex1 cPurple",
            chevron: "optionDatabaseSortKeyIco",
          }}
        />
      </OptionItemBody>
    </>
  );
};

export default MicrophoneOperations;
