import React, { useCallback, useState } from "react";
import { Button } from "react-bootstrap";

import api from "../../Services/Api/api";
import EditTable from "./EditTable";
import RenderDataTable from "./RenderDataTable";
import withRouter from "../../Components/Wrapper/with-router";
import NavBar from "../Common/NavBar";
import { toast } from "react-toastify";

const TableViewer = (props) => {
  const [loading, setLoading] = useState(false);
  const [database, setDatbase] = useState(null);
  const [table, setTable] = useState(null);
  const [visibleModal, setVisibleModal] = useState(null);

  const { databaseId: dbId, tableName, projectId } = props;

  const load = useCallback(async ({ dbId, tableName }) => {
    try {
      setLoading(true);
      const { databases } = await api.get("v1/database", {
        filter: JSON.stringify({ where: { _id: dbId } }),
      });

      const database = databases?.[0];
      if (!database) throw new Error("Could not find the database details");

      setDatbase(database);
      const table = database.tables?.find((x) => x.name === tableName);
      if (!table) throw new Error("Table not found! Wrong table name");
      setTable(table);

      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error(e.message);
      setLoading(false);
    }
  }, []);

  const deleteTable = async () => {
    await api
      .delete(`v1/database/${database._id}/table`, {
        tableName: table.name,
      })
      .then(() => {
        props.router.navigate(`/database?projectId=${projectId || ""}`);
      })
      .catch((e) => window.alert("Error deleting table: " + e.message));
  };

  React.useEffect(() => {
    if (dbId && tableName) load({ dbId, tableName });
  }, [dbId, tableName, load]);

  return (
    <div>
      <NavBar activeLink="/database" />
      <div className="databaseViewer">
        <div
          style={{
            display: "flex",
            margin: "10px 2% 10px 2%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 25,
              fontWeight: "400",
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {database ? (
              <>
                <span
                  style={{
                    background: "#dfdbdb",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >{`${database.name}`}</span>
                {"  /  "}
                <span
                  style={{
                    background: "#dfdbdb",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >{`${table?.name}`}</span>
              </>
            ) : (
              "Loading..."
            )}

            {loading ? (
              <div
                className="loader large"
                style={{
                  border: "4px solid #fff",
                  borderTop: "4px solid #3498db",
                }}
              ></div>
            ) : null}
          </div>
          <div>
            <Button
              onClick={() => {
                let typedName = window.prompt(
                  "Type the table name to confirm delete"
                );
                if (typedName === table.name) {
                  deleteTable();
                } else if (typedName !== null) {
                  window.alert("Wrong table name");
                }
              }}
              variant="danger"
            >
              Delete Table
            </Button>{" "}
            <Button onClick={() => setVisibleModal("editTable")}>
              Edit Table
            </Button>
          </div>
        </div>

        {database &&
        table &&
        table.columns &&
        !["local"].includes(database.type) ? (
          <div
            style={{
              width: "96%",
              margin: "auto",
            }}
          >
            <RenderDataTable {...{ database, table }} /> 
          </div>
        ) : null}
      </div>

      {database && table ? (
        <EditTable
          key={table.name}
          visible={visibleModal === "editTable"}
          {...{
            tableName: table.name,
            database,
            onChange: ({ newTableName }) => {
              if (newTableName) {
                props.router.navigate(
                  `/database/${database._id}/table/${newTableName}?projectId=${
                    projectId || ""
                  }`
                );
              } else {
                load({ dbId, tableName: newTableName || table.name });
              }
            },
            close: () => setVisibleModal(null),
          }}
        />
      ) : null}
    </div>
  );
};

const TableViewerScreen = withRouter((props) => (
  <TableViewer
    key={props.router.params.componentId}
    {...{
      ...props,
      ...props.router.params,
      projectId: props.router.searchParams.projectId,
    }}
  />
));

export default TableViewerScreen;
