import React from "react";
import update from "immutability-helper";

import conditionObj from "../../../appxolo-engine/modules/calculation/condition-obj";
import ValueSelector from "./ValueSelector";
import Chevron from "../../../Components/etc/Chevron";
import CustomSelect from "../../../Components/etc/CustomSelect";
import {
  backendValueTypes,
  emailTepmplateValueTypes,
  frontValueTypes,
} from "../../../Modules/calculation/builder-value-types";
import CalculationValueSelector from "./CalculationValueSelector";

const ConditionsBox = (props) => {
  const conditionBoxes = props.value || [{}];
  const setConditionBoxes = (conditionBoxes) => props.onChange(conditionBoxes);

  const setConditionBox = (conditionBox, i) =>
    setConditionBoxes(
      update(conditionBoxes, { $merge: { [i]: conditionBox } })
    );

  const updateConditionBox = (obj, i) =>
    setConditionBox({ ...(conditionBoxes[i] || {}), ...obj }, i);
  const addConditionBox = () => {
    setConditionBoxes([...conditionBoxes, {}]);
  };
  const removeConditionBox = (i) => {
    setConditionBoxes(update(conditionBoxes, { $splice: [[i, 1]] }));
  };

  const setOperator = (operator, i) => {
    updateConditionBox({ operator }, i);
  };
  const setValueType = (valueType, i) => {
    updateConditionBox({ valueType }, i);
  };
  const setValueObj = (valueObj, i) => {
    updateConditionBox({ valueObj }, i);
  };

  function haveCommonDataType(arr, dataType) {
    if (!arr || !arr.length) return true;
    else if (dataType === "any") return true;
    else if (arr.includes("any")) return true;
    return arr.includes(dataType);
  }

  const getValidConditionOperators = (i) => {
    if (!i) {
      return [
        {
          value: "if",
          label: "If",
        },
      ];
    } else {
      return conditionObj.operatorList.filter((x) => x.outputType !== "null");
    }
  };

  const getValidConditionValueTypes = (i, conditionBoxes) => {
    const conditionBox = conditionBoxes[i];
    if (!conditionBox.operator) {
      return [];
    } else {
      return conditionObj.valueTypes.filter(
        (x) =>
          (!props.availableFor ||
            x.availableFor.includes(props.availableFor)) &&
          haveCommonDataType(
            conditionObj.operators[conditionBox.operator]?.inputTypes,
            x.outputType
          )
      );
    }
  };

  const availableFor = props.availableFor;
  const builderValueTypes =
    availableFor === "backend"
      ? backendValueTypes
      : availableFor === "emailTemplate"
      ? emailTepmplateValueTypes
      : frontValueTypes;

  return (
    <div className="optionCondition" style={styleObj.container}>
      {conditionBoxes.map((conditionBox, i) => {
        return (
          <div className="optionConditionItem" key={i}>
            <div className="optionConditionItemInner">
              <div className="optionConditionItemHead">
                <div className="optionConditionItemContext">
                  <CustomSelect
                    classNames={{
                      head: "optionConditionItemMeta",
                      label: "optionConditionItemMetaField",
                      chevron: "optionConditionItemChevron",
                    }}
                    onChange={(option) => setOperator(option.value, i)}
                    value={conditionBox?.operator || ""}
                    options={getValidConditionOperators(i)}
                    placeholder={"--"}
                  />

                  <CustomSelect
                    classNames={{
                      head: "optionConditionItemValue",
                      label: "optionConditionItemValueField",
                      chevron: "optionConditionItemChevron",
                    }}
                    className="optionConditionItemValueField"
                    onChange={(option) => setValueType(option.value, i)}
                    value={conditionBox?.valueType || ""}
                    // options={getValidConditionValueTypes(i, conditionBoxes)}
                    options={builderValueTypes}
                    placeholder={"Select Condition"}
                  />
                </div>

                {i ? (
                  <div
                    onClick={() => removeConditionBox(i)}
                    className="optionConditionRemove"
                  >
                    <div className="optionConditionRemoveLine"></div>
                  </div>
                ) : (
                  <div onClick={addConditionBox} className="optionConditionAdd">
                    <div className="optionConditionAddLine one"></div>
                    <div className="optionConditionAddLine two"></div>
                  </div>
                )}
              </div>

              {/* <ValueSelector
                {...props}
                valueType={conditionBox.valueType}
                value={conditionBox.valueObj}
                onChange={(obj) => setValueObj(obj, i)}
              /> */}

              <CalculationValueSelector
                {...props}
                valueType={conditionBox.valueType}
                value={conditionBox.valueObj}
                onChange={(obj) => setValueObj(obj, i)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const styleObj = {
  container: {
    textAlign: "left",
  },
  condition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#00f1f1",
    padding: "0px 7px 0px",
    gap: "2%",
  },
  select: {
    width: "100%",
    border: "none",
    background: "none",
    color: "white",
    fontWeight: "bold",
    fontSize: "12px",
    padding: "7px 0 0 0",
  },
  valueType: {
    width: "100%",
    border: "none",
    background: "none",
    color: "gray",
    fontWeight: "bold",
    padding: "7px 0 0 0",
  },
  option: {
    backgroundColor: "#f1f1f1",
    color: "gray",
  },
};

export default ConditionsBox;
