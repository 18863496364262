import React, { useEffect } from "react";

import linkingObj from "../../../appxolo-engine/modules/linking/linking-obj";
import SuccessFailHandler from "./SuccessFailHandler";
import {
  OptionItemBody,
  OptionItemBodyMultiRow,
} from "../../../Components/etc/OptionItem";
import CustomValueSelector from "../QuickValueSelector/CustomValueSelector";
import CustomSelect from "../../../Components/etc/CustomSelect";

const WebrtcOperations = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const valueTypes = linkingObj.valueTypes.find(
    (x) => x.value === "webrtcRoom"
  ).valueTypes;

  const menuItems = {
    joinWebrtcRoom: [
      {
        label: "Room Details",
        rows: [
          {
            label: "Room Id",
            className: "mb14",
            key: "roomId",
          },
        ],
      },
    ],
    leaveWebrtcRoom: null,
  };

  const valueType = value.valueType || null;

  useEffect(() => {
    if (!valueType) {
      onChange({ ...value, valueType: "joinWebrtcRoom" });
    }
  }, [valueType, onChange]);

  return (
    <div>
      <OptionItemBody label="Action">
        <CustomSelect
          value={value.valueType || ""}
          onChange={(option) => mergeChange({ valueType: option.value })}
          options={valueTypes}
          placeholder={"Select Action"}
          className="linkingToBox flexColumn bgWhite shadow flex1 br10 pad6"
          classNames={{
            head: "pad6 flexRow aCenter",
            label: "cGrey flex1 cPurple",
            chevron: "optionDatabaseSortKeyIco",
          }}
        />
      </OptionItemBody>
      <RenderMenuItems
        {...{
          ...props,
          value: value.valueObj?.[valueType] || {},
          onChange: (obj) =>
            mergeChange({
              valueObj: { ...(value.valueObj || {}), [valueType]: obj },
            }),
          items: menuItems[valueType],
        }}
      />
      <SuccessFailHandler {...props} />
    </div>
  );
};

const RenderMenuItems = (props) => {
  const { value = {}, onChange, items } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      {items?.map((group, index) => (
        <OptionItemBodyMultiRow
          label={group.label}
          key={index}
          rows={group.rows.map((row) => ({
            ...row,
            render: () => (
              <CustomValueSelector
                {...{
                  ...props,
                  row,
                  value: value[row.key],
                  onChange: (x) => mergeChange({ [row.key]: x }),
                }}
              />
            ),
          }))}
        />
      ))}
    </>
  );
};
export default WebrtcOperations;
