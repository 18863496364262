import React from "react";
import _ from "lodash";
import styleModule from "../../../../Modules/style/style-module";

class Text extends React.PureComponent {
  updateStaticText(x) {
    console.log(x);
  }

  render() {
    const element = this.props.element;
    const textParts = element.value?.data?.tabs?.[
      element.value?.data?.activeTabIndex || 0
    ]?.textData?.textParts
      ?.map((x) =>
        !x?.valueType || x?.valueType === "customText"
          ? x?.valueObj?.customText
          : "(" + _.startCase(x?.valueType) + ")"
      )
      ?.filter((x) => x !== undefined);
    const text = textParts?.length ? textParts.join("") : undefined;

    let style =
      styleModule.getElementStyleData(element)?.[element.value?.elementType]
        ?.builder;

    return <div style={style}>{text || "Text"}</div>;
  }
}

export default Text;
