import React, { createRef, Fragment, useEffect, useState } from "react";
import update from "immutability-helper";
import _ from "lodash";

import conditionObj from "../../../appxolo-engine/modules/calculation/condition-obj";
import CustomSelect from "../../../Components/etc/CustomSelect";
import { PassedParameterFromParentSelector } from "./PassedParamSelector";
import {
  backendValueTypes,
  emailTepmplateValueTypes,
  frontValueTypes,
} from "../../../Modules/calculation/builder-value-types";
import CalculationValueSelector from "./CalculationValueSelector";

class CalculationBox extends React.Component {
  scrollRef = createRef(null);

  componentDidMount() {
    if (!this.props.value?.length) this.addCalculation();
  }

  setCalculations(calculations) {
    this.props.onChange(calculations);
  }

  addCalculation(calculation) {
    calculation = calculation ? calculation : {};
    calculation.id =
      calculation.id || "#" + Math.random().toString(10).substring(2, 7);

    this.setCalculations(
      update(this.props.value || [], { $push: [calculation] })
    );

    setTimeout(() => {
      if (this.scrollRef.current)
        this.scrollRef.current.scroll(
          0,
          this.scrollRef.current.scrollHeight || 0
        );
    }, 100);
  }

  removeNthCalculation = (n) => {
    this.setCalculations(update(this.props.value || [], { $splice: [[n, 1]] }));
  };

  setNthCalculation = (calculation, n) =>
    this.setCalculations(
      update(this.props.value || [], { $merge: { [n]: calculation } })
    );

  updateNthCalculation(obj, n) {
    return this.setNthCalculation(
      { ...(this.props.value?.[n] || {}), ...obj },
      n
    );
  }

  getCalculationInputs(calculations, index) {
    return calculations?.filter((x, i) => i < index).map((x) => x.id);
  }

  getValidValueTypes = (i, calculationBoxes) => {
    const calculationBox = calculationBoxes[i];
    if (false && i && !calculationBox.operator) {
      return [];
    } else {
      return conditionObj.valueTypes.filter(
        (x) =>
          (!this.props.availableFor ||
            x.availableFor.includes(this.props.availableFor)) &&
          this.haveCommonDataType(
            conditionObj.operators[calculationBox.operator]?.inputTypes,
            x.outputType
          )
      );
    }
  };

  haveCommonDataType(arr, dataType) {
    if (!arr || !arr.length) return true;
    else if (dataType === "any") return true;
    else if (arr.includes("any")) return true;
    return arr.includes(dataType);
  }

  getBuilderValueTypes({ availableFor }) {
    return availableFor === "backend"
      ? backendValueTypes
      : availableFor === "emailTemplate"
      ? emailTepmplateValueTypes
      : frontValueTypes;
  }

  render() {
    const {
      props: { value: calculations, disableAddAnotherItem },
    } = this;

    const disableAddCalculation = calculations?.find((x) => !x.valueType);

    return (
      <div className="calculationOutside">
        <div className="calculationWindow" ref={this.scrollRef}>
          <div className="calcHead">
            <div
              className="calcHeadAction"
              onClick={() => this.props.setVisible(false)}
            >
              <div className="calculationHeadBackLine one"></div>
              <div className="calculationHeadBackLine two"></div>
              <div className="calculationHeadBackLineHorizontal"></div>
            </div>
            <div className="calcHeadMeta">
              <div className="calcHeadLabel">Calculation</div>

              <span className="calcHeadSublineLabel">
                <span>{calculations?.length || 0}</span> Step
                {calculations?.length > 1 ? "s" : ""} in Calculation
              </span>
            </div>
          </div>

          <div className="calcBody">
            {calculations?.map((calculation, i) => {
              const builderValueTypes = this.getBuilderValueTypes(this.props);
              const categories = builderValueTypes
                .map((x) => x.category)
                .filter((x, i, arr) => arr.indexOf(x) === i);

              return (
                <CalculationItem
                  {...{
                    ...this.props,
                    key: calculation.id,
                    calculation,
                    i,
                    removeCalculation: () => this.removeNthCalculation(i),
                    updateCalculation: (calculation) =>
                      this.updateNthCalculation(calculation, i),
                    builderValueTypes,
                    calculationInputs: this.getCalculationInputs(
                      calculations,
                      i
                    ),
                    categories,
                  }}
                />
              );
            })}

            {!disableAddAnotherItem || !calculations?.length ? (
              <div className="calcItem">
                <div className="calcItemHead">
                  <div
                    className={
                      "addOperation" +
                      (disableAddCalculation ? " disabled" : "")
                    }
                    onClick={() =>
                      !disableAddCalculation && this.addCalculation()
                    }
                  >
                    <div className="addOperationLabel">Add Operation</div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const CalculationItem = (props) => {
  const {
    calculation,
    i,
    removeCalculation,
    updateCalculation,
    builderValueTypes,
    calculationInputs,
  } = props;

  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const categoryMap = {
    value: {
      // placeholder: "Get value from input",
      className: "tabValue",
      label: "Value",
      subLabel: "Value",
    },
    database: {
      // placeholder: "Select database operation",
      className: "tabDatabase",
      label: "Database",
      subLabel: "Database operations",
    },
    information: {
      // placeholder: "Get information item",
      className: "tabInformation",
      label: "Information",
      subLabel: "Information panel",
    },
  };
  const categories = Object.keys(categoryMap);

  const builderValueTypeInfo = builderValueTypes.find(
    (x) => x.value === calculation.valueType
  );

  const category = selectedCategory
    ? selectedCategory
    : calculation.valueType
    ? builderValueTypeInfo?.category
    : "value";

  const categoryValueTypes = builderValueTypes.filter(
    (x) => x.category === category
  );

  const categoryList = categories.map((categoryName) => (
    <div
      key={categoryName}
      className={`calcItemHeadTabItem ${
        categoryMap[categoryName]?.className || ""
      } ${categoryName === category ? "active" : ""}`}
      onClick={() => setSelectedCategory(categoryName)}
    >
      {categoryMap[categoryName]?.label || categoryName}
    </div>
  ));

  return (
    <div className="calcItem">
      {i ? (
        <>
          <div className="calcItemHeadAction">
            <div className="calcItemHeadOrder">{i + 1}</div>
            <CustomSelect
              className=""
              onChange={(option) =>
                updateCalculation({ operator: option.value })
              }
              value={calculation?.operator || ""}
              options={conditionObj.operatorList.filter((x) =>
                ["number", "string", "null"].includes(x.outputType)
              )}
              placeholder={"Select Operation"}
              classNames={{
                head: "calcDropdown",
                label: "calcDropdownLabel",
              }}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooterArrow">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              }
            />
          </div>

          <div className="calcItemHead">
            <div className="calcItemRemove" onClick={removeCalculation}>
              <div className="calculationCloseLine one"></div>
              <div className="calculationCloseLine two"></div>
            </div>
            <div className="calcItemHeadTab">{categoryList}</div>
          </div>
        </>
      ) : (
        <>
          <div className="calcItemHead">
            <div className="calcItemHeadOrder">{i + 1}</div>
            <div className="calcItemHeadTab">{categoryList}</div>
          </div>
        </>
      )}

      {selectedCategory ||
      !calculation.valueType ||
      ["currentTableName", "passedParameter"].includes(
        calculation.valueType
      ) ? (
        <div className="calcItemBody">
          <div className="calcItemDecor"></div>
          <div className="calcItemBodyMain">
            {category === "value" &&
            categoryValueTypes.find((x) => x.value === "passedParameter") ? (
              <>
                <div
                  className={
                    i
                      ? "calcItemBodyMainHeaderSecondary"
                      : "calcItemBodyMainHeader"
                  }
                >
                  <div className="calcItemBodyMainHeaderLabel">Parent</div>
                </div>

                <PassedParameterFromParentSelector
                  {...{
                    ...props,
                    valueType: calculation.valueType,
                    value: calculation?.valueObj?.passedParameter,
                    onChange: (obj) =>
                      updateCalculation({
                        valueType: "passedParameter",
                        valueObj: {
                          ...(calculation.valueObj || {}),
                          passedParameter: obj,
                        },
                      }),
                  }}
                />
              </>
            ) : null}

            <div
              className={
                i ? "calcItemBodyMainHeaderSecondary" : "calcItemBodyMainHeader"
              }
            >
              <div className="calcItemBodyMainHeaderLabel">
                {categoryMap[category]?.subLabel}
              </div>
            </div>

            {categoryValueTypes.map((valueType) => {
              return valueType.value === "passedParameter" ? null : (
                <div
                  className="calcItemUnit"
                  key={valueType.value}
                  onClick={() => {
                    if (calculation.valueType !== valueType.value) {
                      updateCalculation({ valueType: valueType.value });
                      setTimeout(() => {
                        setSelectedCategory(null);
                      }, 50);
                    } else {
                      setSelectedCategory(null);
                    }
                  }}
                >
                  <div className="calcItemUnitBox">
                    <div
                      className={
                        valueType.value === calculation.valueType
                          ? "calcItemUnitDecor"
                          : "calcItemUnitDecorUnselected"
                      }
                    ></div>
                    <div className="calcItemUnitMain">
                      <div className="calcItemUnitMainUnselectedLabel">
                        {valueType.label}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="calcItemBody">
          <div className="calcItemDecor"></div>
          <div className="calcItemBodyMain">
            <div className="calcItemBodyMainHeader">
              <div className="calcItemBodyMainHeaderLabel">
                {builderValueTypeInfo?.label}
              </div>
              <div
                onClick={() => {
                  updateCalculation({
                    valueObj: {
                      ...(calculation.valueObj || {}),
                      [calculation.valueType]: null,
                    },
                  });
                  setSelectedCategory(category);
                }}
                className="calcItemBodyMainHeaderAction"
              >
                Clear
              </div>
            </div>

            <CalculationValueSelector
              {...props}
              valueType={calculation.valueType}
              value={calculation.valueObj}
              onChange={(obj) => updateCalculation({ valueObj: obj })}
              calculationInputs={() => calculationInputs}
            />
          </div>
        </div>
      )}
    </div>
  );

  // return (
  //   <div className={"calculationBodyItem" + (i ? " calculationOperator" : "")}>
  //     <div className="calculationBodySide">
  //       <div className="calculationOrder">{i + 1}</div>
  //       <div className="calculationLine"></div>
  //     </div>

  //     <div className="calculationBodyMain">
  //       <div className="calculationMainItem">
  //         {i ? (
  //           <div className="calculationOperation">
  //             <CustomSelect
  //               className="calculationOperatorItem"
  //               onChange={(option) =>
  //                 updateCalculation({ operator: option.value })
  //               }
  //               value={calculation?.operator || ""}
  //               options={conditionObj.operatorList.filter((x) =>
  //                 ["number", "string", "null"].includes(x.outputType)
  //               )}
  //               placeholder={"Select Operation"}
  //               classNames={{
  //                 head: "calculationOperatorItem",
  //                 label: "calculationOperatorItemLabel",
  //                 chevron: "optionDatabaseSelectChevron",
  //               }}
  //             />

  //             <div
  //               className="calculatorOperatorClose"
  //               onClick={removeCalculation}
  //             >
  //               <div className="calculatorOperatorCloseLine one"></div>
  //               <div className="calculatorOperatorCloseLine two"></div>
  //             </div>
  //           </div>
  //         ) : null}

  //         {!i || calculation?.operator ? (
  //           calculation?.valueType && !showCategories ? (
  //             <div
  //               className={
  //                 "calculationTypeBox " + categoryMap[category].className
  //               }
  //             >
  //               <div className="calculationTypeBoxRadio">
  //                 <div></div>
  //               </div>
  //               <div className="calculationTypeBoxMain">
  //                 <div
  //                   className="calculationTypeBoxSelect"
  //                   onClick={() => setShowCategories(true)}
  //                 >
  //                   <div className="calculationTypeBoxLabel">
  //                     {_.startCase(category)}
  //                   </div>
  //                   <img
  //                     className="optionDatabaseSelectChevron"
  //                     src={require("../../../Assets/img/options/container/chevronDown.png")}
  //                     alt="chevron"
  //                   ></img>
  //                 </div>

  //                 <div className="calculationTypeBoxSeperator"></div>

  //                 <CustomSelect
  //                   value={calculation?.valueType || ""}
  //                   onChange={(option) =>
  //                     updateCalculation({ valueType: option.value })
  //                   }
  //                   options={valueTypes.filter((x) => x.category === category)}
  //                   placeholder={categoryMap[category]?.placeholder}
  //                   classNames={{
  //                     head: "calculationTypeBoxSelector",
  //                     label: "calculationTypeBoxSelectorLabel",
  //                     chevron: "optionDatabaseSelectChevron",
  //                   }}
  //                 />
  //               </div>
  //             </div>
  //           ) : (
  //             <div className="calculationList">
  //               {Object.keys(categoryMap).map((category) => (
  //                 <div
  //                   key={category}
  //                   className={
  //                     "calculationTypeBox " + categoryMap[category].className
  //                   }
  //                 >
  //                   <div className="calculationTypeBoxRadio">
  //                     <div></div>
  //                   </div>
  //                   <div className="calculationTypeBoxMain">
  //                     <div className="calculationTypeBoxSelect">
  //                       <div className="calculationTypeBoxLabel">
  //                         {_.startCase(category)}
  //                       </div>
  //                     </div>

  //                     <div className="calculationTypeBoxSeperator"></div>

  //                     <CustomSelect
  //                       value={calculation?.valueType || ""}
  //                       onChange={(option) => {
  //                         updateCalculation({ valueType: option.value });
  //                         setShowCategories(false);
  //                       }}
  //                       options={valueTypes.filter(
  //                         (x) => x.category === category
  //                       )}
  //                       placeholder={categoryMap[category]?.placeholder}
  //                       classNames={{
  //                         head: "calculationTypeBoxSelector",
  //                         label: "calculationTypeBoxSelectorLabel",
  //                         chevron: "optionDatabaseSelectChevron",
  //                       }}
  //                     />
  //                   </div>
  //                 </div>
  //               ))}
  //             </div>
  //           )
  //         ) : null}

  //         {calculation?.valueType ? (
  //           <>
  //             <div className="calculationConnector">
  //               <div className="calculationConnectorItem"></div>
  //             </div>

  //             <ValueSelector
  //               {...props}
  //               valueType={calculation.valueType}
  //               value={calculation.valueObj}
  //               onChange={(obj) => updateCalculation({ valueObj: obj })}
  //               calculationInputs={() => calculationInputs}
  //             />
  //           </>
  //         ) : null}
  //       </div>
  //     </div>
  //   </div>
  // );
};

export class CalculationTrigger extends React.Component {
  state = {
    visible: !!this.props.visible,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.onHide && prevState.visible && !this.state.visible)
      this.props.onHide();
  }

  render() {
    return (
      <>
        <div
          onClick={() => {
            this.setState({ visible: true });
          }}
          style={{
            padding: "10px",
            background: "blue",
            color: "white",
            cursor: "pointer",
            ...(this.props.style || {}),
          }}
        >
          Calculation
        </div>
        {this.state.visible ? (
          <CalculationBox
            {...{
              ...this.props,
              setVisible: (x) => this.setState({ visible: x }),
            }}
          />
        ) : null}
      </>
    );
  }
}

export class CalculationModal extends React.Component {
  render() {
    const { value: calculations } = this.props;

    return <></>;
  }
}

export default CalculationBox;
