import React from "react";
import update from "immutability-helper";
import MarkLocation from "./MarkLocation";
import MapCenter from "./MapCenter";
import MapIcon from "./MapIcon";
import DatabaseDataSelector from "../../../../Common/DatabaseOperations/DatabaseDataSelector";
import ColorButton from "../../../../../Components/Input/ColorButton";
import { RadioBoxHorizontal } from "../../../../../Components/etc/RadioBox";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import ImageData from "../image/ImageData";
import FixedMark from "./FixedMark";

const CreateRoute = (props) => {
  const { value, onChange } = props;
  const operation = value || {};
  const setOperationObj = (obj) => {
    onChange(update(operation, { $merge: obj }));
  };

  return (
    <>
      {operation.typeOfData === "repeating" ? (
        <RepeatingRoute {...props} value={value} onChange={onChange} />
      ) : (
        <FixedRoute {...props} value={value} onChange={onChange} />
      )}
    </>
  );
};

const RepeatingRoute = (props) => {
  const { value, onChange } = props;

  const operationObj = value || {};
  const setOperationObj = (obj) => {
    onChange(update(operationObj, { $merge: obj }));
  };
  const setTableObj = (obj) => {
    setOperationObj({
      tableObj: update(operationObj.tableObj || {}, { $merge: obj }),
    });
  };

  return (
    <>
      <DatabaseDataSelector
        {...{
          ...props,
          value: operationObj.tableObj,
          onChange: (x) => setTableObj(x),
          valueType: "tableSelector-valueOfColumn",
          valueTypeData: {
            tableSelector: {
              // additionalInput: true,
              minNoOfInputs: 0,
              maxNoOfInputs: 0,
            },
            label: "Address",
          },
        }}
      />
      <div className="optionMapDatabaseWrapper">
        <div className="optionMapDatabase">
          <div className="optionItemBody">
            <div className="optionItemRow filterItemUnit">
              <div className="optionDatabaseBoxWrapper">
                <div className="optionDatabaseBox">
                  <div className="optionDatabaseFilters">
                    <div className="addressSelector mt-10">
                      <div className="optionDatabaseBoxLabel">Address</div>
                      <ColumnSelector
                        {...{
                          ...props,
                          value: operationObj?.tableObj?.columns?.[0],
                          tableObj: operationObj?.tableObj,
                          onChange: (x) =>
                            setOperationObj({
                              tableObj: {
                                ...(operationObj?.tableObj || {}),
                                columns: update(
                                  operationObj?.tableObj?.columns || [],
                                  { $merge: { [0]: x } }
                                ),
                              },
                            }),
                        }}
                      />
                    </div>

                    <div className="addressSelector">
                      <div className="optionDatabaseBoxLabel">Sort By</div>
                      <ColumnSelector
                        {...{
                          ...props,
                          value: operationObj.sortBy,
                          onChange: (x) => setOperationObj({ sortBy: x }),
                          tableObj: operationObj?.tableObj,
                        }}
                      />
                      <RadioBoxHorizontal
                        value={operationObj.order}
                        onChange={(value) => setOperationObj({ order: value })}
                        options={[
                          { value: "asc", labelx: "Ascending" },
                          { value: "dsc", labelx: "Descending" },
                        ]}
                      />
                    </div>
                    <div className="addressSelector">
                      <div className="optionDatabaseBoxLabel">Map Line</div>
                      <div className="optionItemBox">
                        <div className="optionColorItem">
                          <ColorButton
                            className="optionColorCircle"
                            style={{ width: "100%", height: "100%" }}
                            value={operationObj.polylineStrokeColor}
                            onChange={(val) =>
                              setOperationObj({ polylineStrokeColor: val })
                            }
                          />
                          <div className="optionInputIconBoxText">
                            Map Line Color
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="addressSelector mapIconSection">
                      <div className="optionDatabaseBoxLabelMultiUnit">
                        <div className="optionDatabaseBoxLabel">Map Icon</div>
                        <ToggleButton
                          classNames={{
                            container: "optionHeadTabs headTabsDark",
                            item: "optionHeadTabItem",
                          }}
                          overideDefaultStyle
                          value={operationObj.icon?.valueType || "database"}
                          onChange={(x) =>
                            setOperationObj({
                              icon: {
                                ...(operationObj.icon || {}),
                                valueType: x,
                              },
                            })
                          }
                          options={[
                            { value: "database", label: "Database" },
                            { value: "textParts", label: "Custom" },
                          ]}
                        />
                      </div>
                      <div className="optionItemRow optionItemIconDimension">
                        <div className="optionItemBox">
                          <div className="optionInputIconBox">
                            <img
                              className="optionInputIconBoxArrowIco"
                              src={require("../../../../../Assets/img/options/container/widthIco.png")}
                            ></img>
                            <input
                              type="text"
                              className="optionInputIconBoxSpacingField"
                              placeholder="25px"
                              value={operationObj.icon?.width || ""}
                              onChange={(e) =>
                                setOperationObj({
                                  icon: {
                                    ...(operationObj.icon || {}),
                                    width: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="optionItemBox">
                          <div className="optionInputIconBox">
                            <img
                              className="optionInputIconBoxArrowIco rotate90"
                              src={require("../../../../../Assets/img/options/container/widthIco.png")}
                            ></img>
                            <input
                              type="text"
                              className="optionInputIconBoxSpacingField"
                              placeholder="25px"
                              value={operationObj.icon?.height || ""}
                              onChange={(e) =>
                                setOperationObj({
                                  icon: {
                                    ...(operationObj.icon || {}),
                                    height: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <IconData
                        {...props}
                        operationObj={operationObj}
                        setOperationObj={setOperationObj}
                        value={operationObj.icon}
                        onChange={(x) => {
                          setOperationObj({
                            icon: {
                              ...operationObj.icon,
                              ...x,
                            },
                          });
                        }}
                      />
                    </div>

                    <div className="addressSelector">
                      <div className="optionDatabaseTextBox mt10">
                        <div className="optionDatabaseTextBoxLabel">
                          Map Center
                        </div>
                        <div className="optionDatabaseTextBoxBody">
                          <MapCenter
                            {...props}
                            value={operationObj.mapCenter}
                            onChange={(value) =>
                              setOperationObj({ mapCenter: value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const FixedRoute = (props) => {
  const { value, onChange } = props;

  const operationObj = value || {};
  const setOperationObj = (obj) => {
    console.log({ obj });
    onChange(update(operationObj, { $merge: obj }));
  };

  const locationMarks = [
    (operationObj.locationMarks && operationObj.locationMarks[0]) || {},
    (operationObj.locationMarks && operationObj.locationMarks[1]) || {},
  ];

  const setLocationMarks = (obj) =>
    setOperationObj({
      locationMarks: update(locationMarks, { $merge: obj }),
    });

  const setMark = (obj) =>
    setLocationMarks(update(locationMarks, { $merge: obj }));

  return (
    <div className="optionMapDatabaseWrapper">
      {locationMarks.map((location, markerNo) => (
        <div className="optionMapCombo">
          <div className="optionMapLeft">
            <div className="optionMapNumberWrapper">
              <div className="optionMapNumber">{markerNo + 1}</div>
            </div>

            <div className="optionMapLeftDecor"></div>
          </div>
          <FixedMark
            {...props}
            key={markerNo}
            value={location || {}}
            onChange={(value) => setMark({ [markerNo]: value })}
            markerNo={markerNo}
            remove={() =>
              setLocationMarks(
                update(locationMarks, { $splice: [[markerNo, 1]] })
              )
            }
          />
        </div>
      ))}

      <div className="mapLinkingWrapper">
        <div className="optionDatabaseTextBox mt10">
          <div className="optionDatabaseTextBoxLabel">Map Center</div>
          <MapCenter
            {...props}
            value={operationObj.mapCenter}
            onChange={(value) => setOperationObj({ mapCenter: value })}
          />
        </div>
      </div>

      <div style={styleObj.lineColor}>
        <div style={styleObj.title}>Map Line coloar</div>
        <ColorButton
          value={operationObj.polylineStrokeColor}
          onChange={(val) => setOperationObj({ polylineStrokeColor: val })}
          transform="translate(-7px, -271px)"
        />
      </div>
    </div>
  );
};

const ColumnSelector = (props) => {
  const { value, onChange, tableObj = {} } = props;

  const database = props.databases?.find((x) => x._id === tableObj.dbId);
  const table = database?.tables?.find((x) => x._id === tableObj.tableId);
  const databaseColumns = table?.columns || [];

  return (
    <CustomSelect
      value={value || ""}
      onChange={(option) => onChange(option.value)}
      options={databaseColumns.map((x) => ({
        value: x.name,
        label: x.name,
      }))}
      placeholder={"Select Column"}
      classNames={{
        head: "optionDatabaseValueSelectorSelect",
        label: "optionDatabaseValueSelectorSelectValue",
        chevron: "optionDatabaseValueSelectorSelectIco",
      }}
    />
  );
};

class IconData extends ImageData {
  databaseColumnConfig() {
    return (
      <ColumnSelector
        {...{
          ...this.props,
          value: this.props.operationObj?.tableObj?.columns?.[1],
          tableObj: this.props.operationObj?.tableObj,
          onChange: (x) =>
            this.props.setOperationObj({
              icon: {
                ...(this.props.operationObj?.icon || {}),
                valueType: "database",
              },
              tableObj: {
                ...(this.props.operationObj?.tableObj || {}),
                columns: update(
                  this.props.operationObj?.tableObj?.columns || [],
                  {
                    $merge: { [1]: x },
                  }
                ),
              },
            }),
        }}
      />
    );
  }

  render() {
    return this.props.value?.valueType === "textParts"
      ? this.textPartsConfig()
      : this.databaseColumnConfig();
  }
}

const styleObj = {
  add: {
    background: "gray",
    padding: "3px",
    textAlign: "center",
    fontSize: "small",
    cursor: "pointer",
    color: "white",
  },
  sort: {
    padding: "6px",
    backgroundColor: "#808080",
  },
  sortTitle: {
    color: "#ffc100",
    fontSize: "x-small",
  },
  select: {
    background: 0,
    border: 0,
    color: "white",
    width: "100%",
  },
  option: {
    color: "gray",
  },
  lineColor: {
    backgroundColor: "#b6b6b6",
    padding: "6px",
  },
  title: {
    fontSize: "x-small",
    color: "white",
    margin: "0 0 5px 0",
    fontWeight: "bold",
  },
  label: {
    color: "white",
    fontSize: "small",
  },
  hr: {
    margin: 0,
    borderColor: "#ddd",
  },
};

export default CreateRoute;
