import React from "react";
import _ from "lodash";

import ControlledInput from "../../../../../Components/Input/ControlledInput";
import ColorButton from "../../../../../Components/Input/ColorButton";
import OptionItem from "../../../../../Components/etc/OptionItem";
import CustomSelect from "../../../../../Components/etc/CustomSelect";

const Border = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  const propObj = {
    // border: {
    //   display: "All",
    //   width: "borderWidth",
    //   color: "borderColor",
    //   style: "borderStyle",
    // },
    borderTop: {
      display: "Top",
      width: "borderTopWidth",
      color: "borderTopColor",
      style: "borderTopStyle",
      iconClass: "",
    },
    borderBottom: {
      display: "Bottom",
      width: "borderBottomWidth",
      color: "borderBottomColor",
      style: "borderBottomStyle",
      iconClass: "rotate180",
    },
    borderLeft: {
      display: "Left",
      width: "borderLeftWidth",
      color: "borderLeftColor",
      style: "borderLeftStyle",
      iconClass: "rotate270",
    },
    borderRight: {
      display: "Right",
      width: "borderRightWidth",
      color: "borderRightColor",
      style: "borderRightStyle",
      iconClass: "rotate90",
    },
  };

  const borderStyles = ["solid", "dotted", "dashed"];

  let border = {};
  Object.keys(propObj).forEach((key) => {
    border[key] = {};
    border[key].width = styles[propObj[key].width];
    border[key].color = styles[propObj[key].color];
    border[key].style = styles[propObj[key].style];
  });

  const changeWidth = (value, key) => {
    border[key].width = value;

    let updateObj = {};
    updateObj[propObj[key].width] = value;
    mergeStyle(updateObj);
  };
  const changeStyle = (value, key) => {
    border[key].style = value;

    let updateObj = {};
    updateObj[propObj[key].style] = value;
    mergeStyle(updateObj);
  };
  const changeColor = (hex, key) => {
    border[key].color = hex;

    let updateObj = {};
    updateObj[propObj[key].color] = hex ? hex : "";
    mergeStyle(updateObj);
  };

  return (
    <OptionItem optionItemLabel="Boder">
      <div className="optionItemBody">
        {Object.keys(propObj).map((key) => {
          const item = propObj[key];

          return (
            <div className="optionItemRow" key={key}>
              <div className="optionItemBox fInitial">
                <ColorButton
                  className="borderColorPicker"
                  style={{ width: "100%", height: "100%" }}
                  value={border[key].color || "#000000"}
                  onChange={(val) => changeColor(val, key)}
                />
              </div>
              <div className="optionItemBox">
                <div className="optionInputIconBox">
                  <img
                    className={"optionInputIconBoxArrowIco " + item.iconClass}
                    src={require("../../../../../Assets/img/options/options/greyArrow.png")}
                    alt="arrow_icon"
                  ></img>
                  <ControlledInput
                    className="optionInputIconBoxSpacingField"
                    onChange={(event) => changeWidth(event.target.value, key)}
                    value={border[key].width || ""}
                    key={key}
                  />
                </div>
              </div>

              <div className="optionItemBox">
                <CustomSelect
                  onChange={(option) => changeStyle(option.value, key)}
                  value={border[key].style || ""}
                  options={borderStyles.map((x) => ({
                    value: x,
                    label: _.startCase(x),
                  }))}
                  placeholder={"Select"}
                  classNames={{
                    head: "optionInputIconBox",
                    label: "optionInputIconBoxField",
                    chevron: "optionDatabaseSelectChevron",
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </OptionItem>
  );
};

export default Border;
