import React from "react";
import _ from "lodash";

import { LinkingOptionItemBody } from "../../../Components/etc/OptionItem";
import CustomSelect from "../../../Components/etc/CustomSelect";
import ValueOfElementSelector from "../DataQueryOperations/ValueOfElementSelector";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import linkingObj from "../../../appxolo-engine/modules/linking/linking-obj";

const footerActions = [
  {
    valueTypes: ["joinWebrtcRoom", "leaveWebrtcRoom"],
    value: "roomType",
    label: "Join With",
    options: [
      { value: "video", label: "Video" },
      { value: "audio", label: "Audio" },
    ],
  },
  {
    valueTypes: ["camera"],
    value: "action",
    label: "Action",
    options: [
      { value: "flipCamera", label: "Flip Camera" },
      { value: "toggleCamera", label: "Toggle" },
    ],
  },
  {
    valueTypes: ["microphone"],
    value: "action",
    label: "Action",
    options: [{ value: "toggleMicrophone", label: "Toggle" }],
  },
];

const LinkToBroadcast = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const valueTypes = linkingObj.valueTypes.find(
    (x) => x.value === "broadcast"
  ).valueTypes;

  const footerAction = footerActions.find((x) =>
    x.valueTypes.includes(value.valueType)
  );

  return (
    <>
      <LinkingOptionItemBody {...props}>
        <div className="databaseOperationModule">
          <div className="domHeader">
            <div className="domLabel">Behavior</div>

            <CustomSelect
              value={value.valueType || ""}
              onChange={(option) => mergeChange({ valueType: option.value })}
              options={valueTypes}
              placeholder={"Select"}
              classNames={{
                head: "calcItemUnitMainDropdown spaceTopify",
                label: "calcItemUnitMainDropdownLabel",
              }}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooter">
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              }
            />
          </div>

          {["joinWebrtcRoom", "leaveWebrtcRoom"].includes(value.valueType) ? (
            <div className="domBody">
              <div className="calcItemDatabaseFilterBox">
                <div className="calcItemDatabaseFilterBoxInner">
                  <div className="calcItemDatabaseFilterBoxInnerRow">
                    <div className="calcItemDBLabel">Room ID</div>
                  </div>
                  <RichTextData
                    {...{
                      ...props,
                      immediateProps: {
                        classNames: {
                          wrapper: " ",
                          editor: "calcDatabaseFilterMultiBox ",
                          paramListRow: "",
                        },
                      },
                      placeholder: "Type here",
                      value: value?.elementValue?.valueObj,
                      onChange: (valueObj) => {
                        mergeChange({
                          elementValue: {
                            valueObj,
                            valueType: "textParts",
                          },
                        });
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </LinkingOptionItemBody>

      {linkBoxOptions.showTriggerOption || footerAction ? (
        <div className="linkingBoxFooter">
          {footerAction ? (
            <div className="optionItemBody">
              <div className="linkLabel">{footerAction.label}</div>
              <div className="optionItemRow">
                <CustomSelect
                  value={value[footerAction.value] || footerAction.options?.[0]?.value || ""}
                  onChange={(option) =>
                    mergeChange({ [footerAction.value]: option.value })
                  }
                  options={footerAction.options}
                  placeholder={"Select"}
                  className="linkingToBox flexColumn flex1 br10 pad6"
                  classNames={{
                    head: "pad6 flexRow aCenter",
                    label: "cDark flex1 cPurple",
                    chevron: "optionDatabaseSortKeyIco",
                  }}
                />
              </div>
            </div>
          ) : null}

          {linkBoxOptions.showTriggerOption ? triggerOption : null}
        </div>
      ) : null}
    </>
  );
};

export default LinkToBroadcast;
