import React from "react";
import update from "immutability-helper";
import _ from "lodash";

import AppModal from "../../../Components/Models/app-modal";
import api from "../../../Services/Api/api";
import DataCondition from "../DataQueryOperations/DataCondition";
import { LinkingBoxes } from "../Linking/LinkingBox";

class Trigger extends React.Component {
  initialState = {
    loading: false,
    visible: null,
    error: null,
    trigger: {
      project: this.props.project?._id,
      triggerOn: "create",
      triggerLocation: "remote",
      data: {
        tabs: [{ uid: Math.random() }],
      },
      ...this.props.trigger,
    },
  };

  state = { ...this.initialState };

  async onSubmit() {
    try {
      if (this.state.loading) return;
      this.setState({ loading: true, error: null });

      const payload = {
        _id: this.state.trigger._id,
        project: this.state.trigger.project,
        triggerOn: this.state.trigger.triggerOn,
        triggerLocation: this.state.trigger.triggerLocation,
        triggerInterval: this.state.trigger.triggerInterval,
        triggerIntervalUnit: this.state.trigger.triggerIntervalUnit,
        data: this.state.trigger.data,
      };

      await api.post("v1/project/trigger", payload);

      this.setState({ loading: false, visible: false });
      this.props.onSuccess();
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }

  mergeTrigger = (obj) => {
    this.setState({ trigger: { ...this.state.trigger, ...obj } });
  };

  mergeTriggerData = (obj) => {
    this.mergeTrigger({
      data: { ...(this.state.trigger.data || {}), ...obj },
    });
  };

  mergeLinkingTab = (obj, index = 0) => {
    const existing = this.state.trigger?.data?.linkingTabs?.[index] || {};
    const updatedLinkingTab = { ...existing, ...obj };
    this.mergeTriggerData({
      linkingTabs: update(this.state.trigger?.data?.linkingTabs || [], {
        $merge: { [index]: updatedLinkingTab },
      }),
    });
  };

  render() {
    const { trigger, error, loading } = this.state;

    const {
      triggerOn,
      triggerLocation,
      data,
      triggerInterval,
      triggerIntervalUnit,
    } = trigger;

    const databases = this.props.databases;
    const remoteDatabases = databases?.filter((x) => x.type === "remote");

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            Trigger On:{" "}
            <select
              value={triggerOn}
              onChange={(e) => {
                const triggerOn = e.target.value;
                this.mergeTrigger(
                  ["onCallAnswer"].includes(triggerOn)
                    ? { triggerOn, triggerLocation: "local" }
                    : { triggerOn }
                );
              }}
            >
              {["create", "update", "delete", "auto", "onCallAnswer"].map(
                (x) => (
                  <option key={x} value={x}>
                    {_.startCase(x)}
                  </option>
                )
              )}
            </select>
          </div>
          <div>
            Trigger Location:{" "}
            <select
              value={triggerLocation}
              onChange={(e) =>
                this.mergeTrigger({
                  triggerLocation: e.target.value,
                  triggerInterval:
                    triggerIntervalUnit === "cron" ? "1" : triggerInterval,
                  triggerIntervalUnit:
                    triggerIntervalUnit === "cron"
                      ? "min"
                      : triggerIntervalUnit,
                  data: {
                    tabs: [{ uid: Math.random() }],
                  },
                })
              }
            >
              <option value="">Select</option>
              {(["onCallAnswer"].includes(triggerOn)
                ? ["local"]
                : ["remote", "local"]
              ).map((x) => (
                <option key={x}>{x}</option>
              ))}
            </select>
          </div>
        </div>

        {triggerOn === "auto" ? (
          <div>
            Trigger Interval:{" "}
            <input
              type={triggerIntervalUnit === "cron" ? "text" : "number"}
              value={triggerInterval || ""}
              onChange={(e) =>
                this.mergeTrigger({ triggerInterval: e.target.value })
              }
            ></input>
            <select
              value={triggerIntervalUnit}
              onChange={(e) =>
                this.mergeTrigger({ triggerIntervalUnit: e.target.value })
              }
            >
              {[
                { value: "sec", label: "Second" },
                { value: "min", label: "Minute" },
                { value: "hour", label: "Hour" },
                { value: "day", label: "Day" },
                { value: "cron", label: "Cron String" },
              ]
                .filter((x) =>
                  triggerLocation === "local" ? x.value !== "cron" : true
                )
                .map((x) => (
                  <option key={x.value} value={x.value}>
                    {x.label}
                  </option>
                ))}
            </select>
          </div>
        ) : null}

        <DataCondition
          {...this.props}
          value={data}
          onChange={(x) => this.mergeTrigger({ data: x })}
          availableFor={triggerLocation === "remote" ? "backend" : null}
          databases={triggerLocation === "remote" ? remoteDatabases : databases}
        />

        <LinkingBoxes
          {...{
            ...this.props,
            value: data?.tabs?.[data?.activeTabIndex || 0]?.linkings,
            onChange: (linkings) => {
              let tabs = update(data?.tabs || [], {
                $merge: {
                  [data?.activeTabIndex || 0]:
                    data?.tabs?.[data?.activeTabIndex || 0] || {},
                },
              });
              this.mergeTrigger({
                data: {
                  ...(data || {}),
                  tabs: update(tabs, {
                    [data?.activeTabIndex || 0]: { $merge: { linkings } },
                  }),
                },
              });
            },
            availableFor: triggerLocation === "remote" ? "backend" : null,
            autoAddOneEmptyLinking: true,
            linkBoxOptions: {}
          }}
        />

        <div className="errormsg">{error}</div>

        <button onClick={() => this.onSubmit()} disabled={loading}>
          Save
        </button>
      </div>
    );
  }
}

class Triggers extends React.Component {
  state = {
    items: null,
    loading: false,
    error: null,
  };

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });
      const { triggers } = await api.get("v1/project/trigger", {
        filter: JSON.stringify({
          where: {
            project: this.props.project._id,
          },
        }),
      });
      this.setState({ loading: false, items: triggers });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: null });
    }
  }

  async deleteTrigger({ _id }, index) {
    try {
      if (_id) {
        this.setState({ loading: true, error: null });
        await api.delete("v1/project/trigger/" + _id);
        this.setState({ loading: false });

        index = this.state.items?.findIndex((x) => x._id === _id);
      }

      this.setState({
        items: update(this.state.items, { $splice: [[index, 1]] }),
      });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: e.message });
      window.alert(e.message);
    }
  }

  render() {
    const {
      state: { items, loading },
    } = this;
    return (
      <div>
        <div style={{ height: "17px", marginTop: "-15px" }}>
          {loading ? <center>Loading...</center> : null}
        </div>
        {items?.map((item, index) => (
          <div
            key={item._id || item.uid}
            style={{ margin: "2px", padding: "10px", border: "1px solid gray" }}
          >
            <div> {_.startCase(item.triggerType)}</div>

            <Trigger
              {...this.props}
              onSuccess={() => this.load()}
              trigger={item}
            />
            <button
              onClick={() => {
                window.confirm("Confirm Delete ?") &&
                  this.deleteTrigger(item, index);
              }}
            >
              Delete
            </button>
          </div>
        ))}

        <button
          onClick={() =>
            this.setState({
              items: [
                ...(this.state.items || []),
                {
                  uid: Date.now(),
                },
              ],
            })
          }
        >
          Add Trigger
        </button>
      </div>
    );
  }
}

export const TriggersModal = (props) => {
  return (
    <AppModal
      {...{
        title: "Triggers",
        visible: props.visible,
        handleClose: () => props.close(),
        showSubmitBtn: false,
      }}
    >
      {props.visible ? <Triggers {...props} /> : null}
    </AppModal>
  );
};

export class ShowTriggerBtn extends React.Component {
  state = { visible: false };

  render() {
    return (
      <>
        <button onClick={() => this.setState({ visible: true })}>
          Triggers
        </button>
        <TriggersModal
          {...{
            ...this.props,
            close: () => this.setState({ visible: false }),
            visible: this.state.visible,
          }}
        />
      </>
    );
  }
}
