import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";
import Margin from "./Margin";
import Padding from "./Padding";

const Spacing = (props) => {
  return (
    <OptionItem optionItemLabel="Spacing">
      <Margin {...props} />
      <Padding {...props} />
    </OptionItem>
  );
};

export default Spacing;
