import React from "react";

const Chevron = ({ className, ico }) => {
  return (
    <img
      className={className}
      src={ico || require("../../Assets/img/options/container/chevronDown.png")}
    ></img>
  );
};

export default Chevron;
