import React from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

const withRouter =
  (WrappedComponent, opt = {}) =>
  (props) => {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let searchParams = getAllSearchParams(useSearchParams()[0]);

    let propsToBePassed = {
      ...props,
      router: { location, navigate, params, searchParams },
    };
    if (opt?.keyExtractor) {
      propsToBePassed = {
        ...propsToBePassed,
        key: opt.keyExtractor(propsToBePassed),
      };
    }

    return <WrappedComponent {...propsToBePassed} />;
  };

const getAllSearchParams = (searchParams) => {
  let result = {};
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    result[param] = value;
  }
  return result;
};

export default withRouter;
