import api from "../Api/api";

var subcribedFuncs = {}; // {eventStr: {randomId: func}}

export const subscribeSocketEvent = (eventStr, func) => {
  if (typeof func != "function") return null;

  if (!subcribedFuncs[eventStr]) {
    subcribedFuncs[eventStr] = {};
  }

  let id = Date.now().toString() + Math.random();

  subcribedFuncs[eventStr][id] = func;

  return id;
};

export const unsubscribeSocketEvent = (eventStr, id) => {
  if (!id) return;

  if (!subcribedFuncs[eventStr]?.[id]) {
    return;
  } else {
    delete subcribedFuncs[eventStr][id];
  }

  return;
};

const dispatchToSubcribers = (eventStr, data) => {
  if (subcribedFuncs[eventStr]) {
    let funcs = Object.values(subcribedFuncs[eventStr]);

    for (let i = 0; i < funcs.length; i++) {
      const func = funcs[i];

      try {
        if (func && typeof func === "function") {
          func(data);
        }
      } catch (e) {
        console.info(e);
      }
    }
  }
};

const socketEvents = [{ eventName: "v1/component/reload", fn: () => {} }];

const socketListeners = async (socket, opt = {}) => {
  for (let i = 0; i < socketEvents.length; i++) {
    const socketEvent = socketEvents[i];

    socket.on(socketEvent.eventName, async (data) => {
      if (socketEvent.fn) socketEvent.fn(data);
      dispatchToSubcribers(socketEvent.eventName, data);
    });
  }
};

var joinedRoomCounts = {};
export const joinSocketRoom = (room) => {
  joinedRoomCounts[room] = (joinedRoomCounts[room] || 0) + 1;

  return api.socket("v1/socket/join", { room });
};
export const leavSocketRoom = (room, opt = {}) => {
  if (opt.all && joinedRoomCounts[room]) {
    delete joinedRoomCounts[room];
  } else if (joinedRoomCounts[room]) {
    joinedRoomCounts[room] = joinedRoomCounts[room] - 1;
  }

  if (joinedRoomCounts[room] <= 0) {
    delete joinedRoomCounts[room];
  }

  if (!joinedRoomCounts[room]) {
    return api.socket("v1/socket/leave", { room });
  }
};

export const socketService = {
  socketApi: api?.socket,
  subscribeSocketEvent,
  unsubscribeSocketEvent,
  joinSocketRoom,
  leavSocketRoom,
};

export default socketListeners;
