import React from "react";
import ExternalApiDataSelector from "../ExternalApiOperations/ExternalApiDataSelector";
import SuccessFailHandler from "./SuccessFailHandler";

const LinkToApi = (props) => {
  return (
    <>
      <ExternalApiDataSelector {...{ ...props, mode: "linkToApi" }} />;
      <SuccessFailHandler {...props} />
    </>
  );
};

export default LinkToApi;
