import React from "react";
import update from "immutability-helper";

import ConditionTabs from "./ConditionTabs";
import ConditionsBox from "./ConditionsBox";

const DataCondition = (props) => {
  const activeTab = props.value?.tabs?.[props.value?.activeTabIndex || 0] || {};

  const setActiveTab = (tab) => {
    props.onChange({
      ...(props.value || {}),
      tabs: update(props.value?.tabs || [], {
        $merge: { [props.value?.activeTabIndex || 0]: tab },
      }),
    });
  };

  const conditions = activeTab.conditions;
  const setConditions = (conditions) => {
    setActiveTab({ ...activeTab, conditions });
  };

  return (
    <>
      <ConditionTabs {...props} />
      <ConditionsBox
        {...{
          ...props,
          value: conditions,
          onChange: setConditions,
        }}
      />
    </>
  );
};

export default DataCondition;
