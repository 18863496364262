import React, { useState } from "react";
import _ from "lodash";
import update from "immutability-helper";

import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";

const CustomUrlParameters = (props) => {
  const { value, onChange } = props;

  const addParam = (item = { uid: Math.random() }) => {
    onChange([...(value || []), item]);
  };

  return (
    <div className="domBody">
      <div className="calcItemDatabaseFilterLabel">Parameters</div>

      {value?.map((item, index) => (
        <ParameterGroup
          {...{
            ...props,
            key: item.uid,
            value: item,
            label: `Parameter ${index + 1}`,
            onChange: (x) => {
              onChange(
                update(value, {
                  $merge: {
                    [index]: x,
                  },
                })
              );
            },
            remove: () => onChange(update(value, { $splice: [[index, 1]] })),
          }}
        />
      ))}

      <div className="pad6">
        <div className="addFilterButton dark" onClick={() => addParam()}>
          Add Parameter
        </div>
      </div>
    </div>
  );
};

const ParameterGroup = (props) => {
  const { value = {}, label, onChange, remove } = props;

  return (
    <div className="calcItemDatabaseFilterBox">
      <div className="calcItemDatabaseFilterBoxInner">
        <div className="calcItemDatabaseFilterBoxInnerRow">
          <div className="calcItemUnitMainDropdown">
            <input
              className="calcItemUnitMainDropdownLabel"
              placeholder="Parameter Name"
              {...{
                value: value?.parameterName?.valueObj?.customText || "",
                onChange: (e) => {
                  onChange({
                    ...value,
                    parameterName: {
                      ...(value?.parameterName || {}),
                      valueObj: { customText: e.target.value },
                      valueType: "customText",
                    },
                  });
                },
              }}
            />
          </div>
        </div>

        <div className="calcItemDatabaseFilterBoxInnerRow">
          <RichTextData
            {...{
              ...props,
              immediateProps: {
                classNames: {
                  wrapper: " ",
                  editor: "calcDatabaseFilterMultiBox",
                  paramListRow: "",
                },
              },
              placeholder: "Parameter Value",
              value: value?.parameterValue?.valueObj,
              onChange: (valueObj) => {
                onChange({
                  ...value,
                  parameterValue: {
                    ...(value?.parameterValue || {}),
                    valueObj,
                    valueType: "textParts",
                  },
                });
              },
            }}
          />
        </div>
        <div className="removeFilterButton" onClick={remove}>
          Remove
        </div>
      </div>
    </div>
  );
};

export default CustomUrlParameters;
