import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import api from "../../Services/Api/api";

const ChangeLog = (props) => {
  const [changeLogs, setChangeLogs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [backingUp, setBackingUp] = useState(false);
  const [restoring, setRestoring] = useState(false);

  const componentId = props.component?._id;
  const projectId = props.project?._id;

  const load = useCallback(async () => {
    try {
      if (loading) return;
      setLoading(true);
      const { changeLogs } = await api.get("v1/project/changelog", {
        filter: JSON.stringify({
          where: {
            component: componentId,
            project: projectId,
            type: "component",
          },
          populate: "createdBy",
          order: -1,
          sortby: "createdAt",
        }),
      });

      setChangeLogs(changeLogs);
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  }, [componentId, projectId]);

  useEffect(() => {
    load();
  }, [load]);

  const backup = useCallback(async () => {
    try {
      if (backingUp) return;
      setBackingUp(true);
      await api.post("v1/project/changelog", {
        project: projectId,
        component: componentId,
        type: "component",
      });

      await load();
      setBackingUp(false);
    } catch (e) {
      toast.error(e.message);
      setBackingUp(false);
    }
  }, [componentId, projectId]);

  const restore = async (changelogId) => {
    try {
      if (backingUp || restoring) return;
      setRestoring(changelogId);

      const lastBackupTime = changeLogs?.[0]?.createdAt;
      const lastBackupTimeMs = lastBackupTime
        ? new Date(lastBackupTime).getTime()
        : 0;

      if (Date.now() - lastBackupTimeMs > 1000 * 60 * 60) {
        await backup();
      }

      await api.patch("v1/project/changelog", {
        project: projectId,
        component: componentId,
        changeLog: changelogId,
      });

      props.reload();
      setRestoring(false);
      toast.success(`Screen is restored successfully`);
    } catch (e) {
      setRestoring(false);
      toast.error(e.message);
    }
  };

  return (
    <div>
      <div>Changes Log</div>

      <button onClick={backup}>{backingUp ? "Loading" : "Backup"}</button>

      <div>
        {changeLogs?.map((changeLog) => (
          <div key={changeLog._id}>
            {new Date(changeLog.createdAt).toLocaleString()}{" "}
            {changeLog.createdBy?.firstName}{" "}
            <button
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure, you want to restore to time " +
                      new Date(changeLog.createdAt).toLocaleString()
                  )
                )
                  restore(changeLog._id);
              }}
            >
              Restore
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChangeLog;
