/* eslint-disable no-unused-expressions */
import React from "react";
import { Button, Form } from "react-bootstrap";
import AppModal from "../../../Components/Models/app-modal";
import api from "../../../Services/Api/api";
import _ from "lodash";

export class Credentials extends React.Component {
  state = {
    items: null,
    loading: false,
    error: null,
  };

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });
      const { credentials } = await api.get("v1/project/credential", {
        filter: JSON.stringify(this.props.filter || {}),
      });
      this.setState({ loading: false, items: credentials });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: null });
    }
  }

  async deleteCredential({ _id }) {
    try {
      this.setState({ loading: true, error: null });
      await api.delete("v1/project/credential/" + _id);
      this.setState({ loading: false });
      this.load();
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: null });
      window.alert(e.message);
    }
  }

  render() {
    const {
      state: { items },
    } = this;
    return (
      <div>
        {items?.map((item) => (
          <div
            key={item._id}
            style={{ margin: "2px", padding: "10px", border: "1px solid gray" }}
          >
            <div> {_.startCase(item.credentialType)}</div>
            <div
              style={{
                overflow: "auto",
                whiteSpace: "pre",
                maxHeight: "200px",
              }}
            >
              {JSON.stringify(item.credential, null, 4)}
            </div>
            <button
              onClick={() =>
                window.confirm("Confirm Delete ?") &&
                this.deleteCredential(item)
              }
            >
              Delete
            </button>
          </div>
        ))}

        <AddCredential {...this.props} onSuccess={() => this.load()} />
      </div>
    );
  }
}

export class AddCredential extends React.Component {
  initialState = {
    loading: false,
    visible: null,
    error: null,
    credential: {
      project: this.props.filter?.where?.project,
      credentialType: "firebaseAdmin",
    },
    parseError: null,
  };

  state = { ...this.initialState };

  async onSubmit() {
    try {
      if (this.state.loading) return;
      this.setState({ loading: true, error: null });

      const payload = {
        ...this.state.credential,
        credential: this.parseJSON(this.state.credential.credential)
      }

      const res = await api.post(
        "v1/project/credential",
            payload
      );

      console.log(res);

      this.setState({ loading: false, visible: false });
      this.props.onSuccess();
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }

  parseJSON = (str, opt) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      if (opt?.catch) {
        opt?.catch(e);
        return null;
      } else throw e;
    }
  };

  render() {
    const { credential, error, loading, parseError } = this.state;

    const content = (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.onSubmit();
        }}
      >
        <Form.Group className="mb-3" controlId="formCredentialType">
          <Form.Label>Credential Type</Form.Label>
          <Form.Control
            as="select"
            value={credential?.credentialType || ""}
            placeholder="Select"
            onChange={(e) =>
              this.setState({
                credential: { ...credential, credentialType: e.target.value },
              })
            }
          >
            <option value="" disabled>
              Select
            </option>
            {[
              "firebaseAdmin",
              "twilioSMS",
              'sendgridEmail'
            ].map((x) => (
              <option key={x} value={x}>
                {_.startCase(x)}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>JSON Data</Form.Label>
          <Form.Control
            placeholder="Copy the json file data here"
            as="textarea"
            value={credential?.credential || ""}
            onChange={(e) =>
              this.setState(
                {
                  credential: {
                    ...credential,
                    credential: e.target.value,
                  },
                  parseError: null
                },
                () => {
                  this.parseJSON(this.state.credential.credential, {
                    catch: (e) => this.setState({ parseError: e.message }),
                  });
                }
              )
            }
            required
          />
        </Form.Group>

        <div className="errormsg">{error || parseError}</div>

        <Button variant="primary" type="submit" disabled={loading}>
          Submit
        </Button>
      </Form>
    );

    return (
      <>
        <Button onClick={() => this.setState({ visible: true })}>
          Add New
        </Button>
        <AppModal
          {...{
            title: "Add 3rd party access",
            visible: this.state.visible,
            handleClose: () => this.setState(this.initialState),
            showSubmitBtn: false,
          }}
        >
          {content}
        </AppModal>
      </>
    );
  }
}

export class CredentialsModal extends React.Component {
  render() {
    return (
      <AppModal
        {...{
          title: "Credentials",
          visible: this.props.visible,
          handleClose: () => this.props.close(),
          showSubmitBtn: false,
        }}
      >
        {this.props.visible ? <Credentials {...this.props} /> : null}
      </AppModal>
    );
  }
}

export class ShowCredentialBtn extends React.Component {
  state = { visible: false };

  render() {
    return (
      <>
        <button onClick={() => this.setState({ visible: true })}>
          3rd Party Credentials
        </button>
        <CredentialsModal
          {...{
            ...this.props,
            close: () => this.setState({ visible: false }),
            visible: this.state.visible,
          }}
        />
      </>
    );
  }
}
