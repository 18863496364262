import React, { useState, useEffect, useRef, useCallback } from "react";
import Chevron from "./Chevron";

const CustomSelect = ({
  options = [],
  value,
  onChange,
  className,
  placeholder,
  classNames = {},
  jointActionRow,
  labelIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const findOption = useCallback((valueToFind, optionsToSearch) => {
    for (const option of optionsToSearch) {
      if (option.value === valueToFind) {
        return option;
      }

      if (option.options) {
        const foundNestedOption = findOption(valueToFind, option.options);
        if (foundNestedOption) {
          return foundNestedOption;
        }
      }
    }

    return null;
  }, []);

  useEffect(() => {
    if (value) {
      const foundOption = findOption(value, options);
      setSelectedOption(foundOption || null);
    } else {
      setSelectedOption(null);
    }
  }, [value, options, findOption]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const renderOptions = (optionsToRender) => {
    return optionsToRender.map((option) => {
      if (option.options) {
        return (
          <div className="optgroup" key={option.label}>
            <div className="optgroup-label">{option.label}</div>
            <div className="optgroup-options" style={{ marginLeft: "20px" }}>
              {renderOptions(option.options)}
            </div>
          </div>
        );
      } else {
        const active = selectedOption && selectedOption.value === option.value;
        return (
          <div
            key={option.value}
            className={`option ${active ? "active" : ""}`}
            onClick={() => handleOptionClick(option)}
          >
            {option.label}
          </div>
        );
      }
    });
  };

  return (
    <div className={`custom-select ${className}`} ref={dropdownRef} style={{position: 'relative'}}>
      <div
        className={`custom-select-head ${classNames.head}`}
        onClick={toggleDropdown}
      >
        {labelIcon ? labelIcon : null}
        <div className={`selected-option ${classNames.label}`}>
          {selectedOption
            ? selectedOption.label
            : placeholder || "Select an option"}
        </div>
        {jointActionRow ? (
          jointActionRow
        ) : (
          <Chevron className={classNames.chevron} />
        )}
      </div>
      {isOpen && (
        <div
          className="options-list"
          style={{ background: "white", position: "" }}
        >
          {renderOptions(options)}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
