import React from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import conditionObj from "../../../appxolo-engine/modules/calculation/condition-obj";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import CustomSelect from "../../../Components/etc/CustomSelect";
import Chevron from "../../../Components/etc/Chevron";

class DatabaseQueryFilter extends React.Component {
  render() {
    const database = this.props.databases?.find(
      (x) => x._id === this.props.dbId
    );
    const table = database?.tables?.find((x) => x._id === this.props.tableId);
    const columns = table?.columns || [];

    return (
      <div className="optionConditionTableSelectorBody">
        <div className="optionConditionTableSelectorHeadSublineFilter">
          Filter:
        </div>
        <Filter {...{ ...this.props, columns }} />
      </div>
    );
  }
}

class Filter extends React.Component {
  componentDidUpdate() {
    const filters = this.props.value?.filters || [];
    if (!filters.length) this.appendFilter();
  }

  updateFilters(filters) {
    this.props.onChange({ ...(this.props.value || {}), filters: filters });
  }

  setNthFilter(filter, n) {
    this.updateFilters(
      update(this.props.value?.filters || [], { $merge: { [n]: filter } })
    );
  }

  appendFilter(filter) {
    filter = filter || { condition: "eq" };
    filter = { ...filter, uid: filter.uid || Math.random() };

    this.updateFilters(
      update(this.props.value?.filters || [], {
        $push: [filter],
      })
    );
  }

  removeFilter(i) {
    this.updateFilters(
      update(this.props.value?.filters || [], { $splice: [[i, 1]] })
    );
  }

  renderFilter({ item: filter, index: i }) {
    const { columns } = this.props;

    return (
      <div
        key={filter.uid || i}
        className="optionConditionTableSelectorFilterItem"
      >
        <div
          className="optionConditionTableSelectorFilterRemove"
          onClick={() => this.removeFilter(i)}
        >
          <div className="optionConditionTableSelectorFilterLine one"></div>
          <div className="optionConditionTableSelectorFilterLine two"></div>
        </div>

        <div className="optionConditionTableSelectorFilterItemContext">
          <CustomSelect
            classNames={{
              head: "optionConditionTableSelectorFilterItemHead",
              label: "optionConditionTableParam",
              chevron: "optionConditionTableFilterMetaRequirementIco",
            }}
            onChange={(option) =>
              this.setNthFilter({ ...filter, name: option.value }, i)
            }
            value={filter.name || ""}
            placeholder="Select Column"
            options={columns?.map((x) => ({ value: x.name, label: x.name }))}
            jointActionRow={
              <div className="jointActionRow">
                <div
                  className="jointActionClose"
                  onClick={() => this.removeFilter(i)}
                >
                  <div className="jointActionCloseItem one"></div>
                  <div className="jointActionCloseItem two"></div>
                </div>
                <Chevron className="optionConditionTableFilterMetaRequirementIco" />
              </div>
            }
          />

          <div className="optionConditionTableFilterMeta">
            <div className="optionConditionTableFilterMetaParams">
              <CustomSelect
                classNames={{
                  head: "optionConditionTableFilterMetaRequirement",
                  label: "optionConditionTableFilterMetaRequirementLabel",
                  chevron: "optionConditionTableFilterMetaRequirementIco",
                }}
                onChange={(option) =>
                  this.setNthFilter({ ...filter, condition: option.value }, i)
                }
                value={filter.condition}
                options={
                  this.props.customFilterConditions ||
                  conditionObj.filterConditions
                }
                placeholder={""}
              />
            </div>
            <RichTextData
              {...{
                ...this.props,
                value: filter.valueObj,
                onChange: (valueObj) =>
                  this.setNthFilter(
                    { ...filter, valueType: "textParts", valueObj },
                    i
                  ),
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const filters = this.props.value?.filters || [];

    return (
      <div className="optionConditionTableSelectorBodyList">
        {filters?.map((filter, i) => {
          if (filter?.type === "filterGroup") {
            return (
              <div style={styleObj.orContainer} key={filter._id || i}>
                <div style={styleObj.title}>
                  <select
                    style={styleObj.textBox}
                    value={filter.name || ""}
                    onChange={(e) =>
                      this.setNthFilter({ ...filter, name: e.target.value }, i)
                    }
                  >
                    <option value={""} disabled>
                      select
                    </option>
                    <option value={"[OR]"}>OR</option>
                    <option value={"[AND]"}>AND</option>
                  </select>
                </div>
                <div key={i} style={styleObj.filterBox2}>
                  <div
                    className="cssCircle minusSign"
                    style={styleObj.roundMinus}
                    onClick={() => this.removeFilter(i)}
                  >
                    &#8211;
                  </div>
                  <Filter
                    {...{
                      ...this.props,
                      value: filter,
                      onChange: (filter) => this.setNthFilter(filter, i),
                    }}
                  />
                </div>
              </div>
            );
          } else return this.renderFilter({ item: filter, index: i });
        })}

        <div className="optionConditionTableSelectorAddFilter">
          <div
            className="optionConditionTableSelectorAddFilterLabel"
            onClick={() => {
              this.appendFilter();
            }}
          >
            Add Filter
          </div>
          {this.props.disbaleFilterGroup ? null : (
            <div
              className="optionConditionTableSelectorAddFilterLabel addFilterGroup"
              onClick={() => {
                this.appendFilter({ name: "[OR]", type: "filterGroup" });
              }}
            >
              Add Filter Group
            </div>
          )}

          {this.props.setFilterConditions ? (
            <div
              className="optionConditionTableSelectorAddFilterLabel"
              onClick={() => {
                // this.appendEmptyCondition();
              }}
            >
              Add Condition
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const styleObj = {
  container: {
    background: "#a0a0a0",
    padding: "5px 9px",
  },
  orContainer: {
    background: "#6d6c6c",
    padding: "5px 0px",
    margin: "5px 0",
  },
  title: {
    fontSize: "9px",
    fontWeight: "bold",
    padding: "0px 0px 0px",
    color: "white",
  },
  filterBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#2b2b2b",
    color: "#dedede",
    borderRadius: "2px",
    margin: "3px 0",
  },
  filterBox2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "2px",
  },
  roundMinus: {
    paddingTop: "unset",
    backgroundColor: "white",
    fontSize: "13px",
    color: "red",
    borderRadius: "52px",
    padding: "0px 3px",
    fontWeight: "bold",
    margin: "0px 5px",
    height: "fit-content",
    cursor: "pointer",
  },
  select: {
    border: 0,
    background: 0,
    color: "#dea800",
  },
  select1: {
    border: 0,
    background: 0,
    fontSize: "11px",
    color: "#9697d4",
    width: "100%",
  },

  addMore: {
    fontSize: "9px",
    fontWeight: "bold",
    color: "black",
    margin: "0 7px",
    paddingBottom: "3px",
    cursor: "pointer",
  },
  textBox: {
    color: "#bbb6b2",
    border: "0",
    fontSize: "small",
    background: 0,
    margin: "0 0 7px",
  },
};

export default connect((state) => ({
  databases: state.vState.BUILDER.databases,
}))(DatabaseQueryFilter);
