import React from "react";
import { connect } from "react-redux";
import _ from 'lodash'

import ColumnsEditor from "./ColumnsEditor";
// import RecordIdOperations from "./RecordIdOperations";
import { DatabaseTableSelector } from "../DatabaseOperations_v2/DatabaseDataSelector";

const AddRecord = (props) => {
  const [dbColumns, setDbColumns] = React.useState([]);

  const { value = {}, onChange, allDatabases, recordIdOperation } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  React.useEffect(() => {
    setDbColumns(
      allDatabases
        ?.find((x) => x._id === value.dbId)
        ?.tables?.find((x) => x._id === value.tableId)
        ?.columns?.map((x) => x.name)
        ?.filter((x) => x !== "_id")
    );
  }, [allDatabases, value.dbId, value.tableId]);

  const propsToPass = {
    ...props,
    mode: null,
    className: null,
    classNames: null,
  };

  return (
    <div className="optionItemBody">
      <div className="databaseItemActionBar">
        <div className="databaseItemActionLabel">Database</div>
        <div
          className="databaseItemActionRemove"
          onClick={() => onChange(_.pick(value, ["valueType", "uid", "_id"]))}
        >
          Clear
        </div>
      </div>

      <div className="databaseOperationModule">
        {value?.query?.valueType !== "currentValue" ? (
          <>
            <div className="domHeader">
              <div className="domLabel">Table</div>

              <DatabaseTableSelector
                {...{
                  ...propsToPass,
                  value,
                  onChange: (obj) => mergeChange(obj),
                  valueTypeData: { tableSelector: { maxNoOfInputs: 0 } },
                }}
              />
            </div>
          </>
        ) : null}

        <div className="domBody">
          <ColumnsEditor
            {...propsToPass}
            value={value.document}
            onChange={(val) => mergeChange({ document: val })}
            columns={dbColumns}
          />
        </div>

        {/* {recordIdOperation ? (
          <RecordIdOperations
            {...props}
            value={value.recordIdOperations}
            onChange={(val) => mergeChange({ recordIdOperations: val })}
          />
        ) : null} */}
      </div>
    </div>
  );
};

export default connect((state) => ({
  allDatabases: state.vState.BUILDER.databases,
}))(AddRecord);
