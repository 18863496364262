import React from "react";

class RenderItem extends React.PureComponent {
  state = {
    edit: !!this.props.edit,
    loading: false,
    error: null,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.isSelected &&
      this.props.triggerEdit !== prevProps.triggerEdit
    ) {
      this.setState({ edit: true, editingItem: this.props.item });
    }
  }

  deleteItem(_id) {
    this.setState({ loading: true, error: null });
    this.props.deleteItem(_id).catch((e) => {
      if (window.alert(e.message)) this.setState({ loading: false });
    });
  }

  updateItem(item) {
    this.setState({ loading: true, error: null });
    this.props
      .updateItem(item)
      .then((updatedItem) => {
        if (item._id)
          this.setState({
            editingItem: null,
            loading: false,
            edit: false,
          });
        else {
          this.setState({ loading: false, editingItem: null });
          this.props.reload();
        }
      })
      .catch((e) => {
        window.alert(e.message);
        this.setState({ loading: false });
      });
  }

  onEditingItemChange(obj) {
    this.setState({
      editingItem: { ...(this.state.editingItem || {}), ...obj },
    });
  }

  renderTd({ col, item }) {
    return (
      <>
        {["string", "number"].includes(typeof item?.[col])
          ? item?.[col]
          : ["object"].includes(
              item?.[col]
                ? JSON.stringify(item?.[col])
                : item?.[col]?.toString?.()
            )}
      </>
    );
  }

  renderEditingTd({ col, item, onChange, index }) {
    if (item?.[col] && !["string", "number"].includes(typeof item?.[col])) {
      return this.renderTd({ col, item, index });
    }
    return (
      <>
        <input
          disabled={this.state.loading || col === "_id"}
          value={item?.[col] || ""}
          onChange={(e) => onChange(e.target.value)}
        />
      </>
    );
  }

  render() {
    const {
      props: { item, index, isSelected, toggleSelection },
      state: { edit, editingItem, loading },
    } = this;

    return (
      <tr key={item?._id || index}>
        {this.props.columns?.map((col) => {
          return (
            <td key={col}>
              {col === "_id" ? (
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={toggleSelection}
                />
              ) : null}
              {edit
                ? this.renderEditingTd({
                    col,
                    item: editingItem,
                    index,
                    onChange: (val) => this.onEditingItemChange({ [col]: val }),
                  })
                : this.renderTd({ col, item, index })}
            </td>
          );
        })}
        <td>
          {edit ? (
            <>
              {item?._id ? (
                <button onClick={() => this.setState({ edit: false })}>
                  Cancel
                </button>
              ) : (
                <button onClick={() => this.setState({ editingItem: null })}>
                  Clear
                </button>
              )}
              <button
                disabled={loading}
                onClick={() => this.updateItem(editingItem)}
              >
                {item?._id ? "Update" : "Create New"}
              </button>
            </>
          ) : null}
        </td>
      </tr>
    );
  }
}

export default RenderItem;
