import React from "react";
import { OptionItemType1 } from "../../../../../Components/etc/OptionItem";

class StyleSelector extends React.Component {
  styleKey = "temp";
  optionItemLabel = "Style Label";
  defaultStyleValue = "unset";
  options = [
    { value: "unset", label: "Unset" },
    { value: "auto", label: "Auto" },
  ];
  renderOptionHeadTabs = null;

  mergeStyle(obj) {
    this.props.onChange({ ...(this.props.value||{}), ...obj });
  }

  setStyleValue(value) {
    this.mergeStyle({
      [this.styleKey]: value,
    });
  }

  getStyleValue() {
    return this.props.value?.[this.styleKey] || this.defaultStyleValue;
  }

  render() {
    const styleValue = this.getStyleValue();
    return (
      <OptionItemType1
        {...{
          className: "",
          optionItemLabel: this.optionItemLabel,
          renderOptionHeadTabs: this.renderOptionHeadTabs,
          value: styleValue,
          onChange: ({ value }) => this.setStyleValue(value),
          options: this.options,
        }}
      ></OptionItemType1>
    );
  }
}

export default StyleSelector;
