import React from "react";
import { toast } from "react-toastify";

import ProjectDetailsBase from "./ProjectDetailsBase";
import SearchInput from "../../../Components/Input/SearchInput";
import api from "../../../Services/Api/api";

class ProjectMembers extends ProjectDetailsBase {
  removeUser = async (userId) => {
    try {
      if (this.state.loadingObj.permissions) return;

      const permissions = this.state.project?.permissions?.filter(
        (x) => x.user._id !== userId
      );

      this.setLoading("permissions", true);

      this.setState({ project: { ...this.state.project, permissions } });

      await this.submitEdit({
        permissions: permissions.map((x) => ({ ...x, user: x.user._id })),
      });
      this.setLoading("permissions", false);
    } catch (e) {
      this.setState({ project: this.props.project });

      this.setLoading("permissions", false);
      toast.error(e.message);
    }
  };

  addUser = async (user) => {
    try {
      if (this.state.loadingObj.permissions) return;

      const permissions = [
        ...(this.state.project.permissions || []),
        { user, permission: "owner" },
      ].filter(
        (x, i, arr) => (i === arr.findIndex((y) => y.user?._id === x.user?._id))
      );

      this.setLoading("permissions", true);

      this.setState({ project: { ...this.state.project, permissions } });

      await this.submitEdit({
        permissions: permissions.map((x) => ({ ...x, user: x.user._id })),
      });
      this.setLoading("permissions", false);
    } catch (e) {
      this.setState({ project: this.props.project });

      this.setLoading("permissions", false);
      toast.error(e.message);
    }
  };

  getInitials(name = "") {
    const nameParts = name?.trim().split(/\s+/);

    if (nameParts.length === 1) {
      return nameParts[0].substring(0, 2).toUpperCase();
    } else {
      return (
        nameParts[0][0] + nameParts[nameParts.length - 1][0]
      ).toUpperCase();
    }
  }

  validateEmail = async (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  handleSearchUser = async (q) => {
    this.setState({ q });

    let email = q.length >= 3 && this.validateEmail(q) ? q : null;
    if (this.state.project.permissions?.find((x) => x.user.email === email))
      email = null;

    if (!email) {
      this.setState({ searchedUsers: null });
    } else {
      await api
        .get("v1/user/search", { email })
        .then(({ users }) => this.setState({ searchedUsers: users }))
        .catch((e) => console.warn(e.message));
    }
  };

  render() {
    const members = (this.state.project?.permissions || []).map((x) => x.user);
    const filteredMembers = this.state.q
      ? members.filter((user) => {
          const lowerCaseSearchText = this.state.q?.toLowerCase();
          return (
            user.firstName?.toLowerCase().includes(lowerCaseSearchText) ||
            user.email?.toLowerCase().includes(lowerCaseSearchText)
          );
        })
      : members;

    return (
      <>
        <div className="pageTitle">
          <div>Project Members</div>
          <span>View and manage project members</span>
        </div>

        <div className="contentBody">
          <div className="tLabel mb10">Members</div>
          <div className="borderBox mb20">
            <div className="borderItem">
              <div className="mLabel">Project users</div>

              <SearchInput
                type="text"
                className="teamSearch"
                placeholder="Search users"
                value={this.state.q}
                onChange={(q) => this.handleSearchUser(q)}
              />

              <div className="mLabel mt10">
                {members.length} member{members.length > 1 ? "s" : ""}
              </div>

              {this.state.searchedUsers?.map((user) => {
                return (
                  <div className="teamItem" key={user._id}>
                    <div className="teamContext">
                      <div className="teamIco">
                        {this.getInitials(user?.firstName)}
                      </div>
                      <div className="teamDetails">
                        <div className="teamName">{user?.firstName}</div>
                        <div className="teamSubline">{user?.email}</div>
                      </div>
                    </div>
                    {user._id !== this.props.user._id ? (
                      <div
                        onClick={() => this.addUser(user)}
                        className="teamAdd"
                      >
                        Add
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                );
              })}

              {filteredMembers.map((user) => {
                return (
                  <div className="teamItem" key={user._id}>
                    <div className="teamContext">
                      <div className="teamIco">
                        {this.getInitials(user?.firstName)}
                      </div>
                      <div className="teamDetails">
                        <div className="teamName">{user?.firstName}</div>
                        <div className="teamSubline">{user?.email}</div>
                      </div>
                    </div>
                    {user._id !== this.props.user._id ? (
                      <div
                        onClick={() => this.removeUser(user._id)}
                        className="teamRemove"
                      >
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProjectMembers;
