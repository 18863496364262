import React from "react";

import {
  CheckBoxHorizontal,
  RadioBoxHorizontal,
  RadioBoxVertical,
} from "../../../../../Components/etc/RadioBox";

const ImagePickerProperties = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  let acceptFileTypeValues = [value.acceptFileType || "photo"];
  if (value.acceptFileType === "mixed")
    acceptFileTypeValues.push("photo", "video");

  const hanldeAcceptFileTypeInput = (list) => {
    let acceptFileType = "photo";
    if (list.includes("photo") && list.includes("video"))
      acceptFileType = "mixed";
    else if (list.includes("video")) acceptFileType = "video";
    mergeChange({ acceptFileType });
  };

  return (
    <>
      <div className="optionItemHead pad5t">
        <div className="optionItemLabel">Options</div>
      </div>
      <div className="optionItemRow pad7">
        <RadioBoxVertical
          value={!!value.multiple}
          onChange={(x) => mergeChange({ multiple: !!x })}
          options={[
            { value: false, label: "Single" },
            { value: true, label: "Multiple" },
          ]}
        />
      </div>
      {/* {value.multiple ? (
        <div>
          <div>Number of images</div>
          <input
            type="number"
            value={value.minimumImageCount || ""}
            onChange={(e) => mergeChange({ minimumImageCount: e.target.value })}
            placeholder="Minimum Images"
          />
          <input
            type="number"
            value={value.maximumImageCount || ""}
            onChange={(e) => mergeChange({ maximumImageCount: e.target.value })}
            placeholder="Maximum Images"
          />
        </div>
      ) : null} */}
      <div className="optionItemRow">
        <CheckBoxHorizontal
          value={acceptFileTypeValues}
          onChange={hanldeAcceptFileTypeInput}
          options={[
            { value: "photo", label: "Photo" },
            { value: "video", label: "Video" },
          ]}
        />
      </div>
    </>
  );
};

export default ImagePickerProperties;
