import React from "react";

const Opacity = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  return (
    <div
      className="box border flex-box "
      style={{ alignItems: "center", justifyContent: "unset" }}
    >
      <span className={"title"} style={{ paddingRight: "10px" }}>
        Opacity
      </span>
      <input
        placeholder="1"
        type={"number"}
        value={styles.opacity}
        onChange={(e) => mergeStyle({ opacity: e.target.value })}
      />
    </div>
  );
};

export default Opacity;
