import StyleSelector from "./StyleSelector";

class DisplayStyle extends StyleSelector {
  styleKey = "display";
  optionItemLabel = "VISIBILITY";
  defaultStyleValue = "flex";
  options = [
    { value: "flex", label: "Show" },
    { value: "none", label: "Hide" },
  ];
}

export default DisplayStyle;
