import React from "react";
import _ from "lodash";

import conditionObj from "../../../appxolo-engine/modules/calculation/condition-obj";
import InputSelector from "../DataQueryOperations/InputSelector";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";

const ColumnsEditor = (props) => {
  const { columns = [], value = {}, onChange } = props;

  return (
    <>
      {columns.map((column, i) => (
        <ColumnEditor
          {...props}
          key={column}
          column={column}
          value={value[column]}
          onChange={(x) => onChange({ ..._.pick(value, columns), [column]: x })}
        />
      ))}
    </>
  );
};

const ColumnEditor = (props) => {
  const { column, value = {}, onChange, availableFor } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const getValidValueTypes = () => {
    return conditionObj.quickSelectionValueTypes.filter(
      (x) =>
        (!availableFor || x.availableFor.includes(availableFor)) &&
        (!x.isAvailable || x.isAvailable(props))
    );
  };

  return (
    <div className="calcItemDatabaseFilterBox">
      <div className="calcItemDatabaseFilterBoxInner">
        <div className="calcItemDatabaseFilterBoxInnerRow">
          <div className="calcItemDBLabel">{column}</div>
        </div>

        <RichTextData
          {...{
            ...props,
            immediateProps: {
              classNames: {
                wrapper: " ",
                editor: "calcDatabaseFilterMultiBox",
                paramListRow: "",
              },
            },
            value: value.valueObj,
            onChange: (valueObj) =>
              mergeChange({ ...value, valueObj, valueType: "textParts" }),
          }}
        />
      </div>
    </div>
  );
};

const styleObj = {
  customText: {
    margin: "5px 0 5px 9px",
    border: 0,
    color: "dimgray",
  },
  columnContainer: {
    backgroundColor: "white",
    borderRadius: "3px",
    fontSize: "9px",
    color: "green",
    fontWeight: "bold",
    marginTop: "8px",
  },
  columnName: {
    padding: "8px",
  },
  selectValueTypeDD: {
    backgroundColor: "lightgray",
    padding: "4px 4px",
  },
  columnValueType: {
    border: 0,
    background: 0,
    fontSize: "11px",
  },
  columnValueMethod: {
    border: 0,
    background: 0,
    fontSize: "9px",
    color: "#a00000",
  },
};

export default ColumnsEditor;
