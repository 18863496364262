import React from "react";
import ps from "../../../../appxolo-engine/modules/styling/processStyle";
const { processStyle } = ps;

class PropertyClass extends React.Component {
  processStyleTimer = null;
  processStyleWithThrottle(value, onChange) {
    clearTimeout(this.processStyleTimer);
    this.processStyleTimer = setTimeout(() => {
      this.processStyle(value, onChange);
    }, 200);
  }
  processStyle(value, onChange) {
    onChange(processStyle(value));
  }
}

export default PropertyClass;
