import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import CustomSelect from "../../../Components/etc/CustomSelect";

class DataSelectorFooter extends React.Component {
  allActions = [
    "valueOfColumn",
    "valuesOfColumn",
    "numberOfRows",
    "sumOfValues",
    "multiplicationOfValues",
    "divisionOfValues",
    "substractionOfValues",
  ].map((x) => ({ value: x, label: _.startCase(x) }));

  render() {
    const { value = {}, onChange, databases } = this.props;

    const database = databases?.find((x) => x._id === value?.dbId);
    const table = database?.tables?.find((x) => x._id === value?.tableId);
    const databaseColumns = table?.columns || [];

    return (
      <div className="calcItemUnitFooter">
        <div className="calcItemUnitMain">
          <div className="calcItemUnitMainLabel">Action</div>

          <CustomSelect
            classNames={{
              head: "calcItemUnitMainDropdown",
              label: "calcItemUnitMainDropdownLabel",
            }}
            placeholder={"Select"}
            value={value?.action || ""}
            onChange={(option) => onChange({ action: option.value })}
            options={this.allActions}
            jointActionRow={
              <div className="calcItemUnitMainDropdownFooter">
                <div className="calcItemUnitMainDropdownFooterArrow">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              </div>
            }
          />
        </div>

        {[
          "valueOfColumn",
          "valuesOfColumn",
          "sumOfValues",
          "multiplicationOfValues",
          "divisionOfValues",
          "substractionOfValues",
        ].includes(value.action) ? (
          <div className="calcItemUnitMain">
            <div className="calcItemUnitMainLabel">Select column</div>

            <CustomSelect
              classNames={{
                head: "calcItemUnitMainDropdown",
                label: "calcItemUnitMainDropdownLabel",
              }}
              placeholder={"Select"}
              value={value?.columns?.[0] || ""}
              onChange={(option) => onChange({ columns: [option.value] })}
              options={databaseColumns.map((x) => ({
                value: x.name,
                label: x.name,
              }))}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooter">
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              }
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect((state) => ({
  databases: state.vState.BUILDER.databases,
}))(DataSelectorFooter);
