import React, { useEffect, useRef, useState } from "react";

const ImageInput = ({ value, onChange, loading }) => {
  const [preview, setPreview] = useState(value);

  useEffect(() => {
    setPreview(value);
  }, [value]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setPreview(reader.result);
        onChange(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const inputRef = useRef(null);

  return (
    <div className="projectIco">
      <img src={preview}></img>
      {loading ? (
        <div
          style={{
            position: "absolute",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#0004",
          }}
        >
          <div className="loader" style={{ display: "flex" }}></div>
        </div>
      ) : (
        <div
          className="overlayChange"
          onClick={() => inputRef.current?.click()}
        >
          <div className="minLightButton">Change</div>
        </div>
      )}
      <input
        ref={inputRef}
        id="imageInput"
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleImageChange}
      />
    </div>
  );
};

export default ImageInput;
