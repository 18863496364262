import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import ColorButton from "../../../../../Components/Input/ColorButton";
import ControlledInput from "../../../../../Components/Input/ControlledInput";

const BoxShadow = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  const boxShadow = styles.boxShadow || "";
  const [color, horizontal, vertical, spread] = boxShadow.split(" ");

  const setShadow = (v, i) => {
    let shadow = [color, horizontal, vertical, spread];
    shadow[i] = v;
    mergeStyle({ boxShadow: shadow.join(" ") });
  };

  const optionHeadTabs = (
    <ToggleButton
      classNames={{
        container: "optionHeadTabs headTabsDark",
        item: "optionHeadTabItem",
      }}
      overideDefaultStyle
      value={boxShadow ? "on" : ""}
      onChange={(x) =>
        mergeStyle({
          boxShadow: x ? "#000000 10px 10px 10px" : "",
        })
      }
      options={[
        { value: "on", label: "On" },
        { value: "", label: "Off" },
      ]}
    />
  );

  return (
    <OptionItem optionItemLabel="Shadow" optionHeadTabs={optionHeadTabs}>
      <div className="optionItemBody">
        <div className="optionItemRow">
          <div className="optionItemBox fInitial">
            <ColorButton
              key={color}
              className="borderColorPicker"
              value={color || "#000000"}
              onChange={(val) => setShadow(val, 0)}
            />
          </div>
          <div className="optionItemBox">
            <div className="optionInputIconBox">
              <img
                className="optionInputIconBoxArrowIco"
                src={require("../../../../../Assets/img/options/container/widthIco.png")}
              ></img>

              <ControlledInput
                onChange={(event) => setShadow(event.target.value, 1)}
                className="optionInputIconBoxSpacingField"
                value={horizontal || ""}
              />
            </div>
          </div>
          <div className="optionItemBox">
            <div className="optionInputIconBox">
              <img
                className="optionInputIconBoxArrowIco rotate90"
                src={require("../../../../../Assets/img/options/container/widthIco.png")}
              ></img>
              <ControlledInput
                onChange={(event) => setShadow(event.target.value, 2)}
                className="optionInputIconBoxSpacingField"
                value={vertical || ""}
              />
            </div>
          </div>
          <div className="optionItemBox">
            <div className="optionInputIconBox">
              <img
                className="optionInputIconBoxArrowIco rotate90"
                src={require("../../../../../Assets/img/options/options/greyArrow.png")}
              ></img>
              <ControlledInput
                onChange={(event) => setShadow(event.target.value, 3)}
                className="optionInputIconBoxSpacingField"
                value={spread || ""}
              />
            </div>
          </div>
        </div>
      </div>
    </OptionItem>
  );
};

export default BoxShadow;
