import React from "react";
import { connect } from "react-redux";
import CustomSelect from "../../../Components/etc/CustomSelect";

const TableSelector = (props) => {
  const database = props.databases?.find((x) => x._id === props.dbId);
  const tables = database?.tables || [];

  return (
    <div className="optionConditionTableSelectorHead">
      <div className="optionConditionTableSelectorHeadSubline">Table</div>
      <CustomSelect
        classNames={{
          head: "optionConditionTableSelectorHeadSelect",
          label: "optionConditionTableSelectorHeadSelectLabel",
          chevron: "optionConditionTableSelectorHeadSelectIco",
        }}
        onChange={(option) => props.onChange(option.value)}
        value={props.value || ""}
        placeholder={"Select Table"}
        options={tables.map((x) => ({
          value: x._id,
          label: x.name,
        }))}
      />
    </div>
  );
};

export default connect((state) => ({
  databases: state.vState.BUILDER.databases,
}))(TableSelector);
