import { v4 as uuidv4 } from "uuid";

const etcModule = {
  getDeviceToken: () => {
    let deviceToken = localStorage.getItem("DEVICE_TOKEN");
    if (!deviceToken) {
      deviceToken = uuidv4();
      localStorage.setItem("DEVICE_TOKEN", deviceToken);
    }

    return deviceToken;
  },

   mergeObjects: (x, y, keys) => {
    if (!keys.length) {
      return { ...x, ...y };
    }

    const [currentKey, ...remainingKeys] = keys;
    const newX = { ...(x || {}) };

    newX[currentKey] = etcModule.mergeObjects(newX[currentKey], y, remainingKeys);
    return newX;
  }
};

export default etcModule;
