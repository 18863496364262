import React from "react";
import update from "immutability-helper";

import PlotMarks from "./PlotMarks";
import CreateRoute from "./CreateRoute";
import Navigate from "./Navigate";
import OptionItem from "../../../../../Components/etc/OptionItem";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import { RadioBoxHorizontal } from "../../../../../Components/etc/RadioBox";

const MapData = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const operations = value.operations || [{ typeOfData: "fixed" }];
  const setOperation = (operation, i) => {
    mergeChange({
      operations: update(operations, { $merge: { [i]: operation } }),
    });
  };

  return (
    <div>
      {operations.map((operation, i) => (
        <MapOperation
          {...props}
          key={i}
          value={operation}
          onChange={(value) => setOperation(value, i)}
        />
      ))}
    </div>
  );
};

const MapOperation = (props) => {
  const { value, onChange } = props;

  const operation = value || {};
  const setOperationObj = (obj) => {
    onChange(update(operation, { $merge: obj }));
  };

  const components = {
    mark: <PlotMarks {...props} value={operation} onChange={onChange} />,
    route: <CreateRoute {...props} value={operation} onChange={onChange} />,
    navigate: <Navigate {...props} value={operation} onChange={onChange} />,
  };

  const selectedOperation = operation.operation;

  const optionHeadTabs = (
    <ToggleButton
      classNames={{
        container: "optionHeadTabs",
        item: "optionHeadTabItem",
      }}
      overideDefaultStyle
      value={operation.typeOfData}
      onChange={(value) => setOperationObj({ typeOfData: value })}
      options={[
        { label: "Fixed", value: "fixed" },
        { label: "Repeating", value: "repeating" },
      ]}
    />
  );

  return (
    <>
      <OptionItem optionItemLabel="MAP" optionHeadTabs={optionHeadTabs}>
        <div className="optionItemBody">
          <div className="optionItemRow">
            <RadioBoxHorizontal
              value={operation.operation || ""}
              onChange={(value) => setOperationObj({ operation: value })}
              options={operationOptions}
            />
          </div>
        </div>
        {components[selectedOperation]
          ? components[selectedOperation]
          : selectedOperation}
      </OptionItem>
    </>
  );
};

const operationOptions = [
  {
    label: "Marker",
    value: "mark",
  },
  {
    label: "Route",
    value: "route",
  },
  // {
  //   label: "Navigate",
  //   value: "navigate",
  // },
];

export default MapData;
