import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import { Form, Button, Table, Container } from "react-bootstrap";

import api from "../../Services/Api/api";
import AppModal from "../../Components/Models/app-modal";
import NavBar from "../Common/NavBar";

const AddProjectPopup = (props) => {
  const [form, setForm] = useState({ ...(props.project || {}) });
  const [img, setImg] = useState(
    props.project?.image ? api.getFileLink(props.project.image) : null
  );

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };

  const content = (
    <div>
      <div className="errormsg">{props.formError}</div>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          required
          value={form?.name || ""}
          onChange={(e) => {
            updateForm({ name: e.target.value });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Subdomain</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Subdomain"
          required
          value={form?.subdomain || ""}
          onChange={(e) => {
            updateForm({ subdomain: e.target.value });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Enter Description"
          value={form?.description || ""}
          onChange={(e) => {
            updateForm({ description: e.target.value });
          }}
        />
      </Form.Group>
      {img ? <img style={{ width: "100px" }} src={img} alt="img" /> : null}
      <Form.Group className="mb-3">
        <Form.Label>Image</Form.Label>
        <Form.Control
          type="file"
          placeholder="Select Image"
          accept="image/png, image/jpeg"
          files={form?.image}
          onChange={(e) => {
            const file = e.target.files?.[0];
            updateForm({ image: file, newImage: true });
            setImg(file ? URL.createObjectURL(file) : null);
          }}
        />
      </Form.Group>
      {props.loading ? <div>Loading...</div> : null}
      {/* <Button disabled={props.loading} type="submit">
        Create Project
      </Button>{" "}
      <Button type="button" onClick={() => props.setPopup(false)}>
        Cancel
      </Button> */}
    </div>
  );

  return (
    <AppModal
      {...{
        title: props.title || "Create New Project",
        visible: true,
        handleClose: () => props.setPopup(false),
        disableBtns: props.loading,
        submitBtnText: props.loading
          ? "Loading"
          : props.submitBtnText || "Create Project",
        onSubmit: (e) => props.handleSubmit(e, form),
      }}
    >
      {content}
    </AppModal>
  );
};

const AddProject = (props) => {
  const [popup, setPopup] = useState(false);
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);

  const appendProject = (project) => {
    props.setProjects(update(props.projects, { $push: [project] }));
  };

  const handleSubmit = async (event, form) => {
    event.preventDefault();

    try {
      if (loading) return;

      setLoading(true);
      setFormError(null);

      const fileRes = form.image
        ? await api.media("v1/file", { file: form.image })
        : null;

      const res = await api
        .post("v1/project", {
          name: form.name,
          subdomain: form.subdomain,
          description: form.description,
          image: fileRes?.file._id,
        })
        .catch(async (e) => {
          if (fileRes?.file?._id)
            await api
              .delete(`v1/file/${fileRes?.file._id}`)
              .catch(console.error);
          throw e;
        });
      setLoading(false);
      setPopup(false);

      appendProject(res.project);
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
    }
  };

  return (
    <div>
      <div>
        <Button onClick={() => setPopup(true)}>Add Project</Button>
      </div>

      {popup ? (
        <AddProjectPopup
          setPopup={setPopup}
          handleSubmit={handleSubmit}
          formError={formError}
          loading={loading}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const ListProject = (props) => {
  return (
    <div>
      {!props.projects ? (
        <div />
      ) : !props.projects.length ? (
        <div className="noList">Currently you have no projects</div>
      ) : (
        <Table>
          <tbody>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Subdomain</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
            {props.projects.map((project) => (
              <tr key={project._id}>
                <td>
                  <img
                    style={{ width: "70px" }}
                    src={api.getFileLink(project.image)}
                    alt="icon"
                  />
                </td>
                <td>{project.name}</td>
                <td>{project.subdomain}</td>
                <td>{project.description}</td>
                <td>
                  <Link key={project._id} to={`/project/${project._id}`}>
                    <Button variant="success">Open</Button>
                  </Link>
                  <Button onClick={() => props.edit(project)} variant="primary">
                    Edit
                  </Button>
                  <Button
                    onClick={() => props.delete(project)}
                    variant="danger"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

const ProjectsScreen = () => {
  const [projects, setProjects] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const { projects } = await api.get("v1/project");
      setProjects(projects);
    } catch (e) {
      console.error(e.message);
    }
  };

  const deleteProject = async (project) => {
    try {
      const typed = window.prompt(
        "Are you sure you want to delete this project ? Confirm by typing the project name"
      );
      if (typed && typed === project.name) {
        await api.delete(`v1/project/${project._id}`);
        await fetchProjects();
      } else if (typed !== null) {
        window.alert("Name mismatched");
        deleteProject(project);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const handleEditSubmit = async (event, form) => {
    event.preventDefault();

    try {
      if (loading) return;

      setLoading(true);
      setFormError(null);

      let image = form.image;

      if (form.newImage && form.image) {
        const fileRes = await api.media("v1/file", { file: form.image });
        image = fileRes?.file?._id;
      }

      await api
        .put("v1/project", {
          _id: form._id,
          name: form.name,
          subdomain: form.subdomain,
          description: form.description,
          image,
        })
        .catch(async (e) => {
          if (form.newImage && image) {
            api.delete(`v1/file/${image}`).catch(console.error);
          }
          throw e;
        });
      setLoading(false);
      setEditModal(null);

      fetchProjects();
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
    }
  };

  return (
    <>
      <NavBar activeLink="/project" />
      <Container>
        <div className="flex sb">
          <h2>Projects</h2>
          <AddProject projects={projects} setProjects={setProjects} />
        </div>
        <ListProject
          projects={projects}
          setProjects={setProjects}
          edit={(x) => setEditModal(x)}
          delete={deleteProject}
        />
      </Container>

      {editModal ? (
        <AddProjectPopup
          setPopup={setEditModal}
          handleSubmit={handleEditSubmit}
          formError={formError}
          loading={loading}
          project={editModal}
          title="Edit Project"
          submitBtnText="Submit"
        />
      ) : null}
    </>
  );
};

export default ProjectsScreen;
