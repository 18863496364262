import React from "react";
import update from "immutability-helper";
import InputSelector from "../../../../Common/DataQueryOperations/InputSelector";

const MarkLocation = (props) => {
  const { value, onChange, markerNo, options = ddOptions, defaultType } = props;

  const origin = value || {};
  const setOriginObj = (obj) => onChange(update(origin, { $merge: obj }));

  const valueType = origin.valueType || defaultType || "customAddress";

  return (
    <div style={styleObj.containerFlex}>
      <div>
        {markerNo === undefined ? (
          ""
        ) : typeof markerNo === "number" ? (
          <div style={styleObj.markerNo}>{markerNo + 1 || 1}</div>
        ) : typeof markerNo === "string" ? (
          <div
            style={{
              background: markerNo,
              padding: "4px",
              borderRadius: "7px",
              border: "1px #eee solid",
            }}
          />
        ) : (
          ""
        )}
      </div>
      <div>
        <div>
          <select
            style={styleObj.select}
            value={origin.valueType || defaultType || ""}
            onChange={(event) =>
              setOriginObj({ valueType: event.target.value })
            }
          >
            {options.map((x, i) => (
              <option key={i} value={x.value}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
        <InputSelector
          {...{
            ...props,
            valueType,
            value: origin.valueObj,
            onChange: (valueObj) =>
              setOriginObj({
                valueObj,
              }),
          }}
        />
      </div>
    </div>
  );
};

const ddOptions = [
  { name: "Custom Address", value: "customAddress" },
  { name: "Current Location", value: "currentLocation" },
  { name: "Passed Parameter", value: "passedParameter" },
  { name: "Input Value", value: "valueOfInputElement" },
  { name: "Calculation", value: "calculation" },
];

const styleObj = {
  containerFlex: {
    background: "#d0d0d0",
    padding: "6px",
    display: "flex",
    alignItems: "baseline",
  },

  markerNo: {
    fontSize: "small",
    padding: "0 2px",
    backgroundColor: "whitesmoke",
  },
  select: {
    border: 0,
    background: 0,
    fontSize: "11px",
    color: "#444",
    width: "100%",
  },
};

export default MarkLocation;
