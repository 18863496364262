import api from "../../Services/Api/api";
import { initSocket } from "../../Services/Socket/socket";
import { store } from "../../store";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

const authModule = {
  login: async ({ email, password, clientType, deviceId }) => {
    const { token } = await api.post("v1/user/login", {
      email,
      password,
      clientType,
      deviceId,
    });

    await store.dispatch(PActions.setPScreenState("AUTH", { token }));

    await authModule.initAuth({ token });

    return { token };
  },

  fetchMyProfile: async (opt) => {
    let apiOpt = {};
    if (opt?.token) {
      apiOpt = {
        headers: {
          authorization: "Bearer " + opt.token,
        },
      };
    }

    const { user } = await api.get("v1/user/self", {}, apiOpt);

    await store.dispatch(PActions.setPScreenState("AUTH", { user }));

    return user;
  },

  initAuth: async (opt) => {
    initSocket({ onConnect: null });
    await authModule.fetchMyProfile(opt);
  },

  signup: async ({
    firstName,
    lastName,
    email,
    password,
    clientType,
    deviceId,
  }) => {
    await api.post("v1/user/signup", {
      firstName,
      lastName,
      email,
      password,
    });

    await authModule.login({ email, password, clientType, deviceId });
  },

  logout: async () => {
    await store.dispatch(PActions.resetPState());
    await store.dispatch(UnpActions.resetVState());
  },
};

export default authModule;
