import React from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import api from "../../../Services/Api/api";

class LocationInput extends React.Component {
  state = {
    text: this.props.value?.description || "",
    focused: false,
    suggestions: [],
  };

  API_CALL_INTERVAL = 100;
  API_SESSION_TIMEOUT_SECOND = 60;
  session = {
    token: uuidv4(),
    expires: Date.now() + this.API_SESSION_TIMEOUT_SECOND * 1000,
  };

  timer = null;

  handleChange(text) {
    this.setState({ text, focused: true });

    clearTimeout(this.timer);

    if (text.length >= 3) {
      this.timer = setTimeout(
        () =>
          this.getSuggestions(text)
            .then((predictions) => {
              this.setState({
                suggestions: predictions.map((x) =>
                  _.pick(x, ["description", "place_id"])
                ),
              });
            })
            .catch((e) =>
              console.error("Error getting place-suggestions: " + e.message)
            ),
        this.API_CALL_INTERVAL
      );
    }
  }

  selectLocation(location) {
    this.setState({
      focused: false,
      suggestions: [],
      text: location.description,
    });

    return api
      .get("v1/google/places/reverseGeocode", {
        place_id: location.place_id,
      })
      .then((data) => {
        const locationDetails = {
          description: location.description,
          place_id: location.place_id,
          ...data,
        };
        this.props.onChange(locationDetails);
      });
  }

  getSuggestions(input) {
    if (this.session.expires < Date.now()) {
      this.session = {
        token: uuidv4(),
        expires: Date.now() + this.API_SESSION_TIMEOUT_SECOND * 1000,
      };
    }

    return api
      .get("v1/google/places/placesAutoComplete", {
        input,
        sessiontoken: this.session.token,
      })
      .then((data) => {
        if (data?.status === "OK" && data.predictions) {
          return data.predictions;
        } else {
          throw new Error("Error getting place suggestions");
        }
      })
      .catch((e) => console.error("Error getting place details: " + e.message));
  }

  handleClose() {
    this.setState({ focused: false, suggestions: [] });
  }

  render() {
    const {
      props: { label, placeholder },
      state: { focused, suggestions, text },
    } = this;
    return (
      <div style={{ position: "relative" }}>
        {label ? <div>{label}</div> : null}
        <input
          type="text"
          onChange={(e) => this.handleChange(e.target.value)}
          value={text || ""}
          placeholder={placeholder || "Type Here.."}
        />
        {focused ? (
          <div>
            <div style={styleObj.cover} onClick={this.handleClose.bind(this)} />
            <div style={styleObj.holder}>
              {suggestions.map((x) => (
                <div
                  key={x.place_id}
                  style={styleObj.description}
                  onClick={() => this.selectLocation(x)}
                >
                  <span style={styleObj.pacIcon} />
                  <span>{x.description}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const styleObj = {
  box: {},
  inputs: {
    border: "0",
    background: "0",
    color: "#848484",
    width: "100%",
    fontSize: "13px",
  },
  holder: {
    position: "absolute",
    margin: "6px 0",
    backgroundColor: "#d8eeff",
    fontSize: "smaller",
    // width: "180px",
    boxShadow: "grey 0px 2px 5px 1px",
    zIndex: 2,
  },
  description: {
    border: "solid 0.5px gray",
    padding: "5px 0px 5px 4px",
    cursor: "pointer",
    color: "black",
  },
  pacIcon: {
    padding: "3px 9px",
    width: "15px",
    height: "20px",
    background:
      "url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png)",
    backgroundPosition: "-1px -160px",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
};

export default LocationInput;
