import React from "react";
import _ from 'lodash';

import styleModule from "../../../../Modules/style/style-module";
import { Form } from "react-bootstrap";

class Input extends React.PureComponent {
  updateStaticText(x) {
    console.log(x);
  }

  render() {
    const element = this.props.element;
    const activeTab =
      element.value?.data?.tabs?.[element.value?.data?.activeTabIndex || 0];

    const inputStyle =
      styleModule.getElementStyleData(element)?.input?.builder || {};
    const labelStyle =
      styleModule.getElementStyleData(element)?.label?.builder || {};

    const withoutBorderStyle = {
      borderTopWidth: "0px",
      borderBottomWidth: "0px",
      borderLeftWidth: "0px",
      borderRightWidth: "0px",
    };
    const withBorderStyle = {
      borderTopWidth: "1px",
      borderBottomWidth: "1px",
      borderLeftWidth: "1px",
      borderRightWidth: "1px",
      borderTopStyle: "solid",
      borderBottomStyle: "solid",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
    };

    const inputType = activeTab.inputType || {
      group: "textInput",
      inputType: "default",
    };

    const textInput = {
      default: (
        <input style={inputStyle} placeholder={`Input #${element.id}`} />
      ),
      withLabel: (
        <>
          <div style={labelStyle}>
            {activeTab?.inputData?.labelText || "Label"}
          </div>
          <input
            style={{ ...withoutBorderStyle, ...inputStyle }}
            placeholder={`Input #${element.id}`}
          />
        </>
      ),
      withBorderAndLabel: (
        <>
          <div style={labelStyle}>
            {activeTab?.inputData?.labelText || "Label"}
          </div>
          <input
            style={{ ...withBorderStyle, ...inputStyle }}
            placeholder={`Input #${element.id}`}
          />
        </>
      ),
    };

    const selectBoxValue =
      activeTab.inputData?.selected?.valueObj?.customText || "";
    const selectBoxOptions = [
      { value: "", label: "Select" },
      ...(activeTab.inputData?.options?.valueObj?.multipleStatic || []),
    ]
      .filter((x, i, arr) => i === arr.findIndex((z) => x.value === z.value))
      .map((x, i) => (
        <option key={x.value + i} value={x.value}>
          {x.label || x.value}
        </option>
      ));

    const components = {
      textInput: textInput,
      locationInput: textInput,
      selectBox: {
        default: (
          <select
            style={{ ...withBorderStyle, ...inputStyle }}
            defaultValue={selectBoxValue}
          >
            {selectBoxOptions}
          </select>
        ),
        withLabel: (
          <>
            <div style={labelStyle}>
              {activeTab?.inputData?.labelText || "Label"}
            </div>
            <select
              style={{ ...withoutBorderStyle, ...inputStyle }}
              defaultValue={selectBoxValue}
            >
              {selectBoxOptions}
            </select>
          </>
        ),
        withBorderAndLabel: (
          <>
            <div style={labelStyle}>
              {activeTab?.inputData?.labelText || "Label"}
            </div>
            <select
              style={{ ...withBorderStyle, ...inputStyle }}
              defaultValue={selectBoxValue}
            >
              {selectBoxOptions}
            </select>
          </>
        ),
      },
      checkBox: {
        default: (
          <label>
            <input style={inputStyle} type="checkbox" />
            {activeTab?.inputData?.labelText || "Label"}
          </label>
        ),
      },
      radio: {
        default: (
          <label>
            <input style={inputStyle} type="radio" />
            {activeTab?.inputData?.labelText || "Label"}
          </label>
        ),
      },
      switch: {
        default: <Form.Switch style={inputStyle} size="large" />,
      },
      imagePicker: {
        default: (
          <FilePicker
            type="image"
            style={inputStyle}
            labelStyle={labelStyle}
            data={activeTab.inputData || {}}
          />
        ),
      },
      filePicker: {
        default: (
          <FilePicker
            style={inputStyle}
            labelStyle={labelStyle}
            data={activeTab.inputData || {}}
          />
        ),
      },
      dateTimePicker: {
        default: <input style={inputStyle} type="datetime-Local" />,
      },
    };

    return components[inputType.group]?.[inputType.inputType] || null;
  }
}

const FilePicker = ({ style, type, data, labelStyle }) => {
  const [files, setFiles] = React.useState(null);
  const [src, setSrc] = React.useState(null);

  const inputEl = React.useRef(null);

  const handleChange = (event) => {
    let files = event.target.files;
    setFiles(files);

    if (type === "image" && files && files[0])
      setSrc(URL.createObjectURL(files[0]));
    else setSrc(false);
  };

  const elStyle = Object.assign(
    {
      backgroundColor: "lightgray",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    style
  );

  const evalTextParts = (data) => {
    const textParts = data?.textParts
      ?.map((x) =>
        !x?.valueType || x?.valueType === "customText"
          ? x?.valueObj?.customText
          : "(" + _.startCase(x?.valueType) + ")"
      )
      ?.filter((x) => x !== undefined);
    const text = textParts?.length ? textParts.join("") : undefined;
    return text;
  };

  const labelDataText = evalTextParts(data?.label?.valueObj);

  return (
    <span
      // onClick={() => inputEl.current?.click()}
      style={elStyle}
    >
      <input
        style={{ display: "none" }}
        ref={inputEl}
        type="file"
        onChange={handleChange}
        accept={type === "image" ? "image/*" : "*/*"}
      />

      {files ? (
        type === "image" ? (
          <img alt="" src={src} style={elStyle} />
        ) : (
          <div style={elStyle}>{files[0]?.name}</div>
        )
      ) : (
        <div style={labelStyle}>
          {labelDataText
            ? labelDataText
            : type === "image"
            ? "Upload Image"
            : "Upload File"}
        </div>
      )}
    </span>
  );
};

export default Input;
