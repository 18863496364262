import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import etcModule from "../../../../../Modules/etc/etc-module";
import Dimensions from "../../Common/StyleProperties/Dimensions";
import InputType from "./InputType";
import Border from "../../Common/StyleProperties/Border";
import Padding from "../../Common/StyleProperties/Padding";
import Margin from "../../Common/StyleProperties/Margin";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import TextColor from "../../Common/StyleProperties/TextColor";
import InputLabelStyle from "../../Common/StyleProperties/InputLabelStyle";
import InputResponse from "../../Common/StyleProperties/InputResponse";
import ImagePickerProperties from "./ImagePickerProperties";
import DateTimePickerConfig from "./DateTimePickerConfig";
import InputData from "./InputData";
import Appearance from "../../Common/StyleProperties/Appearance";
import Spacing from "../../Common/StyleProperties/Spacing";
import TextInputConfig from "./TextInputConfig";

class InputProperties extends PropertyClass {
  getStyleProps = (key) => {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });
    const mergeStyleData = (styleData) => mergeChange({ styleData });

    return {
      ...this.props,
      value: value.styleData?.[key]?.default,
      onChange: (obj) => {
        const updatedStyleData = etcModule.mergeObjects(value.styleData, obj, [
          key,
          "default",
        ]);
        mergeStyleData(updatedStyleData);

        this.processStyleWithThrottle(
          updatedStyleData[key]?.default,
          (styleData) =>
            mergeStyleData({ ...updatedStyleData, [key]: styleData })
        );
      },
    };
  };

  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });

    const inputStyleProps = this.getStyleProps("input");
    const labelStyleProps = this.getStyleProps("label");
    const inputDataProps = {
      ...this.props,
      value: value.inputData,
      onChange: (inputData) => mergeChange({ inputData }),
      mergeChange: (obj) =>
        mergeChange({ inputData: { ...(value.inputData || {}), ...obj } }),
    };

    return (
      <>
        <Dimensions {...inputStyleProps} />

        <div className="optionItem">
          <div className="optionItemInner">
            <InputType
              {...{
                ...this.props,
                value: value.inputType,
                onChange: (inputType) => mergeChange({ inputType }),
              }}
            />
            {(value.inputType?.group || "textInput") === "textInput" ? (
              <TextInputConfig {...inputDataProps} />
            ) : value.inputType?.group === "imagePicker" ? (
              <ImagePickerProperties {...inputDataProps} />
            ) : value.inputType?.group === "dateTimePicker" ? (
              <DateTimePickerConfig {...inputDataProps} />
            ) : null}
          </div>
        </div>

        {/* {["withLabel", "withBorderAndLabel"].includes(
          value.inputType?.inputType
        ) ||
        [
          "textInput",
          "locationInput",
          "selectBox",
          "checkBox",
          "radio",
          "filePicker",
          "imagePicker",
        ].includes(value.inputType?.group) ? (
          <InputLabelStyle
            {...{
              ...labelStyleProps,
              labelText: inputDataProps.value?.labelText || "",
              changeLabelText: (x) =>
                inputDataProps.mergeChange({ labelText: x }),
            }}
          />
        ) : null} */}

        <Appearance {...inputStyleProps} />
        <Border {...inputStyleProps} />
        <Spacing {...inputStyleProps} />
        <BorderRadius {...inputStyleProps} />
        <InputData
          {...this.props}
          inputType={value.inputType}
          value={value.inputData}
          onChange={(x) => mergeChange({ inputData: x })}
          linkBoxOptions={{
            conditionalDataType: true,
            showTriggerOption: true,
            styles: {
              container: {
                margin: "3px 0",
                padding: "5px",
                border: "solid 0.5px grey",
                borderRadius: "2px",
                backgroundColor: "#fff",
                color: "#333",
              },
            },
          }}
        />
      </>
    );
  }
}

export default InputProperties;
