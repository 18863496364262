import React from "react";

import TableSelector from "./TableSelector";
import DatabaseSelector from "./DatabaseSelector";
import DatabaseQueryFilter from "./DatabaseQueryFilter";
import DatabaseColumnNames from "./DatabaseColumnNames";
import conditionObj from "../../../appxolo-engine/modules/calculation/condition-obj";

class DatabaseDataSelector extends React.Component {
  render() {
    const { value, onChange, valueType } = this.props;
    const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

    const valueTypeData =
      this.props.valueTypeData ||
      conditionObj.valueTypes.find((x) => x.value === valueType);

    return (
      <div className="databasedataselector">
        <div className="optionConditionTableSelector">
          <DatabaseSelector
            {...this.props}
            value={value}
            onChange={(obj) => mergeChange(obj)}
          />
        
          <DatabaseQueryFilter
            {...this.props}
            dbId={value?.dbId}
            tableId={value?.tableId}
            value={value}
            onChange={(obj) => mergeChange(obj)}
            valueTypeData={null}
          />
        </div>

        <DatabaseColumnNames
          {...this.props}
          dbId={value?.dbId}
          tableId={value?.tableId}
          value={value?.columns}
          options={valueTypeData}
          onChange={(columns) => mergeChange({ columns })}
        />
      </div>
    );
  }
}

export default DatabaseDataSelector;
