import React, { useRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import AppModal from "../../Components/Models/app-modal";
import api from "../../Services/Api/api";
import DatabaseSelector from "./DatabaseOperations/DatabaseSelector";
import TableSelector from "./DatabaseOperations/TableSelector";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

export class OnlineDeviceLoggerInner extends React.Component {
  initialState = {
    loading: false,
    error: null,
    onlineDevicelogger: this.props.project?.data?.onlineDevicelogger || {},
  };

  state = { ...this.initialState };

  async onSubmit() {
    try {
      if (this.state.loading) return;

      const onlineDevicelogger = this.state.onlineDevicelogger;

      if (!onlineDevicelogger?.dbId) throw new Error("Select one database");
      if (!onlineDevicelogger?.tableId) throw new Error("Select one table");

      const selectedTable = this.props.databases
        ?.find((x) => x._id?.toString() === onlineDevicelogger?.dbId?.toString())
        ?.tables?.find(
          (x) => x._id?.toString() === onlineDevicelogger?.tableId?.toString()
        );

      const existingColumnNames = selectedTable?.columns?.map((x) => x.name);

      const columnsToBeAdded = [ "deviceId", "dataType", 'updatedAt', 'status'].filter(
        (x) => !existingColumnNames?.includes(x)
      );

      this.setState({ loading: true, error: null });

      for (let i = 0; i < columnsToBeAdded.length; i++) {
        const column = columnsToBeAdded[i];
        await api.post(`v1/database/${onlineDevicelogger.dbId}/column`, {
          tableName: selectedTable?.name,
          columnName: column,
        });
      }

      await api.put("v1/project", {
        _id: this.props.project?._id,
        data: {
          ...(this.props.project?.data || {}),
          onlineDevicelogger: this.state.onlineDevicelogger,
        },
      });

      const { projects } = await api.get("v1/project", {
        filter: JSON.stringify({
          where: { _id: this.props.project?._id },
          limit: 1,
        }),
      });
      if (projects[0]) this.props.setScreenState({ project: projects[0] });

      this.setState({ loading: false, visible: false });
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }

  render() {
    const value = this.state.onlineDevicelogger;
    console.log(this.props);
    return (
      <div>
        <div className="errormsg">{this.state.error}</div>
        {this.state.loading ? <div className="loading">Loading..</div> : null}
        <DatabaseSelector
          {...this.props}
          value={value}
          onChange={(obj) => this.setState({ onlineDevicelogger: { ...value, ...obj } })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "BUILDER") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export const OnlineDeviceLogger = connect(mapStateToProps, mapDispatchToProps)(OnlineDeviceLoggerInner);

export const OnlineDeviceLoggerModalInner = (props) => {
  const onlineDevicelogger = useRef(null);
  return (
    <AppModal
      {...{
        title: "Online Device Logger",
        visible: props.visible,
        handleClose: () => props.close(),
        onSubmit: () => onlineDevicelogger.current.onSubmit(),
      }}
    >
      {props.visible ? <OnlineDeviceLoggerInner ref={onlineDevicelogger} {...props} /> : null}
    </AppModal>
  );
};

export const OnlineDeviceLoggerModal = connect(mapStateToProps, mapDispatchToProps)(
  OnlineDeviceLoggerModalInner
);

export class ShowOnlineDeviceLoggerBtn extends React.Component {
  state = { visible: false };

  render() {
    return (
      <>
        <button onClick={() => this.setState({ visible: true })}>
          Online Device Logger
        </button>
        <OnlineDeviceLoggerModal
          {...{
            ...this.props,
            close: () => this.setState({ visible: false }),
            visible: this.state.visible,
          }}
        />
      </>
    );
  }
}
