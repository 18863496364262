import StyleSelector from "../../Common/StyleProperties/StyleSelector";

class SafeAreaViewConfig extends StyleSelector {
  styleKey = "enable";
  optionItemLabel = "SAFE AREA";
  defaultStyleValue = "";
  options = [
    { value: "", label: "Off" },
    { value: "enable", label: "On" },
  ];
}

export default SafeAreaViewConfig;
