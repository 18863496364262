import React from "react";

import {
  RadioBoxHorizontal,
  RadioBoxVertical,
} from "../../../../../Components/etc/RadioBox";

const TextInputConfig = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <div className="optionItemHead pad5t">
        <div className="optionItemLabel">Options</div>
      </div>
      <div className="optionItemRow pad7">
        <RadioBoxVertical
          value={value.dataValidation || "text"}
          onChange={(x) => mergeChange({ dataValidation: x })}
          options={[
            { value: "text", label: "Plain Text" },
            { value: "number", label: "Number" },
            { value: "email", label: "Email" },
            { value: "password", label: "Password" },
          ]}
        />
      </div>
      {(value.dataValidation || "text") === "text" ? (
        <div className="optionItemRow">
          <RadioBoxHorizontal
            value={!!value.multiline}
            onChange={(x) => mergeChange({ multiline: !!x })}
            options={[
              { value: false, label: "Single Line" },
              { value: true, label: "Multi Line" },
            ]}
            isCheckbox
          />
        </div>
      ) : null}
    </>
  );
};

export default TextInputConfig;
