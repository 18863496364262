import React from "react";
import update from "immutability-helper";

import RepeatingMark from "./RepeatingMark";
import FixedMark from "./FixedMark";
import MapCenter from "./MapCenter";

const PlotMarks = (props) => {
  const { value, onChange } = props;
  const operation = value || {};
  const setOperationObj = (obj) => {
    onChange(update(operation, { $merge: obj }));
  };

  const locationMarks = operation.locationMarks || [];
  const setMarks = (value) =>
    setOperationObj(update(operation, { $merge: { locationMarks: value } }));

  const setMark = (obj) => setMarks(update(locationMarks, { $merge: obj }));
  const appendMark = () => setMarks(update(locationMarks, { $push: [{}] }));

  if (operation.typeOfData === "repeating")
    return (
      <>
        <RepeatingMark
          {...props}
          value={locationMarks[0] || {}}
          // eslint-disable-next-line no-useless-computed-key
          onChange={(value) => setMark({ [0]: value })}
          mapCenter={operation.mapCenter}
          changeMapCenter={(value) => setOperationObj({ mapCenter: value })}
        />
      </>
    );
  else
    return (
      <div className="optionMapDatabaseWrapper">
        {locationMarks.map((location, markerNo) => (
          <div key={markerNo} className="optionMapCombo">
            <div className="optionMapLeft">
              <div className="optionMapNumberWrapper">
                <div className="optionMapNumber">{markerNo + 1}</div>
              </div>

              <div className="optionMapLeftDecor"></div>
            </div>
            <FixedMark
              {...props}
              key={markerNo}
              value={location || {}}
              onChange={(value) => setMark({ [markerNo]: value })}
              markerNo={markerNo}
              remove={() =>
                setMarks(update(locationMarks, { $splice: [[markerNo, 1]] }))
              }
            />
          </div>
        ))}

        <div onClick={() => appendMark()} className="mapLinkingWrapper">
          <div className="optionAddLinkingButton">Add Marker</div>
        </div>

        <div className="mapLinkingWrapper">
          <div className="optionDatabaseTextBox mt10">
            <div className="optionDatabaseTextBoxLabel">Map Center</div>
            <MapCenter
              {...props}
              value={operation.mapCenter}
              onChange={(value) => setOperationObj({ mapCenter: value })}
            />
          </div>
        </div>
      </div>
    );
};

export default PlotMarks;
