import React from "react";
import _ from "lodash";

import OptionItem from "../../../../../../Components/etc/OptionItem";
import { CheckBoxHorizontal } from "../../../../../../Components/etc/RadioBox";
import RichTextData from "../../../Common/DataProperties/RichTextData";

const SliderConfig = (props) => {
  const { value, onChange } = props;

  const sliderConfig = value || {};
  const changeSliderConfig = (obj) => {
    onChange({ ...sliderConfig, ...obj });
  };

  const checkboxOptions = [
    { value: "loop", label: "Loop" },
    { value: "pagination", label: "Pagination" },
    { value: "autoPlay", label: "Auto Play" },
  ];

  return (
    <>
      <OptionItem optionItemLabel="OPTIONS">
        <div className="optionItemBody">
          <div className="optionItemRow">
            <CheckBoxHorizontal
              value={checkboxOptions
                .map((x) => x.value)
                .filter((key) => sliderConfig[key])}
              onChange={(list) => {
                let updatedSliderConfig = { ...sliderConfig };
                checkboxOptions.map((x) => {
                  return (updatedSliderConfig[x.value] = !!list.includes(
                    x.value
                  ));
                });
                changeSliderConfig(updatedSliderConfig);
              }}
              options={checkboxOptions}
              isVertical
            />
          </div>

          {[
            {
              value: "autoPlayInterval",
              placeholder: "In milliseconds",
            },
            {
              value: "scrollCount",
              label: "Slides to Scroll",
              placeholder: "Number of slides to scroll",
            },
          ].map((item) => {
            const itemValue = sliderConfig?.[item.value] || {};
            const onChange = (valueObj) =>
              changeSliderConfig({
                [item.value]: {
                  ...itemValue,
                  valueObj,
                  valueType: "textParts",
                },
              });

            return (
              <div className="optionItemRow pad7" key={item.value}>
                <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                  <div className="optionConditionTableSelectorFilterItemHead">
                    <div className="optionConditionTableFilterMetaRequirementLabel">
                      {item.label || _.startCase(item.value)}
                    </div>
                  </div>

                  <RichTextData
                    {...{
                      ...props,
                      key: item.value,
                      value: itemValue.valueObj,
                      placeholder: item.placeholder,
                      onChange,
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </OptionItem>
    </>
  );
};

export default SliderConfig;
