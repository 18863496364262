const config = {
  appName: "Appxolo",
  apiUrl: process.env.REACT_APP_API_URL || "/api/",
  socketUrl: process.env.REACT_APP_SOCKET_URL || "localhost",
  relativePath: process.env.REACT_APP_RELATIVE_PATH || "",
  frontDomain: process.env.REACT_APP_FRONT_DOMAIN || "localhost",
  frontProductionIp: process.env.REACT_APP_FRONT_PRODUCTION_IP,
  encryptionSecret: process.env.REACT_APP_ENCRYPTION_SECRET,

  encryption: { apiSecret: process.env.REACT_APP_API_SECRET },
};

export default config;
