import React from "react";
import update from "immutability-helper";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";

class KeyValuePairs extends React.Component {
  componentDidUpdate() {
    const pairs = this.props.value || [];
    if (!pairs.length) this.appendPair();
  }

  updatePairs(pairs) {
    this.props.onChange(pairs);
  }

  setNthPair(pair, n) {
    this.updatePairs(update(this.props.value || [], { $merge: { [n]: pair } }));
  }

  appendPair(pair) {
    pair = pair || {};
    pair = { ...pair, uid: pair.uid || Math.random() };

    this.updatePairs(
      update(this.props.value || [], {
        $push: [pair],
      })
    );
  }

  removePair(i) {
    this.updatePairs(update(this.props.value || [], { $splice: [[i, 1]] }));
  }

  renderPair({ item: pair, index: i }) {
    return (
      <div key={pair.uid || i} className="calcItemDatabaseFilterBox">
        <div className="calcItemDatabaseFilterBoxInner">
          <div className="calcItemDatabaseFilterBoxInnerRow">
            <div className="calcItemUnitMainDropdown">
              <input
                className="calcItemUnitMainDropdownLabel"
                placeholder="Key"
                value={pair.key || ""}
                onChange={(e) =>
                  this.setNthPair({ ...pair, key: e.target.value }, i)
                }
              ></input>
            </div>
          </div>

          <div
            className="calcItemDatabaseFilterBoxInnerRow"
            style={{ display: "block", position: "relative" }}
          >
            <RichTextData
              {...{
                ...this.props,
                immediateProps: {
                  classNames: {
                    wrapper: " ",
                    editor: "calcDatabaseFilterMultiBox",
                    paramListRow: "",
                  },
                },
                placeholder: "Value",
                value: pair.valueObj,
                onChange: (valueObj) =>
                  this.setNthPair(
                    { ...pair, valueType: "textParts", valueObj },
                    i
                  ),
              }}
            />
          </div>
          <div
            className="removeFilterButton"
            onClick={() => this.removePair(i)}
          >
            Remove
          </div>
        </div>
      </div>
    );
  }

  render() {
    const pairs = this.props.value || [];

    return (
      <>
        {pairs?.map((pair, i) => {
          return this.renderPair({ item: pair, index: i });
        })}

        <div className="pad6">
          <div
            className="addFilterButton"
            onClick={() => {
              this.appendPair();
            }}
          >
            {this.props.addButtonLevel || "Add More"}
          </div>
        </div>
      </>
    );
  }
}

export default KeyValuePairs;
