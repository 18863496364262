import React from "react";

const DateInput = React.forwardRef((props, ref) => {
  // const getFormatedValue = React.useCallback((d) => {
  //   if (isNaN(d)) return "0000-00-00";
  //   const date = new Date(d);

  //   let dd = date.getUTCDate();
  //   let MM = date.getUTCMonth() + 1;
  //   let yyyy = date.getUTCFullYear();

  //   if (isNaN(dd)) dd = 0;
  //   if (isNaN(MM)) MM = 0;
  //   if (isNaN(yyyy)) yyyy = "0000";

  //   dd = dd < 10 ? "0" + dd.toString() : dd.toString();
  //   MM = MM < 10 ? "0" + MM.toString() : MM.toString();
  //   yyyy = yyyy.toString();

  //   return yyyy + "-" + MM + "-" + dd;
  // }, []);

  return (
    <input
      ref={ref}
      style={Object.assign({}, { width: "100%" }, props.style || {})}
      type="date"
      value={props.value || ""}
      // value={getFormatedValue(props.value)}
      onChange={
        (event) => props.onChange(event.target.value)
        // props.onChange(new Date(event.target.value).getTime())
      }
    />
  );
});

export default DateInput;
