import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import etcModule from "../../../../../Modules/etc/etc-module";
import Padding from "../../Common/StyleProperties/Padding";
import Margin from "../../Common/StyleProperties/Margin";
import Border from "../../Common/StyleProperties/Border";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import ImageDisplayMode from "./ImageDisplayMode";
import Dimensions from "../../Common/StyleProperties/Dimensions";
import ObjectFit from "../../Common/StyleProperties/ObjectFit";
import ImageData from "./ImageData";
import Spacing from "../../Common/StyleProperties/Spacing";

class ImageProperties extends PropertyClass {
  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });
    const mergeStyleData = (styleData) => mergeChange({ styleData });

    const styleProps = {
      ...this.props,
      value: value.styleData?.image?.default,
      onChange: (obj) => {
        const updatedStyleData = etcModule.mergeObjects(value.styleData, obj, [
          "image",
          "default",
        ]);
        mergeStyleData(updatedStyleData);

        this.processStyleWithThrottle(
          updatedStyleData.image?.default,
          (styleData) =>
            mergeStyleData({ ...updatedStyleData, image: styleData })
        );
      },
    };

    return (
      <>
        <ImageDisplayMode
          {...{
            ...this.props,
          }}
        />

        <Dimensions {...styleProps} />
        <ObjectFit {...styleProps} />
        <Spacing {...styleProps} />
        <Border {...styleProps} />
        <BorderRadius {...styleProps} />
        <ImageData
          {...this.props}
          value={value.imageData}
          onChange={(x) => mergeChange({ imageData: x })}
          styleData={value.styleData?.image?.default}
        />
      </>
    );
  }
}

export default ImageProperties;
