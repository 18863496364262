import io from "socket.io-client";

import config from "../../Config";
import { store } from "../../store";
import { getSocket, setSocket } from "../../Providers/socket";
import authModule from "../../Modules/auth/auth-module";
import socketListeners from "./socketListeners";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

export const initSocket = async ({ onConnect }) => {
  try {
    let existingSocket = getSocket();
    existingSocket && existingSocket.disconnect();
    setSocket(null);

    let query = {};

    const socket = io(config.socketUrl, {
      query,
      extraHeaders: await getHeaders({}),
    });
    setSocket(socket);

    await store.dispatch(UnpActions.setVScreenState("APP", { socket }));

    socket.on("connect", () => {
      console.info("Socket connected:", socket.id);
      if (onConnect) onConnect(socket);
    });

    socket.on("disconnect", () => {
      console.info("Socket disconnected:", socket.id);
    });

    socket.on("error", (error) => {
      console.warn("Socket Error: ", error);

      if (error === "Unauthorize") {
        logoutUser();
      }
    });

    socketListeners(socket);

    return socket;
  } catch (e) {
    console.warn(e);
    console.error("Error initiating socket", e.message);
  }
};

export const getHeaders = async (opt = {}) => {
  if (process.env.NODE_ENV === "development") {
    await sleep(1000);
  }

  let result = {};

  let token = store?.getState()?.pState?.AUTH?.token;

  if (token)
    result = {
      ...result,
      authorization: "Bearer " + token,
      "x-project-id": "builder",
    };

  result = { ...result, ...(opt.headers || {}) };

  return result;
};

const sleep = (ms) =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve(true);
    }, ms)
  );

const logoutUser = () => {
  authModule.logout();
};
