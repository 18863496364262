const sourceElements = [
  {
    elementType: "container",
    data: {
      dataType: "fixed",
      tabs: [
        {
          uid: "DEFAULT_TAB_UID",
        },
      ],
    },
  },
  {
    elementType: "text",
    data: {
      dataType: "fixed",
      tabs: [
        {
          uid: "DEFAULT_TAB_UID",
        },
      ],
    },
  },
  {
    elementType: "image",
    data: {
      dataType: "fixed",
      tabs: [
        {
          uid: "DEFAULT_TAB_UID",
        },
      ],
    },
  },
  {
    elementType: "input",
    data: {
      dataType: "fixed",
      tabs: [
        {
          uid: "DEFAULT_TAB_UID",
        },
      ],
    },
  },
  {
    elementType: "map",
    data: {
      dataType: "fixed",
      tabs: [
        {
          uid: "DEFAULT_TAB_UID",
        },
      ],
    },
  },
  {
    elementType: "media",
    data: {
      dataType: "fixed",
      tabs: [
        {
          uid: "DEFAULT_TAB_UID",
        },
      ],
    },
  },
];

export default  sourceElements;
