import React from "react";
import _ from "lodash";

import ToggleButton from "../../../../../Components/Input/ToggleButton";
import DatabaseDataSelector from "../../../../Common/DatabaseOperations/DatabaseDataSelector";
import DatabaseSortingQuery from "../../../../Common/DatabaseOperations/DatabaseSortingQuery";
import { LinkingBoxes } from "../../../../Common/Linking/LinkingBox";
import OptionItem from "../../../../../Components/etc/OptionItem";
import ExternalApiDataSelector from "../../../../Common/ExternalApiOperations/ExternalApiDataSelector";
import DatabaseSelector from "../../../../Common/DatabaseOperations/DatabaseSelector";
import RichTextData from "../../Common/DataProperties/RichTextData";

const ContainerData = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const optionHeadTabs = (
    <ToggleButton
      classNames={{ container: "optionHeadTabs", item: "optionHeadTabItem" }}
      overideDefaultStyle
      value={!!value.repeating}
      onChange={(val) => mergeChange({ repeating: !!val })}
      options={[
        { label: "Fixed", value: false },
        { label: "Repeating", value: true },
      ]}
    />
  );

  return (
    <>
      <OptionItem optionItemLabel="Content" optionHeadTabs={optionHeadTabs}>
        {value.repeating ? (
          value.dbId === "externalApi" ? (
            <>
              <ExternalApiDataSelector
                {...{
                  ...props,
                  value: value,
                  onChange: (value) => mergeChange(value),
                }}
              />
              <ExternalApiPagination
                {...props}
                value={value.pagination}
                onChange={(val) => mergeChange({ pagination: val })}
              />
            </>
          ) : value.dbId === "staticValues" ? (
            <>
              <div className="optionConditionTableSelector">
                <DatabaseSelector
                  {...props}
                  value={value}
                  onChange={(obj) => mergeChange(obj)}
                  externalApi={true}
                  webrtcStreams={true}
                />
              </div>
              <div className="optionConditionTableSelectorHead">
                <div className="optionConditionTableSelectorHeadSubline">
                  Comma Separated Values
                </div>
                <RichTextData
                  {...{
                    ...props,
                    value: value?.staticValues?.valueText?.valueObj,
                    onChange: (valueObj) =>
                      mergeChange({
                        staticValues: {
                          ...(value?.staticValues || {}),
                          valueText: { valueType: "textParts", valueObj },
                        },
                      }),
                  }}
                />
              </div>
            </>
          ) : value.dbId === "webrtcStreams" ? (
            <div className="optionConditionTableSelector">
              <DatabaseSelector
                {...props}
                value={value}
                onChange={(obj) => mergeChange(obj)}
                externalApi={true}
                webrtcStreams={true}
              />
            </div>
          ) : (
            <>
              <DatabaseDataSelector
                {...{
                  ...props,
                  value: value,
                  onChange: (value) => mergeChange(value),
                  valueTypeData: { tableSelector: { maxNoOfInputs: 0 } },
                  externalApi: true,
                  webrtcStreams: true,
                }}
              />
              <DatabaseSortingQuery
                {...props}
                dbId={value.dbId}
                tableId={value.tableId}
                value={value}
                onChange={(value) => mergeChange(value)}
              />
              <Pagination
                value={value.pagination}
                onChange={(val) => mergeChange({ pagination: val })}
              />
            </>
          )
        ) : null}
      </OptionItem>

      <LinkingBoxes
        {...{
          ...props,
          value: value.linkings,
          onChange: (linkings) => mergeChange({ linkings }),
          availableFor: ["emailTemplate"].includes(props.availableFor)
            ? props.availableFor
            : "front",
          autoAddOneEmptyLinking: false,
          triggerOnOptions: ["onPress", "onLongPress", "onLoad"].map((x) => ({
            value: x,
            label: _.startCase(x),
          })),
          linkBoxOptions: {},
        }}
      />
    </>
  );
};

const Pagination = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

  return (
    <div className="optionItemRow">
      <div className="optionDatabaseBoxWrapper modifiedBoxes">
        <div className="optionDatabaseBoxLabel">Pagination</div>
        <div className="optionDatabaseBox">
          <div className="optionDatabasePagination">
            {[
              { value: "limit", label: "Limit Results" },
              { value: "infinite", label: "Infinite Load Interval" },
              { value: "page", label: "Pages" },
            ].map((item) => (
              <div key={item.value} className="optionDatabasePaginationItem">
                <div
                  className={`optionDatabaseRadio ${
                    value.valueType === item.value ? "active" : ""
                  }`}
                  onClick={() => mergeChange({ valueType: item.value })}
                >
                  <div className="optionDatabaseRadioDecor"></div>
                </div>
                <div className="optionDatabasePaginationContext">
                  <div className="optionDatabasePaginationKey">
                    <div className="optionDatabasePaginationKeyLabel">
                      {item.label}
                    </div>
                  </div>
                  <div className="optionDatabasePaginationMeta">
                    <input
                      className="optionDatabasePaginationMetaInput"
                      type="number"
                      value={value.valueObj?.[item.value] || ""}
                      placeholder="Value"
                      onChange={(event) => {
                        mergeChange({
                          valueObj: {
                            ...(value.valueObj || {}),
                            [item.value]: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ExternalApiPagination = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

  return (
    <div>
      <div>
        <input
          type="checkbox"
          checked={value.valueType === "externalApiInfinite"}
          onChange={() =>
            mergeChange({
              valueType:
                value.valueType === "externalApiInfinite"
                  ? null
                  : "externalApiInfinite",
            })
          }
        />
        Infinite Pagination
      </div>

      {value.valueType === "externalApiInfinite" ? (
        <div>
          <div>Additional Configuration for Infinite Pagination</div>
          <ExternalApiDataSelector
            {...{
              ...props,
              value: value.externalApi,
              onChange: (value) => mergeChange({ externalApi: value }),
              mode: "externalApiInfinitePagination",
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ContainerData;
