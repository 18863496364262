const tableIdCache = {};

const getDbData = (data, databases) => {
  let table = tableIdCache[data.tableId];
  if (!table) {
    table = databases
      ?.find((x) => x._id === data.dbId)
      ?.tables?.find((x) => x._id === data.tableId);
    tableIdCache[data.tableId] = table;
  }

  return { dbId: data.dbId, tableId: data.tableId, table };
};

const getExternalApiData = (data) => {
  let table = {
    _id: "externalApi",
    name: "externalApi",
    columns: data.externalApiDataColumns
      ?.split(",")
      .map((x) => ({ name: x.trim() }))
      .filter((x) => x.name),
  };

  return { dbId: data.dbId, tableId: table._id, table };
};

const getStaticValuesData = (data)=>{
    let table = {
      _id: "staticValues",
      name: "staticValues",
      columns: [{ name: "value" }, { name: "index" }],
    };
  
    return { dbId: data.dbId, tableId: table._id, table };
}

const webrtcStreamsData = {
  table: {
    _id: "webrtcStreams",
    name: "webrtcStreams",
    columns: ["index", "streamId", "deviceId", "streamLocation"].map((x) => ({
      name: x,
    })),
  },
  dbId: "webrtcStreams",
  tableId: "webrtcStreams",
};

const getContainerPassedParameters = (elements, { databases }) => {
  const passedParameters = [];

  for (let i = 0; i < elements?.length; i++) {
    const element = elements[i];
    const tabs = element?.value?.data?.tabs;

    for (let i = 0; i < tabs?.length; i++) {
      const tab = tabs[i];
      if (tab?.containerData?.repeating) {
        let passedParameter = {
          uid: Math.random(),
          elementId: element.id,
          conditionIndex: i,
          dbData:
            tab.containerData?.dbId === "externalApi"
              ? getExternalApiData(tab.containerData, databases)
              : tab.containerData?.dbId === "staticValues"
              ? getStaticValuesData(tab.containerData, databases)
              : tab.containerData?.dbId === "webrtcStreams"
              ? webrtcStreamsData
              : getDbData(tab.containerData, databases),
          sourceType: "repeatingContainer",
        };
        passedParameters.push(passedParameter);
      }

      if (tab?.containerType?.type === "containerTabs") {
        let passedParameter = {
          uid: Math.random(),
          elementId: element.id,
          conditionIndex: i,
          sourceType: "containerTabs",
        };
        passedParameters.push(passedParameter);
      }
    }
  }

  return passedParameters;
};

export default { getContainerPassedParameters };
