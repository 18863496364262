import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";
import StyleSelector from "../../Common/StyleProperties/StyleSelector";

class ImageDisplayMode1 extends StyleSelector {
  optionItemLabel = "Display Style";
  defaultStyleValue = "single";
  options = [
    { label: "Single", value: "single" },
    { label: "Repeating", value: "repeating" },
    { label: "Gallery", value: "gallery" },
  ];
  getStyleValue = () => this.props.value;
  setStyleValue = this.props.onChange.bind(this);
}

const ImageDisplayMode = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });
  return (
    <>
      <OptionItem optionItemLabel="Image">
        <div className="optionItemBody pad5t">
          <div className="optionItemRow">
            <div
              className="optionItemBox"
              onClick={() => mergeChange({ imageDisplayMode: "single" })}
            >
              <div
                className={
                  "advancedRadioItem" +
                  (value.imageDisplayMode === "single" ? " lightActive" : "")
                }
              >
                <div className="verticalOptions jStart">
                  <div className="containerDecor">
                    <div className="containerDecorPlusButton"></div>
                  </div>
                </div>
                <div className="advancedMultiLabel tCenter">Single</div>
              </div>
            </div>
            <div
              className="optionItemBox"
              onClick={() => mergeChange({ imageDisplayMode: "gallery" })}
            >
              <div
                className={
                  "advancedRadioItem" +
                  (["gallery"].includes(value.imageDisplayMode)
                    ? " lightActive"
                    : "")
                }
              >
                <div className="verticalOptions jStart">
                  <div className="containerDecorPlusButtonSecond">
                    <div className="containerDecorPlusButtonLine one"></div>
                    <div className="containerDecorPlusButtonLine two"></div>
                  </div>
                </div>
                <div className="advancedMultiLabel tCenter">Gallery</div>
              </div>
            </div>
          </div>

          <div className="optionItemRow">
            <div className="flex column pad7 flex1">
              <div className="linkingOpen flexRow bgWhite shadow flex1 br10 pad6">
                <div
                  className="pad6 flexRow flex1"
                  onClick={() =>
                    mergeChange({ zoomboxOnPopup: !value.zoomboxOnPopup })
                  }
                >
                  <div
                    className={
                      "optionDatabaseRadio checkBoxify" +
                      (value.zoomboxOnPopup ? " active" : "")
                    }
                  >
                    <div className="optionDatabaseRadioDecor"></div>
                  </div>
                  <div className="cGrey">Zoombox on popup</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OptionItem>
    </>
  );
};

export default ImageDisplayMode;
