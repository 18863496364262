import _ from "lodash";
import valueTypes from "../../appxolo-engine/modules/calculation/value-types";

const builderValueTypes = [
  // Value
  {
    value: "passedParameter",
    category: "value",
  },
  {
    value: "valueOfElement",
    category: "value",
    // valueTypes: [
    //   "valueOfElement",
    //   "stylesOfElementSelector", // left out
    //   "valueOfInputElement",
    // ],
  },
  {
    label: "Custom Value",
    value: "textParts",
    category: "value",
  },
  {
    label: "Order of repeating element",
    value: "valueOfRepeatingContainerRow",
    category: "value",
  },

  // Database
  {
    value: "currentTableName",
    category: "database",
  },
  {
    label: "Value: Affected Column",
    value: "valueOfAffectedColumn",
    category: "database",
  },
  {
    label: "Database value(s)",
    value: "databaseValue",
    category: "database",
    // valueTypes: [
    //   "tableSelector-valueOfColumn",
    //   "tableSelector-valuesOfColumn",
    //   "tableSelector-numberOfRows",
    //   "tableSelector-existsInColumns",
    //   "tableSelector-sumOfColumns",
    //   "tableSelector-multiplicationOfColumns",
    //   "tableSelector-divisionOfColumns",
    //   "tableSelector-substractionOfColumns",
    // ],
  },
  {
    label: "External API Request",
    value: "valueFromApi",
    category: "database",
    // valueTypes: ["valueFromApi", "getApiResponse"],
  },

  // Information
  {
    label: "Date & Time",
    value: "dateAndTime",
    category: "information",
    // valueTypes: ["currentTime", "currentDate", "timestamp"],
  },
  {
    value: "screenInformation",
    category: "information",
    // valueTypes: ["screenWidth", "screenHeight"],
  },
  {
    value: "deviceInformation",
    category: "information",
    // valueTypes: [
    //   "deviceModal",
    //   "deviceVersion",
    //   // //   TODO
    //   //   "deviceId",
    //   //   "notificationToken",
    //   //   "microphoneStatus",
    //   //   "cameraStatus",
    // ],
  },
  {
    label: "Distance Between Locations",
    value: "distanceBetweenLocation",
    category: "information",
    // valueTypes: ["distanceBetweenLocation", "travelTimeBetweenLocation"],
  },
  {
    value: "randomValue",
    category: "information",
  },
];

const getBuilderValueTypes = (opt = {}) => {
  const { availableFor } = opt;

  let result = [];

  for (let i = 0; i < builderValueTypes.length; i++) {
    let builderValueType = builderValueTypes[i];

    const valueTypeData = valueTypes.find(
      (valueType) => valueType.value === builderValueType.value
    );

    const isAvailable =
      !availableFor || valueTypeData?.availableFor.includes(availableFor);

    if (isAvailable) {
      builderValueType = {
        ...valueTypeData,
        ...builderValueType,
        label: builderValueType.label || _.startCase(builderValueType.value),
      };

      result.push(builderValueType);
    }
  }

  return result;
};

export const frontValueTypes = getBuilderValueTypes({
  availableFor: "front",
});

export const backendValueTypes = getBuilderValueTypes({
  availableFor: "backend",
});

export const emailTepmplateValueTypes = getBuilderValueTypes({
  availableFor: "emailTemplate",
});


export const allBuilderValueTypes = getBuilderValueTypes({});
