import React from "react";
import { toast } from "react-toastify";
import api from "../../../Services/Api/api";
import ProjectDetailsBase from "./ProjectDetailsBase";

class ProjectResources extends ProjectDetailsBase {
  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true });
      const result = await api.get("v1/project/resources", {
        filter: JSON.stringify({
          where: {
            project: this.props.project._id,
          },
        }),
      });
      this.setState({ loading: false, result });
    } catch (e) {
      toast.error(e.mssage);
      this.setState({ loading: false });
    }
  }

  formatBytes(bytes) {
    if (bytes === 0) return "0 Bytes";

    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
  }

  render() {
    return (
      <>
        <div className="pageTitle">
          <div>Project Resources</div>
          <span>View your resource usage</span>
        </div>

        <div className="contentBody">
          <div className="tLabel mb10">Usage</div>
          <div className="borderBox mb20">
            <div className="borderItem">
              <div className="mLabel">Data Transfer</div>
              <div className="usLabel">
                {this.state.result
                  ? this.formatBytes(this.state.result?.dataTransfer || 0)
                  : "-"}
              </div>
            </div>
            <div className="borderItem">
              <div className="mLabel">Data Storage</div>
              <div className="usLabel">
                {this.state.result
                  ? this.formatBytes(this.state.result?.dataSize || 0)
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProjectResources;
