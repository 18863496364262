import React from "react";
import _ from "lodash";
import update from "immutability-helper";

import LinkingBox, { LinkingBoxes } from "./LinkingBox";

const SuccessFailHandler = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const postOpOptions = props.immediateProps?.postOpOptions || [
    "onSuccess",
    "onFail",
    "onCompletion",
  ];

  return (
    <>
      {postOpOptions.map((successOrFailKey) => {
        return (
          <div className="optionItemRow">
            <div className="linkingBoxWrapper">
              <PostOpHandler
                {...{
                  ...props,
                  immediateProps: null,
                  key: successOrFailKey,
                  valueType: successOrFailKey,
                  classNames: {
                    onSuccess: "success",
                    onFail: "fail",
                    onCompletion: "completion",
                  },
                  value: value[successOrFailKey],
                  onChange: (x) => mergeChange({ [successOrFailKey]: x }),
                }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export const PostOpHandler = (props) => {
  const { value = {}, onChange, valueType, styles = {}, classNames } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <div className="linkingBoxMain">
      <div className="linkingBoxHead">
        <div className="optionItemBody">
          <div className={"linkLabel " + classNames?.[valueType]}>
            {_.startCase(valueType)} - Link to
          </div>
          <PostOpLinkingBoxes
            {...{
              ...props,
              value: value.linkings,
              onChange: (linkings) => mergeChange({ linkings }),
              linkBoxOptions: {
                conditionalDataType: false,
                showTriggerOption: false,
                mode: "selectorOnly",
                hideLinkDropdown: true,
              },
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div style={styles.title?.[valueType]}>{_.startCase(valueType)}</div>

      <LinkingBoxes
        {...{
          ...props,
          value: value.linkings,
          onChange: (linkings) => mergeChange({ linkings }),
          autoAddOneEmptyLinking: false,
          linkBoxOptions: {
            conditionalDataType: false,
            showTriggerOption: false,
          },
        }}
      />
    </div>
  );
};

const PostOpLinkingBoxes = (props) => {
  const { value = [], onChange } = props;

  React.useEffect(() => {
    if (!value.length) {
      onChange([{ uid: Math.random() }]);
    }
  }, [value.length, onChange]);

  return (
    <>
      {value.map((link, i) => {
        return (
          <div key={link.uid} className="optionItemRow">
            <div style={{ flex: 1 }}>
              <LinkingBox
                {...{
                  ...props,
                  key: link.uid,
                  value: link,
                  delete: () =>
                    onChange(update(value || [], { $splice: [[i, 1]] })),
                  onChange: (link) =>
                    onChange(update(value || [], { $merge: { [i]: link } })),
                }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SuccessFailHandler;
