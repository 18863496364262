import React from "react";

import CustomSelect from "../../../Components/etc/CustomSelect";
import { getDatabaseSelectorOptions } from "./DatabaseSelector";
import DatabaseQueryFilter from "./DatabaseQueryFilter";

import DataSelectorFooter from "./DataSelectorFooter";

class DatabaseDataSelector extends React.Component {
  render() {
    const { value, onChange } = this.props;
    const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

    return (
      <div className="calcItemUnit">
        <div className="calcItemUnitBox">
          <div className="calcItemUnitDecor"></div>
          <div className="calcItemUnitMain">
            <div className="calcItemUnitMainLabel">Database Table</div>

            <CustomSelect
              classNames={{
                head: "calcItemUnitMainDropdown",
                label: "calcItemUnitMainDropdownLabel",
              }}
              labelIcon={
                <img
                  className="calcItemUnitMainDropdownIco"
                  src={require("../../../Assets/img/options/options/database.png")}
                ></img>
              }
              placeholder={"Select"}
              {...getDatabaseSelectorOptions({
                ...this.props,
                onChange: (obj) => mergeChange(obj),
              })}
              jointActionRow={
                <div className="calcItemUnitMainDropdownFooter">
                  <div className="calcItemUnitMainDropdownFooterArrow">
                    <div className="one"></div>
                    <div className="two"></div>
                  </div>
                </div>
              }
            />
          </div>
        </div>

        <div className="calcItemUnitBox">
          <div className="calcItemDatabaseFilter">
            <DatabaseQueryFilter
              {...this.props}
              dbId={value?.dbId}
              tableId={value?.tableId}
              value={value}
              onChange={(obj) => mergeChange(obj)}
              valueTypeData={null}
            />
          </div>
        </div>

        <DataSelectorFooter
          {...this.props}
          value={value}
          onChange={(obj) => mergeChange(obj)}
        />
      </div>
    );
  }
}

export class DatabaseTableSelector extends React.Component {
  render() {
    const { value, onChange, classNames, className } = this.props;
    const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

    return (
      <CustomSelect
        className={className}
        classNames={{
          head: "calcItemUnitMainDropdown",
          label: "calcItemUnitMainDropdownLabel",
          ...classNames,
        }}
        labelIcon={
          <img
            className="calcItemUnitMainDropdownIco"
            src={require("../../../Assets/img/options/options/database.png")}
          ></img>
        }
        placeholder={"Select"}
        {...getDatabaseSelectorOptions({
          ...this.props,
          onChange: (obj) => mergeChange(obj),
        })}
        jointActionRow={
          <div className="calcItemUnitMainDropdownFooter">
            <div className="calcItemUnitMainDropdownFooterArrow">
              <div className="one"></div>
              <div className="two"></div>
            </div>
          </div>
        }
      />
    );
  }
}

export default DatabaseDataSelector;
