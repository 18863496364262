import React from "react";

import _dom from "../../../appxolo-engine/modules/dom/Dom";
import CustomSelect from "../../../Components/etc/CustomSelect";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
const { DomTree, DomTreeNode } = _dom;

class ValueOfElementSelector extends React.Component {
  state = {
    elementIds: [],
    parentRepeatingContainers: null,
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.elementType != prevProps.elementType ||
      (this.props.dom && !prevProps.dom)
    )
      this.load();
  }

  load() {
    if (this.props.dom) {
      const elementIds = this.getElementIds(this.props.dom);
      this.setState({ elementIds });

      this.checkForRepeatingContainers(this.props.value?.id);
    }
  }

  getElementIds(dom, options = {}) {
    const {
      props: { elementType, components, elementFilter },
    } = this;

    const elementIds = [];

    const screenContainerIds = options.screenContainerIds || [];

    const addElementId = (x, options) => {
      if (!elementFilter || elementFilter(x)) {
        elementIds.push({
          value: [...screenContainerIds, x.id].join("-"),
          label:
            "#" +
            [...screenContainerIds, x.id].join("-") +
            " " +
            (x.value?.elementType || ""),
          options: options,
        });
      }
    };

    const fn = (x) => {
      if (elementType && elementType !== "container") {
        if (x.value?.elementType === elementType) {
          addElementId(x);
        }
      } else {
        if (x.value?.elementType === "container") {
          const tabs =
            x.value?.data?.dataType === "repeating"
              ? x.value?.data?.tabs || []
              : [x.value?.data?.tabs?.[0]].filter((x) => x);

          let innerElements = [];

          for (let i = 0; i < tabs.length; i++) {
            const tab = tabs[i];

            if (tab?.containerType?.type === "containerTabs") {
              addElementId(x);
            } else if (tab?.containerType?.type === "screen") {
              const component = components?.find(
                (x) => x._id === tab?.containerType?.screenConfig?.to
              );

              if (component?.data?.dom?.children?.length) {
                const domTreeNode = new DomTreeNode(
                  "ROOT",
                  { elementType: "container" },
                  component?.data?.dom?.children
                );
                const dom = new DomTree(domTreeNode);

                const screenElementIds = this.getElementIds(dom, {
                  ...options,
                  screenContainerIds: [
                    ...(options.screenContainerIds || []),
                    x.id,
                  ],
                });
                innerElements = [...innerElements, ...screenElementIds];
              }
            } else if (elementFilter) {
              addElementId(x);
            }
          }

          if (innerElements.length) {
            const uniqueInnerElements = innerElements.filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.value === item.value)
            );

            addElementId(x, uniqueInnerElements);
          }
        } else {
          addElementId(x);
        }
      }
    };

    dom.loopOverAllNodes(fn);

    const uniqueElementIds = elementIds.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );

    return uniqueElementIds;
  }

  onElementSelect(x) {
    let obj = { id: x.value };

    if (x.value !== this.props.value?.id) {
      obj = { ...obj, repeatingContainerInfo: null };

      this.setState({ parentRepeatingContainers: null });
      this.checkForRepeatingContainers(x.value);
    }
    this.props.onChange({ ...(this.props.value || {}), id: x.value });
  }

  checkForRepeatingContainers(elementIdsStr) {
    if (!elementIdsStr) return;

    const elementIds = elementIdsStr.split("-");

    const elementId = elementIds[elementIds.length - 1];
    const screenContainers = elementIds.slice(0, -1);

    let dom = this.props.dom;

    for (let i = 0; i < screenContainers.length; i++) {
      // const screenContainer = screenContainers[i];
      // TODO handle when element id is inside container type screen
    }

    const indices = dom.findRecursiveIndices(elementId);
    const parents = dom.findNodeAndParentsByIndices(indices);
    parents.pop();
    const parentRepeatingContainers = parents.filter(
      (x) => x.value?.data?.tabs?.[0]?.containerData?.repeating
    );
    console.log({ parents, parentRepeatingContainers });
    this.setState({ parentRepeatingContainers });
  }

  render() {
    const {
      props: { value = {}, onChange },
      state: { elementIds, parentRepeatingContainers },
    } = this;

    // <div className="calcItemUnitMainDropdown">
    //   <div className="calcItemUnitMainDropdownLabel">Container #OH839</div>
    //   <div className="calcItemUnitMainDropdownFooter">
    //     <div className="calcItemUnitMainDropdownFooterArrow">
    //       <div className="one"></div>
    //       <div className="two"></div>
    //     </div>
    //   </div>
    // </div>;

    return (
      <div>
        <CustomSelect
          onChange={this.onElementSelect.bind(this)}
          value={value?.id || ""}
          options={elementIds}
          placeholder={"Select Element"}
          classNames={{
            head: "optionInputIconBox",
            label: "optionInputIconBoxField",
            chevron: "optionDatabaseSelectChevron",
            ...(this.props.immediateProps?.classNames || {}),
          }}
        />

        {parentRepeatingContainers?.length ? (
          <div>
            <div style={{ display: "flex" }}>
              <input
                type="checkbox"
                checked={!value.interveneGetClosest}
                onChange={() =>
                  onChange({
                    ...value,
                    interveneGetClosest: !value.interveneGetClosest,
                  })
                }
              />
              Get Closest Element
            </div>

            {value.interveneGetClosest ? (
              <div>
                <div>Repeating Containers</div>
                {parentRepeatingContainers?.map((repeatingContainer) => {
                  return (
                    <div key={repeatingContainer.id}>
                      <div>container id: #{repeatingContainer.id}</div>
                      <RichTextData
                        {...{
                          ...this.props,
                          placeholder: "Repeating id or order",
                          value:
                            value?.repeatingContainerInfo?.[
                              repeatingContainer.id
                            ]?.valueObj,
                          onChange: (valueObj) =>
                            onChange({
                              ...value,
                              repeatingContainerInfo: {
                                ...(value?.repeatingContainerInfo || {}),
                                [repeatingContainer.id]: {
                                  valueType: "textParts",
                                  valueObj,
                                },
                              },
                            }),
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default ValueOfElementSelector;
