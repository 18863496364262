import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import etcModule from "../../../../../Modules/etc/etc-module";
import Padding from "../../Common/StyleProperties/Padding";
import Margin from "../../Common/StyleProperties/Margin";
import Border from "../../Common/StyleProperties/Border";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import Dimensions from "../../Common/StyleProperties/Dimensions";
import MediaData from "./MediaData";
import Background from "../../Common/StyleProperties/Background";
import Spacing from "../../Common/StyleProperties/Spacing";

class MediaProperties extends PropertyClass {
  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });
    const mergeStyleData = (styleData) => mergeChange({ styleData });

    const styleProps = {
      ...this.props,
      value: value.styleData?.media?.default,
      onChange: (obj) => {
        const updatedStyleData = etcModule.mergeObjects(value.styleData, obj, [
          "media",
          "default",
        ]);
        mergeStyleData(updatedStyleData);

        this.processStyleWithThrottle(
          updatedStyleData.media?.default,
          (styleData) =>
            mergeStyleData({ ...updatedStyleData, media: styleData })
        );
      },
    };

    return (
      <>
        <Dimensions {...styleProps} />
        <Spacing {...styleProps} />
        <Border {...styleProps} />
        <BorderRadius {...styleProps} />
        <Background {...styleProps} />
        <MediaData
          {...this.props}
          value={value.mediaData}
          onChange={(x) => mergeChange({ mediaData: x })}
        />
      </>
    );
  }
}

export default MediaProperties;
