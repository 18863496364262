import React from "react";
import { Table } from "react-bootstrap";
import _ from "lodash";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import api from "../../Services/Api/api";
import AppModal from "../../Components/Models/app-modal";
import { toast } from "react-toastify";
import ToggleButton from "../../Components/Input/ToggleButton";
import DatabaseDataSelector from "../Common/DatabaseOperations/DatabaseDataSelector";

class EditTableInner extends React.PureComponent {
  state = {
    loading: false,
    error: null,
    table: {},
  };

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({
        table: this.props.database.tables?.find(
          (x) => x.name === this.props.tableName
        ),
      });

      const { databases } = await api.get("v1/database");

      const database = databases?.find(
        (x) => x._id === this.props.database._id
      );

      if (!database) throw new Error("Could not find the database details");

      const table = database.tables?.find(
        (x) => x.name === this.props.tableName
      );
      this.setState({
        table,
        database,
        dbSync: table?.dbSyncs?.[0],
      });
      this.props.setScreenState({ databases }, false, "BUILDER");
    } catch (e) {
      toast.error(e.message);
    }
  }

  async rename() {
    const {
      props: { database, tableName, onChange },
      state: { table },
    } = this;

    await api
      .put(`v1/database/${database._id}/table`, {
        tableName: tableName,
        newTableName: table.name,
      })
      .then(() => {
        onChange({ newTableName: table.name });
      })
      .catch((e) => toast.error(e.message));
  }

  async toggleDbSync(dbSync) {
    try {
      if (dbSync) {
        if (dbSync._id) {
          this.setState({ loading: true });
          if (dbSync.status === "active") {
            await this.applyDbSync({ ...dbSync, status: "deactive" });
          } else {
            await this.applyDbSync({ ...dbSync, status: "active" });
          }
          await this.load();
          this.setState({ loading: false });
        } else {
          if (dbSync.status === "active") {
            this.setState({ dbSync: null });
          } else {
            this.setState({ dbSync: { ...dbSync, status: "active" } });
          }
        }
      } else {
        this.setState({ dbSync: { status: "active" } });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async applyDbSync(dbSync) {
    try {
      if (this.state.applyingDbSync) return;
      this.setState({ applyingDbSync: true });

      const payload = {
        ..._.pick(dbSync, ["_id", "status", "synchFilter"]),
        synchFromDatabase: dbSync?.synchFilter?.dbId,
        synchFromTable: dbSync?.synchFilter?.tableId,
        synchToDatabase: this.state.database._id,
        synchToTable: this.state.table._id,
      };

      await api.post("v1/database/dbsync", payload);
      await this.load();

      this.setState({ applyingDbSync: false });
    } catch (e) {
      this.setState({ applyingDbSync: false });
      window.alert(e.message);
    }
  }

  renderDbSync() {
    const {
      state: { dbSync, loading, applyingDbSync },
    } = this;

    return (
      <div
        style={{
          padding: "15px",
          background: "#fff",
          marginTop: "10px",
          boxShadow: "#d0d0d0 0px 2px 9px",
          border: "solid 1px #d5d5d5",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Database Synching</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {loading ? <div className="loader"></div> : null}

            <ToggleButton
              classNames={{
                container: "optionHeadTabs",
                item: "optionHeadTabItem",
              }}
              overideDefaultStyle
              value={dbSync?.status === "active"}
              onChange={(x) =>
                x !== (dbSync?.status === "active") && this.toggleDbSync(dbSync)
              }
              options={[
                { value: false, label: "Off" },
                { value: true, label: "On" },
              ]}
            />
          </div>
        </div>
        {dbSync?.status === "active" ? (
          <div>
            Select Database Table To Sync From
            <DatabaseDataSelector
              {...{
                ...this.props,
                // availableFor: "front",

                disbaleFilterGroup: false,
                databases: (this.props.databases || []).filter(
                  (x) => x._id !== this.state.database?._id
                ),
                value: dbSync?.synchFilter || {},
                customFilterConditions: [{ label: "Equals", value: "eq" }],
                onChange: (x) => {
                  let _dbSync = {
                    ...(dbSync || {}),
                    synchFilter: { ...(dbSync?.synchFilter || {}), ...x },
                  };
                  console.log(x, _dbSync);
                  this.setState({ dbSync: _dbSync });
                },
                valueType: "tableSelector-valueOfColumn",
                valueTypeData: {
                  tableSelector: {
                    // additionalInput: true,
                    minNoOfInputs: 0,
                    maxNoOfInputs: 0,
                  },
                  label: "Address",
                },
              }}
            />
            <button onClick={() => this.applyDbSync(dbSync)}>
              {applyingDbSync ? "Loading" : "Submit"}
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const {
      props: { visible, close, tableName },
      state: { loading, table, database },
    } = this;

    const content = (
      <div>
        <div
          style={{
            display: "flex",
            padding: "15px",
            background: "#fff",
            justifyContent: "space-between",
            boxShadow: "#d0d0d0 0px 2px 9px",
            border: "solid 1px #d5d5d5",
          }}
        >
          <div>Rename Table: </div>
          <div>
            <input
              value={table?.name || ""}
              onChange={(e) =>
                this.setState({
                  table: { ...table, name: e.target.value.trim() },
                })
              }
            />
            <button
              onClick={() => this.rename()}
              disabled={loading || !tableName.trim()}
            >
              Submit
            </button>
          </div>
        </div>

        <div
          style={{
            padding: "15px",
            background: "#fff",
            marginTop: "10px",
            boxShadow: "#d0d0d0 0px 2px 9px",
            border: "solid 1px #d5d5d5",
          }}
        >
          <div>Columns</div>
          <Table bordered>
            <tbody>
              <AddColumn
                {...{
                  ...this.props,
                  reload: () => {
                    this.load();
                    this.props.onChange({});
                  },
                }}
              />
              {table?.columns?.map((col) => (
                <ColumnEditor
                  key={col._id}
                  {...{
                    ...this.props,
                    col: col,
                    reload: () => {
                      this.load();
                      this.props.onChange({});
                    },
                  }}
                />
              ))}
            </tbody>
          </Table>
        </div>

        {this.renderDbSync()}
      </div>
    );

    return (
      <AppModal
        {...{
          title: tableName,
          visible: !!visible,
          handleClose: close,
          showSubmitBtn: false,
          cancelBtnText: "Close",
        }}
      >
        {content}
      </AppModal>
    );
  }
}

class AddColumn extends React.PureComponent {
  state = {
    col: "",
    loading: false,
    error: null,
  };

  async add() {
    this.setState({ loading: true });

    await api
      .post(`v1/database/${this.props.database._id}/column`, {
        tableName: this.props.tableName,
        columnName: this.state.col,
      })
      .then(() => {
        this.setState({ loading: false, col: "" });
        this.props.reload();
      })
      .catch((e) => {
        this.setState({ error: e.message, loading: false });
        toast.error(e.message);
      });
  }

  render() {
    const {
      state: { col, loading },
    } = this;

    return (
      <tr>
        <td>
          <input
            value={col}
            onChange={(e) => this.setState({ col: e.target.value.trim() })}
            placeholder="New column"
          />
        </td>
        <td>
          <button onClick={this.add.bind(this)} disabled={loading || !col}>
            Add New
          </button>
        </td>
      </tr>
    );
  }
}

class ColumnEditor extends React.PureComponent {
  state = {
    col: this.props.col.name,
    loading: false,
    error: null,
    edit: false,
  };

  async editCol() {
    this.setState({ loading: true });

    await api
      .put(`v1/database/${this.props.database._id}/column`, {
        tableName: this.props.tableName,
        columnName: this.props.col.name,
        newColumnName: this.state.col,
      })
      .then(() => {
        this.props.reload();
        this.setState({ loading: false, edit: false });
      })
      .catch((e) => {
        this.setState({ error: e.message, loading: false, edit: false });
        toast.error(e.message);
      });
  }

  async deleteCol() {
    this.setState({ loading: true });

    await api
      .delete(`v1/database/${this.props.database._id}/column`, {
        tableName: this.props.tableName,
        columnName: this.props.col.name,
      })
      .then(() => {
        this.props.reload();
        this.setState({ loading: false });
      })
      .catch((e) => {
        this.setState({ error: e.message, loading: false });
        toast.error(e.message);
      });
  }
  render() {
    const {
      state: { col: editedCol, loading, edit },
      props: { col },
    } = this;

    if (edit) {
      return (
        <tr>
          <td>
            <input
              value={editedCol}
              onChange={(e) => this.setState({ col: e.target.value.trim() })}
            />
          </td>
          <td>
            <button
              onClick={() =>
                this.setState({ col: this.props.col.name, edit: false })
              }
            >
              Cancel
            </button>
            <button
              onClick={this.editCol.bind(this)}
              disabled={loading || !editedCol}
            >
              Submit
            </button>
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td>{col.name}</td>
          <td>
            <button
              onClick={() => {
                if (
                  window.confirm(
                    `Are you sure you want to delete col "${col.name}"?`
                  )
                ) {
                  this.deleteCol();
                }
              }}
            >
              Delete
            </button>
            <button
              onClick={() =>
                this.setState({ edit: true, col: this.props.col.name })
              }
            >
              Edit
            </button>
          </td>
        </tr>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  databases: state.vState.BUILDER.databases,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "DATABASE_VIEWER") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const EditTable = connect(mapStateToProps, mapDispatchToProps)(EditTableInner);

export default EditTable;
